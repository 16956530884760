import { StandardTables } from "wksConstants";
import {
  eqrcAdvColumns,
  eqrcAuditColumns,
  eqrcFatFingerColumns,
  eqrcGrossExposureColumns,
  eqrcMarketImpactColumns,
  eqrcOrderRateColumns,
  eqrcOrderTypeColumns,
  eqrcRestrictedStockColumns,
  eqrcShortSaleColumns,
  eqrcMaxNotionalOrderColumns,
  eqrcSharesLocatedCheckColumns,
  eqrcMaxSharesPerOrderColumns,
  eqrcBrokerListColumns,
  eqrcSharesLocatedBrokerListColumns,
} from "./columnGroups";

export const AuditColumnMap = {
  [StandardTables.EQRC_FAT_FINGER]: [...eqrcAuditColumns, ...eqrcFatFingerColumns(true)],
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: [
    ...eqrcAuditColumns,
    ...eqrcRestrictedStockColumns(true),
  ],
  [StandardTables.EQRC_GROSS_EXPOSURE]: [...eqrcAuditColumns, ...eqrcGrossExposureColumns(true)],
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: [
    ...eqrcAuditColumns,
    ...eqrcMarketImpactColumns(true),
  ],
  [StandardTables.EQRC_ORDER_TYPE]: [...eqrcAuditColumns, ...eqrcOrderTypeColumns(true)],
  [StandardTables.EQRC_ADV_CHECK]: [...eqrcAuditColumns, ...eqrcAdvColumns(true)],
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: [...eqrcAuditColumns, ...eqrcOrderRateColumns(true)],
  [StandardTables.EQRC_SHORT_SALE]: [...eqrcAuditColumns, ...eqrcShortSaleColumns(true)],
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: [
    ...eqrcAuditColumns,
    ...eqrcMaxNotionalOrderColumns(true),
  ],

  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: [
    ...eqrcAuditColumns,
    ...eqrcMaxSharesPerOrderColumns(true),
  ],

  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: [
    ...eqrcAuditColumns,
    ...eqrcSharesLocatedCheckColumns(true),
  ],
  [StandardTables.EQRC_BROKER_LIST]: [...eqrcAuditColumns, ...eqrcBrokerListColumns(true)],
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: [
    ...eqrcAuditColumns,
    ...eqrcSharesLocatedBrokerListColumns(true),
  ],
};
