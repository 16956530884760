import { Middle } from "components/middle";
import React from "react";

export const PvRejectLite: React.FC = () => {
  return (
    <Middle>
      <div>todo</div>
    </Middle>
  );
};
