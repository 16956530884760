import { NotificationHub } from "@nef/core";
import { getHeaders, getKeycloak } from "keycloak";

export const doFetch = async (url, options, onOk) => {
  let isTokenExpired = getKeycloak().isTokenExpired();
  if (isTokenExpired) {
    await getKeycloak()
      .updateToken()
      .then(
        () => {
          isTokenExpired = false;
          options.headers = { ...(options?.headers || {}), ...getHeaders() };
        },
        err => {
          console.error(`Error refreshing token: ${err}`);
        }
      );
  }

  if (isTokenExpired) {
    NotificationHub.send(
      "danger",
      "Could not refresh user token. Please refresh window or login again."
    );
  }

  const r = await fetch(url, options);
  if (r.ok && onOk) {
    onOk();
  }

  switch (r.status) {
    case 502:
      throw new Error("Service unavailable");
    case 403:
      throw new Error("Request not allowed");
    case 504:
      throw new Error("Request time-out");
    case 500:
      throw new Error("Request failed");
    default:
      break;
  }

  try {
    // .json() fails when the response body is empty
    // So check the length of body as text, only parse if there is content
    // we need the cloned response so we can call .json on it,
    // bc calling a read method on the response stream more than once throws
    // on the response object

    const cloned = r.clone();
    const text = await r.text();

    if (text.length) {
      const json = await cloned.json();
      return { json, response: r };
    } else {
      return { json: {}, response: r };
    }
  } catch (e) {
    throw new Error("Parsing Error");
  }
};

export const doFetchWrapper = async (
  url,
  options,
  callback,
  errorCallback,
  abortCallback,
  onOk,
  numAttempts = 0
) => {
  let caught = false;
  let aborted = false;
  let json, response;
  let attempNum = 1;

  const makeRequest = async () => {
    return await doFetch(url, options, onOk).catch(e => {
      if (options.signal) {
        aborted = options.signal.aborted;
      }
      if (aborted) {
        if (abortCallback) {
          abortCallback(e);
        }
      } else {
        if (numAttempts > attempNum) {
          attempNum++;
          return makeRequest();
        }

        errorCallback(e);
      }
      caught = true;
    });
  };
  const data = await makeRequest();

  if (caught) {
    return;
  }

  json = data?.json;
  response = data?.response;

  if (!response?.ok) {
    if (numAttempts > attempNum) {
      setTimeout(() => {
        doFetchWrapper(url, options, callback, errorCallback, abortCallback, onOk, numAttempts - 1);
      }, 500);
    } else {
      errorCallback(json);
    }
  } else {
    callback(json);
  }
};
