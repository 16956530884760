import { CircleButton, FontAwesomeIcon, Header, Indicator } from "@nef/core";
import { getColor, getShadow } from "@nef/style-utils";
import React from "react";
import styled from "styled-components";

const borderRadius = "5px";
const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 30px ${CHART_HEIGHT_PX}px calc(100% - ${CHART_HEIGHT_PX}px - 30px - 2rem);
`;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArraySection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    border: 1px solid black;
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    border-radius: ${borderRadius};
    padding: 1rem;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 2rem);
`;

const ChartSection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    padding: 1rem;
    box-sizing: border-box;
    border-radius: ${borderRadius};
    grid-column: span 1;
  `}
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PieChartWrapper = styled.div`
  aspect-ratio: 1 / 1;
  width: ${CHART_HEIGHT_PX - 75}px;
`;

const TableWrapper = styled.div`
  grid-column: span 4;
`;

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

const RequestTableWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 1 / 3;
`;

const FlipChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type RejectsFlipCardSectionProps = {
  totalRejectCount: number;
  toggleFlipped: () => void;
  children: React.ReactNode | JSX.Element;
};

export const RejectsFlipCardSection: React.FC<RejectsFlipCardSectionProps> = ({
  totalRejectCount,
  toggleFlipped,
  children,
}) => {
  return (
    <>
      <FlipCardHeader size={3}>
        <ServiceIndicatorWrapper>
          Rejects By Symbol - {totalRejectCount}
          <Indicator color="success" />
        </ServiceIndicatorWrapper>
        <CircleButton onClick={toggleFlipped} ghost={true} size="xs">
          <FontAwesomeIcon iconClassName="fa-chart-bar" />
        </CircleButton>
      </FlipCardHeader>
      {children}
    </>
  );
};
