export const ReportingParty = {
  Exec: "M",
  Contra: "O",
};

export const Date_Format = "YYYY MM DD";
export const DATE_DISPLAY_FORMAT = "MM/DD/YYYY";
export const DATE_RANGE_DISPLAY_FORMAT = "MM/DD/YYYY - MM/DD/YYYY";
export const ALL_FORMS = "*";

export enum CONTROLLED_INPUT_TYPES {
  ALPHA = "alpha",
  CONTRACT = "contract",
  DECIMAL = "decimal",
  NUMERIC = "numeric",
  PRICE = "price",
  SYMBOL = "symbol",
  INVERSE_CHAR_CASE = "inverse",
  RASH_PRICE = "rashPrice",
  FOUR_DECIMAL_PLACES = "4DecimalPlaces",
  FEE_PRICE = "feePrice",
  FEE_CONTRACT = "feeContract",
}

export enum TR_SIDE {
  BUY = "B",
  SELL = "S",
  SELL_SHORT = "Z",
  SHORT_SELL_EXEMPT = "E",
  CROSS = "X",
  CROSS_SELL_SHORT = "C",
  CROSS_SELL_SHORT_EXEMPT = "K",
  BUY_CUSTOMER_SOLD_SHORT = "P",
  BUY_CUSTOMER_SOLD_SHORT_EXEMPT = "A",
  UNKNOWN = "?",
}

export enum TR_SHORT_SALE_INDICATOR {
  NOT_SOLD_SHORT = " ",
  SOLD_SHORT = "S",
  SOLD_SHORT_EXEMPT = "E",
}

export enum AUGMENT_ON_CHANGE {
  UPDATE_CLEARING_PRICE = "updateClearingPrice",
  SET_RISK_REPORT_TYPE = "setRiskReportType",
  EMPTY_GIVE_UPS = "emptyGiveUps",
  SET_CONFIRM_MSG = "setConfirmMessage",
  EMPTY_SELLER_DAYS = "emptySellerDays",
  EMPTY_REVERSAL_FIELDS = "emptyReversalFields",
  EMPTY_CORRESPONDENT_MPID = "emptyCorrespondentMPID",
  EMPTY_PTRA_EMAILS = "emptyPtraEmails",
  SET_ALERT_LOG = "setAlertLog",
  EMPTY_SYMBOL_AND_CUSIP = "emptySymbolAndCUSIP",
  EMPTY_PORT = "emptyPort",
  SET_EQRC_START_END_DATE = "setEqrcStartEndDate",
  EMPTY_PRICE_CONTRACT_AND_FEE = "emptyPriceContractAndFee",
  EMPTY_DATE_TIME_RANGES = "emptyDateTimeRanges",
  EMPTY_PARTICIPANT_MPID = "emptyParticipantMPID",
  PVR_SET_ALL_MPID = "pvrSetAllMpid",
}
