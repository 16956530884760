import React, { useCallback, useEffect, useMemo } from "react";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { PvrEmailTableButtons, usePvrEmailContext } from "./";

export const PvrEmailManagerTable = ({
  onClickNew,
  onClickEdit,
  onClickEnable,
  onClickDisable,
}) => {
  const [emailData, emailDispatch] = usePvrEmailContext();
  const tableDispatch = useStandardTableDispatch();

  useEffect(() => {
    emailDispatch({
      type: "START_REFRESH",
    });
  }, [emailDispatch]);

  useEffect(() => {
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.PVR_EMAIL_MANAGER, isLoading: emailData.isLoading },
    });
  }, [emailData.isLoading, tableDispatch]);

  useEffect(() => {
    if (emailData.emails) {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PVR_EMAIL_MANAGER, data: emailData.emails },
      });
    }
  }, [emailData.emails, tableDispatch]);

  const handleClickNew = useCallback(() => {
    if (typeof onClickNew === "function") {
      onClickNew();
    }
  }, [onClickNew]);

  const handleClickEdit = useCallback(() => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleClickDisable = useCallback(() => {
    if (typeof onClickDisable === "function") {
      onClickDisable();
    }
  }, [onClickDisable]);

  const tableButtons = useMemo(() => {
    return (
      <PvrEmailTableButtons
        onClickNew={handleClickNew}
        onClickEdit={handleClickEdit}
        onClickDisable={handleClickDisable}
      />
    );
  }, [handleClickNew, handleClickEdit, handleClickDisable]);

  const Header = useCallback(() => <StandardHeader title="External Recipients" />, []);
  return (
    <StandardTable
      header={Header}
      table={StandardTables.PVR_EMAIL_MANAGER}
      enableLayoutExport={true}
      id={"pvr-email-table"}
      isSingleSelect={false}
      isColumnsVirtualized={false}
      isFilterable={true}
      headerMenu={tableButtons}
    />
  );
};
