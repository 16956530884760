import { PVR_ALERT_TYPE } from "components/settings/pvr/constants";

export type PVR_ALERT_MODEL = {
  alertId: string;
  alertType: number;
  mpid: string;
  symbol: string;
  status: string;
  time: number;
};

export type PVR_EMAIL_ENTITY = {
  id: string;
  creator: string;
  recipientName: string;
  recipientEmail: string;
  status: string;
  userId: string;
  version: number;
  modifiedBy: string;
  lastUpdate: string;
  new: boolean;
};

export type PVR_CONFIGURATION_ENTITY = {
  id: string;
  mpid: string;
  email: PVR_EMAIL_ENTITY;
  creator: PVR_EMAIL_ENTITY;
  version: number;
  modifiedBy: string;
  lastUpdate: string;
  enabled: boolean;
  new: boolean;
  porStatusAlertActive: boolean;
  pvResubmitAlertActive: boolean;
  pvWindowAlertActive: boolean;
  emailActive: boolean;
  logActive: boolean;
  popupActive: boolean;
};

export const getIdentifierFromAlert = (alert: PVR_ALERT_MODEL) => {
  return `${alert.alertType}|${alert.status}|${alert.mpid}|${alert.symbol}`;
};

export const getHashKey = ({ alertType, mpid }: { alertType: number; mpid: string }) => {
  if (alertType === PVR_ALERT_TYPE.POR_WINDOW) {
    mpid = "<ALL>";
  }
  return `${alertType}|${mpid}`;
};

export const getAlertIdentifier = (hashKey: string, alertConfigId: string) => {
  return `${hashKey}|${alertConfigId}`;
};
