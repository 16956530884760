import React, { useReducer, useCallback } from "react";

const defaultState = {
  shadowLeftStyle: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: 50,
    background:
      "linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, .4) 100%)",
  },
  shadowRightStyle: {
    pointerEvents: "none",
    position: "absolute",
    bottom: 0,
    right: 0,
    top: 0,
    width: 50,
    paddingRight: "1.5rem",
    background:
      "linear-gradient(to left, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.4) 100%)",
  },
};

const scrollbarDispatch = (state, action) => {
  switch (action.type) {
    case "SET_OPACITY":
      return {
        ...state,
        shadowLeftStyle: { ...state.shadowLeftStyle, opacity: action.payload.left },
        shadowRightStyle: {
          ...state.shadowRightStyle,
          opacity: action.payload.right,
        },
      };
    default:
      return { ...state };
  }
};

export const HorizontalShadowedScrollbar = ({ children, style }) => {
  const [state, componentDispatch] = useReducer(scrollbarDispatch, defaultState);

  const handleUpate = useCallback(
    values => {
      let { left, clientWidth, scrollWidth } = values;
      if (clientWidth === scrollWidth) {
        if (state.shadowLeftStyle.opacity !== 0 || state.shadowRightStyle.opacity !== 0) {
          componentDispatch({
            type: "SET_OPACITY",
            payload: { left: 0, right: 0 },
          });
        }
      } else {
        if (
          (left === 0 &&
            (state.shadowLeftStyle.opacity !== 0 || state.shadowRightStyle.opacity !== 1)) ||
          (left === 1 &&
            (state.shadowLeftStyle.opacity !== 1 || state.shadowRightStyle.opacity !== 0))
        ) {
          componentDispatch({
            type: "SET_OPACITY",
            payload: { left, right: 1 - left },
          });
        } else if (
          left !== 1 &&
          left !== 0 &&
          (state.shadowLeftStyle.opacity !== 1 || state.shadowRightStyle.opacity !== 1)
        ) {
          componentDispatch({
            type: "SET_OPACITY",
            payload: { left: 1, right: 1 },
          });
        }
      }
    },
    [componentDispatch, state]
  );

  return children;
};
