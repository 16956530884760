import { BarChart, PieChart } from "@nef/charts";
import { DataArray, FormField, Header, Indicator, Loader, NotificationHub } from "@nef/core";
import { getColor, getShadow } from "@nef/style-utils";
import { ApiResponseNames, Forms, PVMemberMonitorTop } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { FlipCard } from "components/flipCard";
import { useFormContext } from "components/form";
import { Middle } from "components/middle";
import {
  CloseButton,
  getSelectedRows,
  RecapButton,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import TableButtons from "components/standardTable/tableButtons";
import { TradeDetailRecap, useRecapContext } from "components/tradeDetailRecap";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { EffectCallback, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SelectOption } from "types";
import { formatUrl } from "utils/js.utils";
import { StandardTables, TableButtonAction, TableButtonLabel } from "wksConstants";
import { useLayoutContext } from "../../context";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch, usePorCacheState } from "../porCache";
import { DATE_FILTER, POR, PORModel, PV_REJECT_TOP, PvReject, PvRejectModel } from "./constant";
import {
  PV_REJECT_MESSAGE,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
  RejectLabel,
} from "components/pvRejects/constant";
import { usePvMemberDispatch, usePvMemberState } from "./context";
import { REJECT_CACHE_FILTER_BY, useRejectCacheDispatch, useRejectCacheState } from "./rejectCache";
import {
  REJECT_COUNT_FILTER_BY,
  useRejectCountDispatch,
  useRejectCountState,
} from "./rejectCountCache";
import { RejectsFlipCardSection } from "./rejectsFlipCardSection";
import { PorWindowTimer } from "../porWindowTimer";
import { usePorWindowCacheState } from "../porWindowCache";
import { PorButtonHeader } from "./porButtonHeader";
import { useBulkJobDispatch } from "components/topBar/bulkJob/context";
import { filterForDataArray } from "../constant";

const borderRadius = "5px";
const CHART_HEIGHT_PX = 300;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
  grid-template-rows: 7px calc(23px - 1rem) ${CHART_HEIGHT_PX}px calc(
      100% - ${CHART_HEIGHT_PX}px - 30px - 2rem
    );
`;

const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArraySection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    border: 1px solid black;
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    border-radius: ${borderRadius};
    padding: 1rem;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 2rem);
`;

const ChartSection = styled.section`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    box-shadow: ${getShadow(1)};
    border: 1px solid rgb(230, 230, 230);
    padding: 1rem;
    box-sizing: border-box;
    border-radius: ${borderRadius};
    grid-column: span 1;
  `}
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PieChartWrapper = styled.div`
  aspect-ratio: 1 / 1;
  width: ${CHART_HEIGHT_PX - 75}px;
`;

const TableWrapper = styled.div`
  grid-column: span 4;
`;

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

const RequestTableWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 2 / 4;
`;

const FlipChartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const FlipCardHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FieldWrapper = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

const TimerWrapper = styled.div`
  grid-column: 3 / 5;
  grid-row: 1 / 2;
`;

const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

type DataArrayCount = {
  label: string;
  value: number;
  subRows: { label: string; value: JSX.Element; count: number }[];
};

type PieChartData = { name: string; value: number }[];

type PorServiceErrorResponse = { message: string };

const emptyMpids: SelectOption[] = [];
export const PvRejectMonitor: React.FC = () => {
  const [filterSymbol, setFilterSymbol] = useState("");
  const [, layoutDispatch] = useLayoutContext();
  const tableDispatch = useStandardTableDispatch();
  const [isFlipped, setFlipped] = useState(false);
  const pvMemberData = usePvMemberState();
  const [tableData] = useStandardTableContext();
  const pvMemberDispatch = usePvMemberDispatch();
  const [formData] = useFormContext();
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCacheState = useRejectCacheState();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCountState = useRejectCountState();
  const rejectCountDispatch = useRejectCountDispatch();
  const [recapData] = useRecapContext();
  const porWindowState = usePorWindowCacheState();
  const bulkJobDispatch = useBulkJobDispatch();
  const [isResubmitting, setIsResubmitting] = useState(false);

  const selectedMpids: SelectOption[] = useMemo(() => {
    if (Array.isArray(formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid])) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[ApiResponseNames.mpid];
    }
    return emptyMpids;
  }, [formData]);

  const selectedDateFilter: SelectOption = useMemo(() => {
    if (formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate]) {
      return formData[Forms.PV_MONITOR_MEMBER_TOP.key].fields[PV_REJECT_TOP.rejectDate];
    }
    return emptyMpids;
  }, [formData]);

  useEffect(() => {
    if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
      porCacheDispatch({
        type: "START_POLLING",
        payload: {
          filterBy: POR_CACHE_FILTER_BY.MPID,
          keys: selectedMpids.map(mpid => mpid.label),
        },
      });
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_REJECTS },
      });
      rejectCacheDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_CACHE_FILTER_BY.MPID,
            criteria: {
              mpids: selectedMpids.map(mpid => mpid.value as string),
              rejectText: REJ_PRICE_OO_RANGE,
              date: selectedDateFilter.value as DATE_FILTER,
            },
          },
        },
      });
      rejectCountDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_COUNT_FILTER_BY.CLIENT_COUNTS,
            criteria: selectedMpids.map(mpid => mpid.value),
            date: selectedDateFilter.value,
          },
        },
      });
    } else {
      porCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCacheDispatch({
        type: "RESET_CACHE",
      });
      rejectCountDispatch({
        type: "RESET_CACHE",
      });
    }
  }, [
    pvMemberDispatch,
    selectedMpids,
    tableDispatch,
    porCacheDispatch,
    rejectCacheDispatch,
    rejectCountDispatch,
    selectedDateFilter,
  ]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  useEffect(() => {
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.PV_REQUESTS,
          data: Object.values(porCacheState.data).sort(
            (a, b) =>
              new Date(b[PORModel.LAST_UDPATE]).getTime() -
              new Date(a[PORModel.LAST_UDPATE]).getTime()
          ),
        },
      },
      {
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PV_REQUESTS, isLoading: porCacheState.isLoading },
      },
    ]);
  }, [porCacheState.data, porCacheState.isLoading, tableDispatch]);

  useEffect(() => {
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PV_REJECTS, data: Object.values(rejectCacheState.data) },
      },
      {
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PV_REJECTS, isLoading: rejectCacheState.isLoading },
      },
    ]);
  }, [rejectCacheState.data, rejectCacheState.isLoading, tableDispatch]);

  const handleSetFilter = useCallback(
    ({ symbol, reject }: { symbol?: string; reject: string }) =>
      () => {
        if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
          tableDispatch({
            type: "DESELECT_ALL_ROWS",
            payload: { table: StandardTables.PV_REJECTS },
          });
          rejectCacheDispatch({
            type: "START_POLLING",
            payload: {
              filter: {
                filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL,
                criteria: {
                  mpidToSymbols: selectedMpids.reduce((acc, mpid) => {
                    acc[mpid.label] = [symbol];
                    return acc;
                  }, {} as any),
                  rejectText: reject,
                  date: selectedDateFilter.value as DATE_FILTER,
                },
              },
            },
          });
        }
      },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids, tableDispatch]
  );

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const { dataArrayCounts, pieChartData, totalRejectCount, totalPriceCount, totalOverrideCount } =
    useMemo(() => {
      const {
        dataArrayCounts,
        pieChartData,
        totalRejectCount,
        totalPriceCount,
        totalOverrideCount,
      } = Object.entries(rejectCountState.counts).reduce(
        (acc, [symbol, count]) => {
          const subRows = [];
          if (count.priceCount > 0) {
            subRows.push({
              label: REJ_PRICE_OO_RANGE,
              value: (
                <ArrayButton onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_RANGE })}>
                  {/* <CircleButton ghost={true} size="xs">
                  <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
                </CircleButton> */}
                  {count.priceCount}
                </ArrayButton>
              ),
              count: count.priceCount,
            });
          }
          if (count.priceOverrideCount > 0) {
            subRows.push({
              label: REJ_PRICE_OO_OVERRIDE_RANGE,
              value: (
                <ArrayButton
                  onClick={handleSetFilter({ symbol, reject: REJ_PRICE_OO_OVERRIDE_RANGE })}
                >
                  {/* <CircleButton ghost={true} size="xs">
                  <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
                </CircleButton> */}
                  {count.priceOverrideCount}
                </ArrayButton>
              ),
              count: count.priceOverrideCount,
            });
          }
          acc.dataArrayCounts.push({
            label: symbol,
            value: count.priceCount + count.priceOverrideCount,
            subRows,
          });
          acc.pieChartData[0].value += count.priceCount;
          acc.pieChartData[1].value += count.priceOverrideCount;
          acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
          acc.totalPriceCount += count.priceCount;
          acc.totalOverrideCount += count.priceOverrideCount;
          return acc;
        },
        {
          dataArrayCounts: [],
          pieChartData: [
            { name: REJ_PRICE_OO_RANGE, value: 0 },
            { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
          ],
          totalRejectCount: 0,
          totalPriceCount: 0,
          totalOverrideCount: 0,
        } as {
          dataArrayCounts: DataArrayCount[];
          pieChartData: PieChartData;
          totalRejectCount: number;
          totalPriceCount: number;
          totalOverrideCount: number;
        }
      );
      dataArrayCounts.push({
        label: "<ALL>",
        value: totalRejectCount,
        subRows: [
          {
            label: REJ_PRICE_OO_RANGE,
            value: (
              <ArrayButton onClick={handleSetFilter({ symbol: "*", reject: REJ_PRICE_OO_RANGE })}>
                {/* <CircleButton ghost={true} size="xs">
                <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
              </CircleButton> */}
                {totalPriceCount}
              </ArrayButton>
            ),
            count: totalPriceCount,
          },
          {
            label: REJ_PRICE_OO_OVERRIDE_RANGE,
            value: (
              <ArrayButton
                onClick={handleSetFilter({ symbol: "*", reject: REJ_PRICE_OO_OVERRIDE_RANGE })}
              >
                {/* <CircleButton ghost={true} size="xs">
                <FontAwesomeIcon iconClassName={`fa-arrow-up`} />
              </CircleButton> */}
                {totalOverrideCount}
              </ArrayButton>
            ),
            count: totalOverrideCount,
          },
        ],
      });
      dataArrayCounts.sort((a: DataArrayCount, b: DataArrayCount) => {
        if (a.label === "<ALL>") {
          return -1;
        } else if (b.label === "<ALL>") {
          return 1;
        }
        return b.value - a.value;
      });
      return {
        dataArrayCounts,
        pieChartData,
        totalRejectCount,
        totalPriceCount,
        totalOverrideCount,
      };
    }, [handleSetFilter, rejectCountState.counts]);

  const setSymbolFilter = useCallback((e: any) => {
    setFilterSymbol(e.value);
  }, []);

  const barChartData = useMemo(() => {
    const data = Object.entries(rejectCountState.counts).reduce((acc, [symbol, count]) => {
      acc[symbol] = {
        [REJ_PRICE_OO_RANGE]: count.priceCount,
        [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
      };
      return acc;
    }, {} as { [symbol: string]: { [rejMsg: string]: number } });
    const arrData = Object.entries(data).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    arrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const chartData = [];
    for (let i = 0; i < 5 && i < arrData.length; i++) {
      chartData.push({
        name: arrData[i].symbol,
        values: Object.entries(arrData[i].rejects).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOthers = arrData.slice(5, arrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const vals = Object.values(countOfAllOthers);
    if (vals.length > 0 && vals.reduce((acc, curr) => acc + curr) > 0) {
      chartData.push({
        name: "Other",
        values: Object.entries(countOfAllOthers).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    return chartData;
  }, [rejectCountState.counts]);

  const PORHeader = useCallback(
    () => (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            Parameter Requests
            <Indicator color="success" />
          </ServiceIndicatorWrapper>
        }
      />
    ),
    []
  );

  const createPORs = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const porData: any[] = [];
    const porKeys = new Set();
    rows.forEach(row => {
      const key = `${row[PvRejectModel.ENTRY_MPID]}|${row[PvRejectModel.SYMBOL]}`;
      if (!porKeys.has(key)) {
        porKeys.add(key);
        porData.push({ symbol: row[PvRejectModel.SYMBOL], mpid: row[PvRejectModel.ENTRY_MPID] });
      }
    });

    const createPorSuccess = (data: POR) => {
      NotificationHub.send("success", "POR created successfully");
      tableDispatch({
        type: "ADD_TABLE_DATA_FIRST",
        payload: { table: StandardTables.PV_REQUESTS, data },
      });
    };

    const createPorError = (err: PorServiceErrorResponse) => {
      NotificationHub.send("danger", "Error creating POR(s)", { subtitle: err.message });
    };

    doFetchWrapper(
      formatUrl(
        //user[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.pvrUrl],
        process.env.REACT_APP_URL_PVR_POR_SERVICE,
        "por/saveAll"
      ),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(porData),
      },
      createPorSuccess,
      createPorError
    );
  }, [tableData, tableDispatch]);

  const resubmitPriceRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (result: any) => {
      setIsResubmitting(false);
      NotificationHub.send("success", "Processing reject resubmission with Price Override");
      bulkJobDispatch({ type: "ADD_JOB", payload: result.data });
    };

    const resubmitError = () => {
      setIsResubmitting(false);
      NotificationHub.send("danger", "Error resubmitting rejects for Price Override");
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "bulk/resubmit/priceOverride"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, tableData, tableDispatch]);

  const resubmitOverrideRange = useCallback(() => {
    const rows: PvReject[] = getSelectedRows(tableData[StandardTables.PV_REJECTS]);
    const rejectIds = rows.map(row => row[PvRejectModel.ID]);
    const resubmitSuccess = (result: any) => {
      setIsResubmitting(false);
      NotificationHub.send("success", "Processing reject resubmission with Price Override");
      bulkJobDispatch({ type: "ADD_JOB", payload: result.data });
    };

    const resubmitError = () => {
      setIsResubmitting(false);
      NotificationHub.send("danger", "Error resubmitting rejects for Price Override");
    };
    setIsResubmitting(true);
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_ACT_WS, "bulk/resubmit/priceValidationOff"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify({ rejectIds }),
      },
      resubmitSuccess,
      resubmitError
    );
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_REJECTS },
    });
  }, [bulkJobDispatch, tableData, tableDispatch]);

  const { uniqueSelectedPorRejects, numRejectsSelected } = useMemo(() => {
    const symbolSet = new Set<string>();
    const selected = getSelectedRows(tableData[StandardTables.PV_REJECTS]) as PvReject[];
    const uniqueSelectedPorRejects = selected.reduce((acc, curr) => {
      if (curr) {
        if (!symbolSet.has(curr.symbol)) {
          acc++;
          symbolSet.add(curr.symbol);
        }
      } else {
        console.warn("Invalid row found in selected rows: " + curr);
      }
      return acc;
    }, 0);
    return { uniqueSelectedPorRejects, numRejectsSelected: selected.length };
  }, [tableData]);

  const ResubmitButttonConfirm = useCallback(() => {
    return (
      <Header size={3}>Are you sure you want to Resubmit {numRejectsSelected} Reject(s)?</Header>
    );
  }, [numRejectsSelected]);

  const PorButttonConfirm = useCallback(() => {
    return <PorButtonHeader count={uniqueSelectedPorRejects} />;
  }, [uniqueSelectedPorRejects]);

  const RejectButtons = useMemo(() => {
    const tableButtons = [
      {
        icon: "arrow-up",
        text: TableButtonLabel.RESUBMIT,
        actionId: TableButtonAction.PVR_RESUBMIT,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        header: ResubmitButttonConfirm,
        onClick:
          rejectCacheState.filter?.criteria.rejectText === REJ_PRICE_OO_OVERRIDE_RANGE
            ? resubmitOverrideRange
            : resubmitPriceRange,
        isDisabled: isResubmitting,
        loading: isResubmitting,
      },
      {
        icon: "lock-open",
        text: TableButtonLabel.POR,
        actionId: TableButtonAction.POR,
        canConfirm: true,
        header: PorButttonConfirm,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: createPORs,
        isDisabled: !porWindowState.isWindowOpen,
        disabledMessage: !porWindowState.isWindowOpen
          ? "Parameter override request window is closed"
          : undefined,
      },
    ];
    return [
      <TableButtons table={StandardTables.PV_REJECTS} buttons={tableButtons} />,
      <CloseButton table={StandardTables.PV_REJECTS} />,
      <RecapButton table={StandardTables.PV_REJECTS} />,
    ];
  }, [
    ResubmitButttonConfirm,
    rejectCacheState.filter,
    resubmitOverrideRange,
    resubmitPriceRange,
    isResubmitting,
    PorButttonConfirm,
    createPORs,
    porWindowState.isWindowOpen,
  ]);

  const handlePieChartClick = useCallback(
    (event: React.MouseEvent, data: { name: string }) => {
      if (Array.isArray(selectedMpids) && selectedDateFilter?.value) {
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_REJECTS },
        });
        rejectCacheDispatch({
          type: "START_POLLING",
          payload: {
            filter: {
              filterBy: REJECT_CACHE_FILTER_BY.MPID,
              criteria: {
                mpids: selectedMpids.map(mpid => mpid.value as string),
                rejectText: data.name,
                date: selectedDateFilter.value as DATE_FILTER,
              },
            },
          },
        });
      }
    },
    [rejectCacheDispatch, selectedDateFilter.value, selectedMpids, tableDispatch]
  );

  const handleBarChartClick = useCallback(
    (event: any, data: any) => {
      const reject = data.name === "Price" ? REJ_PRICE_OO_RANGE : REJ_PRICE_OO_OVERRIDE_RANGE;
      handleSetFilter({ symbol: data.name, reject })();
    },
    [handleSetFilter]
  );

  const RejectBarChart = useMemo(() => {
    return (
      <FlipChartContainer>
        <ChartContainer>
          <BarChart data={barChartData} legend={true} onClick={handleBarChartClick} />
        </ChartContainer>
      </FlipChartContainer>
    );
  }, [barChartData, handleBarChartClick]);

  const RejectDataArray = useMemo(() => {
    if (totalRejectCount > 0) {
      let filteredCounts = filterForDataArray(dataArrayCounts, filterSymbol);
      return (
        <>
          <FormField
            value={filterSymbol}
            onChange={setSymbolFilter}
            size={"sm"}
            placeholder="Symbol"
          />
          <ArrayWrapper>
            <DataArray data={filteredCounts} expandable={true} boldValues={false} />
          </ArrayWrapper>
        </>
      );
    }
    return <React.Fragment></React.Fragment>;
  }, [totalRejectCount, dataArrayCounts, filterSymbol, setSymbolFilter]);

  const toggleFlipped = useCallback(() => {
    setFlipped(!isFlipped);
  }, [isFlipped]);

  const RejectTableHeader = useCallback(() => {
    let title = "Price Rejects";
    if (pvMemberData.filter?.reject === REJ_PRICE_OO_OVERRIDE_RANGE) {
      title = "Price Override Rejects";
    }
    if (pvMemberData.filter?.symbol) {
      title += ` for ${pvMemberData.filter.symbol}`;
    }

    return (
      <StandardHeader
        title={
          <ServiceIndicatorWrapper>
            {title}
            <Indicator color="success" />
          </ServiceIndicatorWrapper>
        }
      />
    );
  }, [pvMemberData.filter]);

  return (
    <Middle>
      <Grid>
        <FieldWrapper>
          <FieldLoop
            isReactFragment={true}
            form={Forms.PV_MONITOR_MEMBER_TOP}
            fields={PVMemberMonitorTop}
            classNames={undefined}
            augmentOnChange={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
          />
        </FieldWrapper>

        <ChartSection>
          <LoaderWrapper>
            <Loader isLoading={rejectCountState.isLoading}>
              {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
              <ChartWrapper>
                <Header size={3}>
                  <ServiceIndicatorWrapper>
                    Rejects By Reason - {totalRejectCount}
                    <Indicator color="success" />
                  </ServiceIndicatorWrapper>
                </Header>
                <ChartContainer>
                  <PieChartWrapper>
                    <PieChart
                      data={pieChartData}
                      donut={false}
                      legend={false}
                      ratio="1:1"
                      onClick={handlePieChartClick}
                    />
                  </PieChartWrapper>
                </ChartContainer>
              </ChartWrapper>
            </Loader>
          </LoaderWrapper>
        </ChartSection>

        <ArraySection>
          <LoaderWrapper>
            <Loader isLoading={rejectCountState.isLoading}>
              {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
              <FlipCard
                isFlipped={isFlipped}
                front={
                  <RejectsFlipCardSection
                    totalRejectCount={totalRejectCount}
                    toggleFlipped={toggleFlipped}
                  >
                    {RejectDataArray}
                  </RejectsFlipCardSection>
                }
                back={
                  <RejectsFlipCardSection
                    totalRejectCount={totalRejectCount}
                    toggleFlipped={toggleFlipped}
                  >
                    {RejectBarChart}
                  </RejectsFlipCardSection>
                }
              />
            </Loader>
          </LoaderWrapper>
        </ArraySection>

        <TimerWrapper>
          <PorWindowTimer marginTopPx={1} />
        </TimerWrapper>
        <RequestTableWrapper>
          <StandardTable
            Header={undefined}
            table={StandardTables.PV_REQUESTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={undefined}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={false}
            header={PORHeader}
            isFilterable={false}
          />
        </RequestTableWrapper>

        <TableWrapper>
          <StandardTable
            header={RejectTableHeader}
            table={StandardTables.PV_REJECTS}
            enableLayoutExport={true}
            isSingleSelect={false}
            isColumnsVirtualized={false}
            isFilterable={true}
            subHeader={undefined}
            hideRowCount={false}
            hideSelectedCount={false}
            hideQueryDate={undefined}
            headerMenu={RejectButtons}
            exportCallback={undefined}
            exportFileName={undefined}
            additionalRowClick={undefined}
            isEditableTableEnabled={undefined}
            style={undefined}
            isRowSelectEnabled={undefined}
            Header={undefined}
          />
        </TableWrapper>
      </Grid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};
