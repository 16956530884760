import { FormLayoutProvider, FormProvider } from "components/form";
import {
  AggregateLimitProvider,
  HKCacheProvider,
  LimitMonitorProvider,
} from "components/limitMonitor";
import { RefDataProvider } from "components/refData";
import { RightProvider } from "components/right";
import { EqrcRuleProvider, PtraEmailContext, SettingsProvider } from "components/settings";
import EqrcInputProvider from "components/settings/eqrc/exchangeMpidPort/eqrcInputContext";
import { StandardTableProvider } from "components/standardTable";
import { StatsProvider } from "components/stats";
import { TabTableProvider } from "components/tabTable";
import { RecapProvider } from "components/tradeDetailRecap";
import { UploadProvider } from "components/upload";
import { BellProvider } from "../topBar";
import { UploadCacheProvider } from "../upload";
import { useUserContext } from "../user";
import React, { useEffect, useState } from "react";
import { PtraConfigProvider } from "components/settings/ptra/configContext";
import { PtraAlertProvider } from "components/topBar/alerts/ptra/alertCache";
import EqrcCacheProvider from "components/eqrc/dataCacheContext";
import { NEFThemeProvider } from "@nef/core";
import {
  FieldNames,
  WORKX_NOTIFICATION_PLACEMENT_VALUES,
  WORKX_THEME_VALUE,
} from "components/fields";
import { getCookie } from "utils/js.utils";
import { NotificationContainer } from "components/notificationContainer";
import { useUserPreferenceContext } from "components/user/preferenceContext";
import { USER_PREFEREENCE_NAME } from "components/user/initialDataModelTS";
import { PvMemberProvider } from "components/pvRejects/member/context";
import PvrEmailContext from "components/settings/pvr/email/context";
import { PvrConfigProvider } from "components/settings/pvr/configContext";
import { PorCacheProvider } from "components/pvRejects/porCache";
import { PorWindowCacheProvider } from "components/pvRejects/porWindowCache";
import { AlertCacheProvider } from "components/topBar/alerts/pvr/alertCache";
import { RejectCacheProvider } from "components/pvRejects/member/rejectCache";
import { RejectCountProvider } from "components/pvRejects/member/rejectCountCache";
import { PorCountProvider } from "components/pvRejects/porCountCache";
import { BulkJobProvider } from "components/topBar/bulkJob/context";

const AllTheContexts = ({ children }) => {
  const [user] = useUserContext();
  const [userPref] = useUserPreferenceContext();
  const [theme, setTheme] = useState(getCookie(FieldNames.workXTheme) || WORKX_THEME_VALUE.LIGHT);
  const [notificationPlacement, setNotificationPlacement] = useState(
    getCookie(FieldNames.workXNotificationPlacement) ||
      WORKX_NOTIFICATION_PLACEMENT_VALUES.TOP_RIGHT
  );

  useEffect(() => {
    if (userPref[USER_PREFEREENCE_NAME.THEME] && userPref[USER_PREFEREENCE_NAME.THEME] !== theme) {
      setTheme(userPref[USER_PREFEREENCE_NAME.THEME]);
    }
    if (
      userPref[USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT] &&
      userPref[USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT] !== notificationPlacement
    ) {
      setNotificationPlacement(userPref[USER_PREFEREENCE_NAME.NOTIFICATION_PLACEMENT]);
    }
  }, [notificationPlacement, theme, userPref]);

  return (
    <NEFThemeProvider mode={theme}>
      <SettingsProvider>
        <StatsProvider>
          <StandardTableProvider>
            <TabTableProvider>
              <BulkJobProvider>
                <UploadProvider>
                  <UploadCacheProvider>
                    <FormProvider theme={theme} notificationPlacement={notificationPlacement}>
                      <RefDataProvider>
                        <BellProvider>
                          <EqrcRuleProvider>
                            <EqrcCacheProvider>
                              <EqrcInputProvider>
                                <PtraConfigProvider>
                                  <PtraAlertProvider>
                                    <PtraEmailContext userId={user.userId}>
                                      <PvrConfigProvider>
                                        <AlertCacheProvider>
                                          <PorWindowCacheProvider>
                                            <PorCountProvider>
                                              <PorCacheProvider>
                                                <RejectCountProvider>
                                                  <RejectCacheProvider>
                                                    <PvrEmailContext userId={user.userId}>
                                                      <AggregateLimitProvider>
                                                        <LimitMonitorProvider>
                                                          <HKCacheProvider>
                                                            <RightProvider>
                                                              <PvMemberProvider>
                                                                <FormLayoutProvider>
                                                                  <RecapProvider>
                                                                    {children}
                                                                    <NotificationContainer
                                                                      position={
                                                                        notificationPlacement
                                                                      }
                                                                    />
                                                                  </RecapProvider>
                                                                </FormLayoutProvider>
                                                              </PvMemberProvider>
                                                            </RightProvider>
                                                          </HKCacheProvider>
                                                        </LimitMonitorProvider>
                                                      </AggregateLimitProvider>
                                                    </PvrEmailContext>
                                                  </RejectCacheProvider>
                                                </RejectCountProvider>
                                              </PorCacheProvider>
                                            </PorCountProvider>
                                          </PorWindowCacheProvider>
                                        </AlertCacheProvider>
                                      </PvrConfigProvider>
                                    </PtraEmailContext>
                                  </PtraAlertProvider>
                                </PtraConfigProvider>
                              </EqrcInputProvider>
                            </EqrcCacheProvider>
                          </EqrcRuleProvider>
                        </BellProvider>
                      </RefDataProvider>
                    </FormProvider>
                  </UploadCacheProvider>
                </UploadProvider>
              </BulkJobProvider>
            </TabTableProvider>
          </StandardTableProvider>
        </StatsProvider>
      </SettingsProvider>
    </NEFThemeProvider>
  );
};
export default AllTheContexts;
