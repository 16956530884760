import { PVR_ALERT_MODEL } from "components/topBar/alerts/pvr/constants";
import styled from "styled-components";
import { Form } from "types";

export const ADD_EMAIL_BTN_ID = "ADD_PTRA_EMAIL_FORM_BUTTON";

export const AlertForm = styled.form`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
`;

export const AlertEditForm = styled.form`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 1rem;
  }

  > * > * {
    margin-bottom: 0px !important;
  }
`;

export const Divider = styled.div``;

export const SectionHeader = styled.h5`
  margin: 0px 0px 1rem 0px !important;
  font-size: 1rem;
  font-weight: bold;
`;

export const SectionFieldsWrapper = styled.div`
  display: grid;
  grid-gap: 0.25rem;
`;

export interface PtraFieldSection {
  form: Form;
}

export const PVR_ALERT_TYPE = {
  POR_STATUS: 1,
  PVR_RESUBMIT: 2,
  POR_WINDOW: 3,
};

export enum POR_STATUS {
  PENDING = "p",
  APPROVED = "a",
  DENIED = "d",
  EXPIRED = "e",
}

export enum PVR_ALERT_LABELS {
  POR_CREATED = "New Parameter Override Request",
  POR_APPROVED = "Parameter Override Approved",
  POR_DENIED = "Parameter Override Denied",
  POR_EXPIRED = "Parameter Override Expired",
}

export const getPvrAlertLabel = (alert: PVR_ALERT_MODEL) => {
  switch (alert.alertType) {
    case PVR_ALERT_TYPE.POR_STATUS:
      switch (alert.status) {
        case POR_STATUS.PENDING:
          return PVR_ALERT_LABELS.POR_CREATED;
        case POR_STATUS.APPROVED:
          return PVR_ALERT_LABELS.POR_APPROVED;
        case POR_STATUS.DENIED:
          return PVR_ALERT_LABELS.POR_DENIED;
        case POR_STATUS.EXPIRED:
          return PVR_ALERT_LABELS.POR_EXPIRED;
        default:
          return "Invalid Alert";
      }
    case PVR_ALERT_TYPE.PVR_RESUBMIT:
      return "Price Validation Resubmit";
    case PVR_ALERT_TYPE.POR_WINDOW:
      return "Parameter Override Window";
    default:
      return "Invalid Alert";
  }
};
