import { getColor } from "@nef/style-utils";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { ReadBtn, ReadText, SharedBtn } from "./pvrAlertBody";
import React, { useCallback, useMemo } from "react";
import { useAlertCacheState, useAlertCacheDispatch } from "./alertCache";
import { NotificationHub } from "@nef/core";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { useAlertDispatch } from "../context";
import { GENERIC_ALERT_ITEM } from "../genericAlerts";
import { LoadingIcon } from "components/loadingIcon";

export enum ALERT_FILTER {
  ALL = "All",
  READ = "Read",
  UNREAD = "Unread",
}

const FilterBtn = styled.button<{ $isActive: boolean }>`
  ${props => `
    ${SharedBtn}
    color: ${getColor("primary", 500)(props)};
    text-decoration: ${props.$isActive ? "underline" : "none"};
    font-weight: ${props.$isActive ? "bold" : "normal"};

    &:hover {
      text-decoration: underline;
      color: ${getColor("primary", 400)(props)}
    }
  `}
`;

const CountWrapper = styled.div`
  color: ${getColor("gray", 300)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type AlertFooterProps = {
  activeFilter: ALERT_FILTER;
  onFilterChange?: (filter: ALERT_FILTER) => void;
};

export const AlertFooter: React.FC<AlertFooterProps> = ({ activeFilter, onFilterChange }) => {
  const pvrAlertData = useAlertCacheState();
  const pvrAlertDispatch = useAlertCacheDispatch();
  const alertDispatch: any = useAlertDispatch();

  const handleFilterClick = (value: ALERT_FILTER) => () => {
    if (typeof onFilterChange === "function") {
      onFilterChange(value);
    }
  };

  const handleMarkAllAsRead = useCallback(() => {
    pvrAlertDispatch({
      type: "SET_MARK_ALL_LOADING",
      payload: true,
    });
    const cb = (json: any) => {
      pvrAlertDispatch([
        {
          type: "SET_READ",
          payload: json,
        },
        {
          type: "SET_MARK_ALL_LOADING",
          payload: false,
        },
      ]);
      alertDispatch({ type: "START_FORCE_REFRESH" });
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking all as unread");
      pvrAlertDispatch({
        type: "SET_MARK_ALL_LOADING",
        payload: false,
      });
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "savepvralertsread"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(
          pvrAlertData.activeAlerts.map(alertItem => alertItem[GENERIC_ALERT_ITEM.ID])
        ),
      },
      cb,
      errorCb
    );
  }, [alertDispatch, pvrAlertData.activeAlerts, pvrAlertDispatch]);

  const handleMarkAllAsUnread = useCallback(() => {
    pvrAlertDispatch({
      type: "SET_MARK_ALL_LOADING",
      payload: true,
    });
    const cb = () => {
      pvrAlertDispatch({
        type: "SET_ALL_UNREAD",
        payload: pvrAlertData.activeAlerts,
      });
      pvrAlertDispatch({
        type: "SET_MARK_ALL_LOADING",
        payload: false,
      });
      alertDispatch({ type: "START_FORCE_REFRESH" });
    };
    const errorCb = () => {
      NotificationHub.send("danger", "Error marking all as unread");
    };
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "deleteunreadpvralerts"),
      {
        method: "post",
        headers: getHeaders(),
        body: JSON.stringify(
          pvrAlertData.activeAlerts.map(alertItem => alertItem[GENERIC_ALERT_ITEM.ID])
        ),
      },
      cb,
      errorCb
    );
  }, [alertDispatch, pvrAlertData.activeAlerts, pvrAlertDispatch]);

  const isRead = useMemo(() => {
    return pvrAlertData.activeAlerts.reduce((acc, curr) => {
      return acc && !!pvrAlertData.readMap[curr[GENERIC_ALERT_ITEM.ID]];
    }, true);
  }, [pvrAlertData]);

  const MarkAsRead = useMemo(() => {
    let onClick = undefined;
    if (!pvrAlertData.isMarkAllLoading) {
      if (isRead) {
        onClick = handleMarkAllAsUnread;
      } else {
        onClick = handleMarkAllAsRead;
      }
    }
    return (
      <ReadBtn
        $alignSelf="center"
        $justifySelf="end"
        $isRead={isRead}
        onClick={onClick}
        $isLoading={pvrAlertData.isMarkAllLoading}
      >
        <LoadingIcon isLoading={pvrAlertData.isMarkAllLoading} />
        <ReadText>{isRead ? "Mark All Unread" : "Mark All Read"}</ReadText>
      </ReadBtn>
    );
  }, [isRead, handleMarkAllAsUnread, handleMarkAllAsRead, pvrAlertData.isMarkAllLoading]);

  return (
    <Footer>
      <CountWrapper>
        <FilterBtn
          onClick={handleFilterClick(ALERT_FILTER.ALL)}
          $isActive={activeFilter === ALERT_FILTER.ALL}
        >{`${ALERT_FILTER.ALL} (${Object.keys(pvrAlertData.alerts).length})`}</FilterBtn>
        {" | "}
        <FilterBtn
          onClick={handleFilterClick(ALERT_FILTER.UNREAD)}
          $isActive={activeFilter === ALERT_FILTER.UNREAD}
        >{`${ALERT_FILTER.UNREAD} (${Object.keys(pvrAlertData.unreadAlerts).length})`}</FilterBtn>
        {" | "}
        <FilterBtn
          onClick={handleFilterClick(ALERT_FILTER.READ)}
          $isActive={activeFilter === ALERT_FILTER.READ}
        >{`${ALERT_FILTER.READ} (${Object.keys(pvrAlertData.readAlerts).length})`}</FilterBtn>
      </CountWrapper>
      {MarkAsRead}
    </Footer>
  );
};
