import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormContext, useFormDispatch } from "components/form";
import { getHeaders } from "keycloak";
import React, { useCallback, useState } from "react";
import { SettingsWrapper } from "components/settings";
import {
  getSelectedRows,
  useStandardTableContext,
  useStandardTableDispatch,
} from "components/standardTable";
import { formatUrl } from "utils/js.utils";
import { StandardTables } from "wksConstants";
import { doFetchWrapper } from "../../../../network";
import { PvrEmailManagerTable, PvrEmailModal, usePvrEmailDispatch } from "./";
import { PVR_EMAIL_ENTITY } from "./fields";
import { usePvrConfigDispatch } from "../configContext";

export const PvrEmailManager = () => {
  const [tableData] = useStandardTableContext();
  const tableDispatch = useStandardTableDispatch();
  const [formData] = useFormContext();
  const formDispatch = useFormDispatch();
  const emailDispatch = usePvrEmailDispatch();
  const configDispatch = usePvrConfigDispatch();
  const [{ isModalVisible, selectedEmail }, setEmailModal] = useState({
    isModalVisible: false,
    selectedEmail: false,
  });

  const handleClickNew = useCallback(() => {
    setEmailModal({ isModalVisible: true, selectedEmail: false });
  }, []);

  const handleClickEdit = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.PVR_EMAIL_MANAGER]);
    setEmailModal({
      isModalVisible: true,
      selectedEmail: selected[0][PVR_EMAIL_ENTITY.recipientEmail],
    });
  }, [tableData]);

  const handleCloseModal = useCallback(() => {
    formDispatch({ type: "RESET_FORM", payload: { form: Forms.PVR_EMAIL_MANAGER } });
    setEmailModal({ isModalVisible: false, selectedEmail: false });
  }, [formDispatch]);

  const enableDisableCallback = useCallback(
    actionText => data => {
      NotificationHub.send("success", `${actionText} email(s)`);
      const tableActions = data.map(row => ({
        type: "UPDATE_TABLE_ROW_WITH_ID",
        payload: {
          table: StandardTables.PVR_EMAIL_MANAGER,
          row,
          idField: PVR_EMAIL_ENTITY.id,
        },
      }));
      configDispatch({ type: "SET_REQUESTING", payload: true });
      tableActions.push({
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.PVR_EMAIL_MANAGER, isLoading: false },
      });
      tableActions.push({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PVR_EMAIL_MANAGER },
      });
      tableDispatch(tableActions);
      emailDispatch({
        type: "START_REFRESH",
      });
    },
    [configDispatch, tableDispatch, emailDispatch]
  );

  const enableDisableErrorCallback = useCallback(
    actionText => () => {
      tableDispatch([
        {
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.PVR_EMAIL_MANAGER, isLoading: false },
        },
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PVR_EMAIL_MANAGER },
        },
      ]);
      NotificationHub.send("danger", `Failed to ${actionText} email(s)`);
    },
    [tableDispatch]
  );

  const handleClickDisable = useCallback(() => {
    const selected = getSelectedRows(tableData[StandardTables.PVR_EMAIL_MANAGER]);
    if (Array.isArray(selected)) {
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, "alert/email/disableEmails"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(selected.map(row => row.id)),
        },
        enableDisableCallback("Deleted"),
        enableDisableErrorCallback("delete")
      );
    }
  }, [enableDisableCallback, enableDisableErrorCallback, tableData]);

  return (
    <>
      <SettingsWrapper
        header="Email Manager"
        subheader="Manage emails for Price Validation Reject Alerts"
        errorMessage={formData[Forms.SETTINGS_TR.key].globalErrorMessage}
        hasSave={false}
        disableFormWrapperClass={true}
      >
        <PvrEmailManagerTable
          onClickNew={handleClickNew}
          onClickEdit={handleClickEdit}
          onClickDisable={handleClickDisable}
        />
      </SettingsWrapper>
      <PvrEmailModal
        isVisible={isModalVisible}
        selectedEmail={selectedEmail}
        onClose={handleCloseModal}
      />
    </>
  );
};
