import { memo } from "react";
import { useFieldOptionContext } from "./loadingContext";
import WorkXDropdown from "./workXDropdown";

const WorkXDropdownWrapper = props => {
  const [loadingData] = useFieldOptionContext();

  return (
    <WorkXDropdown
      key={props.id ? props.id : props.name}
      {...props}
      portalRef={props.portalRef}
      loadingMap={loadingData.loadingMap[props.id]?.has(props.form.id)}
    />
  );
};

export default memo(WorkXDropdownWrapper);
