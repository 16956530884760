import { useEffect, useReducer, useState } from "react";
import { DispatchFn, DispatchFnSwitch, ContextProviderProps } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { NotificationHub } from "@nef/core";

type BulkJobState = {
  //   queue: any;
  processing: any;
};

type BulkJobAction = { type: any; payload?: any };

const FACTORY = new ContextFactory<BulkJobState, BulkJobAction>();

const DEFAULT_STATE: BulkJobState = {
  //   queue: {},
  processing: {},
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<BulkJobState, BulkJobAction> = (
  prevState: BulkJobState,
  action: BulkJobAction
) => {
  switch (action.type) {
    case "ADD_JOB": {
      const newProcessing = { ...prevState.processing, [action.payload.id]: action.payload };
      return { ...prevState, processing: newProcessing };
    }
    case "UPDATE_JOBS": {
      const newProcessing = action.payload.reduce((acc: any, curr: any) => {
        if (
          curr.countAccepted + curr.countRejected + curr.countFailed + curr.countUnknown ===
          curr.numItems
        ) {
          const subtitle = [];
          if (curr.countAccepted > 0) {
            subtitle.push(<span>{curr.countAccepted} Accepted</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countRejected > 0) {
            subtitle.push(<span>{curr.countRejected} Rejected</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countFailed > 0) {
            subtitle.push(<span>{curr.countFailed} Failed</span>);
            subtitle.push(<br></br>);
          }
          if (curr.countUnknown > 0) {
            subtitle.push(<span>{curr.countUnknown} Unknown</span>);
            subtitle.push(<br></br>);
          }
          NotificationHub.send("success", "Bulk Job Complete", {
            subtitle: <>{subtitle}</>,
          });
        } else {
          acc[curr.id] = curr;
        }
        return acc;
      }, {} as any);
      return { ...prevState, processing: newProcessing };
    }
    default: {
      return prevState;
    }
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<BulkJobState, BulkJobAction>(DISPATCH_FN_SWITCH);

interface BulkJobProviderProps extends ContextProviderProps {}

export const BulkJobProvider: React.FC<BulkJobProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<BulkJobState, BulkJobAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );
  const [interval, setPollingInterval] = useState<NodeJS.Timeout>();
  const [prevKey, setPrevKey] = useState("");

  useEffect(() => {
    const ids = Object.keys(state.processing);
    const key = ids.join("|");
    if (key !== prevKey && ids.length > 0) {
      setPollingInterval(
        setInterval(() => {
          doFetchWrapper(
            formatUrl(process.env.REACT_APP_URL_ACT_WS, "bulk/poll"),
            {
              method: "post",
              headers: getHeaders(),
              body: JSON.stringify(ids),
            },
            (bulkJobs: any) => {
              dispatch({ type: "UPDATE_JOBS", payload: bulkJobs });
            },
            () => NotificationHub.send("danger", "Error polling for Bulk Job Status")
          );
        }, 3000)
      );
      setPrevKey(key);
    }
    if (ids.length === 0) {
      clearInterval(interval);
    }
  }, [interval, prevKey, state.processing]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useBulkJobDispatch =
  FACTORY.createContextHook<React.Dispatch<BulkJobAction | BulkJobAction[]>>(DISPATCH_CONTEXT);
export const useBulkJobState = FACTORY.createContextHook<BulkJobState>(STATE_CONTEXT);
