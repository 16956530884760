import { Button, CSSGrid } from "@nef/core";
import React, { EffectCallback, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useLayoutContext } from "components/context";
import { Forms } from "components/fields";
import { Middle } from "components/middle";
import { Table } from "./table";
import { ActionsModal } from "./actions";
import { SettingNames, StandardTables } from "wksConstants";
import { useFormContext } from "components/form";
import { EqrcFields } from "components/settings/eqrc/constants";
import useWindowFocus from "use-window-focus";
import { useEqrcCacheDispatch } from "./dataCacheContext";
import { useEqrcRuleDispatch } from "components/settings/eqrc/context";
import { EqrcRuleStatus } from "components/settings/eqrc/constants2";
import { ChartsData } from "./eqrc.types";
import { network } from "components/settings";
import { useUserContext } from "components/user";
import { SelectOption } from "types";
import { EqrcExchangeMpidPort } from "components/settings/eqrc/exchangeMpidPort/exchangeMpidPort";
import { PageLoader } from "components/content/pageLoader";
import { ViewActions } from "viewConstants";
import { EqrcRules } from "components/settings/eqrc/types.consts";
import { useStandardTableDispatch } from "components/standardTable";
import { Charts } from "./charts";

const StyledGrid = styled(CSSGrid)`
  & > div {
    margin-right: 0 !important;
  }
`;

const StyledActionsButton = styled(Button)`
  grid-column: span 5;
  max-width: 10rem;
`;

type Args = {
  mpid: SelectOption | undefined;
  port: SelectOption[] | undefined;
};

const EQRC = () => {
  const eqrcCacheDispatch = useEqrcCacheDispatch();
  const [, layoutDispatch] = useLayoutContext();
  const [formData] = useFormContext();
  const eqrcForm = formData[Forms.EQRC_TOP.key].fields;
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useUserContext();
  const windowFocused = useWindowFocus();
  const eqrcRuleDispatch = useEqrcRuleDispatch();
  const [args, setArgs] = useState<Args>({
    port: undefined,
    mpid: undefined,
  });
  const tableDispatch = useStandardTableDispatch();

  useEffect(() => {
    const requestMap = {
      [EqrcRules.grossExposure]: new Set([EqrcRuleStatus.active]),
    };
    tableDispatch({
      type: "SET_IS_LOADING",
      payload: { table: StandardTables.EQRC_MONITORING, isLoading: true },
    });
    eqrcRuleDispatch([
      {
        type: "SET_REQUESTING",
        payload: { isRequesting: true, requestMap },
      },
      {
        type: "SET_POLLING",
        payload: { isPolling: true, pollMap: requestMap },
      },
    ]);
    return () => {
      eqrcRuleDispatch({
        type: "SET_POLLING",
        payload: { isPolling: false, pollMap: requestMap },
      });
    };
  }, [eqrcRuleDispatch, tableDispatch]);

  useEffect(() => {
    eqrcCacheDispatch({ type: "SET_SHOULD_REQUEST", payload: true });

    if (windowFocused === true) {
      eqrcCacheDispatch({ type: "SET_IS_WINDOW_FOCUSED", payload: true });
    } else {
      eqrcCacheDispatch([{ type: "SET_IS_WINDOW_FOCUSED", payload: false }]);
    }

    return () => {
      eqrcCacheDispatch([
        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { shouldMake: true, view: "table" },
        },

        { type: "SET_IS_REQUESTING", payload: { view: "table", isRequesting: false } },
        { type: "SET_SHOULD_ABORT", payload: true },
        { type: "SET_SHOULD_REQUEST", payload: false },
        {
          type: "CLEAR_TIMEOUT",
          payload: { view: "table" },
        },
      ]);
    };
  }, [eqrcCacheDispatch, windowFocused]);

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    eqrcCacheDispatch([
      {
        type: "SET_DATA_CHARTS",
        payload: {
          dataToSet: {
            singleOrders: [],
            exposure: [],
          },
        },
      },
    ]);
  }, [eqrcCacheDispatch]);

  const noop = useCallback(() => {}, []);

  useEffect(() => {
    const mpid = eqrcForm[EqrcFields.mpid];
    const ports = eqrcForm[EqrcFields.port];

    const argsMpid = args.mpid;
    const argsPorts = args.port;

    const toSet: Args = { port: undefined, mpid: undefined };
    let shouldSet = false;

    toSet.mpid = mpid;
    toSet.port = ports;

    if (mpid !== undefined && argsMpid?.value !== mpid.value) {
      shouldSet = true;
    }

    const mappedArgsPorts = (argsPorts || []).map((a: SelectOption) => a.value);
    const mappedPorts = (ports || []).map((a: SelectOption) => a.value);
    const portsHaveChanged =
      mappedArgsPorts.length !== mappedPorts.length ||
      mappedArgsPorts.some(a => !mappedPorts.includes(a));

    if (portsHaveChanged && mpid !== undefined) {
      shouldSet = true;
    }

    if (shouldSet) {
      network().writeSettings(
        {
          data: JSON.stringify(toSet),
          name: SettingNames.EQRC_MONITORING_FORM,
          type: SettingNames.EQRC_MONITORING_FORM,
        },
        noop, // onSaveSuccess,
        noop // onSaveError
      );

      setArgs(toSet);
    }
  }, [args.mpid, args.port, eqrcForm, noop, user.username]);

  const Actions = useMemo(() => {
    if (user.allowed.actions[ViewActions.EQRC_MONITOR_SAFETY_SWITCH]) {
      return [
        <StyledActionsButton onClick={toggleModal} size="sm" key="EQRC_Open_Actions_Button">
          Actions
        </StyledActionsButton>,
        <ActionsModal isOpen={isOpen} toggleModal={toggleModal} key="EQRC_Actions_Modal" />,
      ];
    }
  }, [isOpen, toggleModal, user.allowed.actions]);

  if (user.isUserDataLoading) {
    return <PageLoader />;
  }

  return (
    <Middle>
      <StyledGrid rows={"30px 40% auto"} gap={"1rem"} cols={"repeat(8,1fr)"}>
        <EqrcExchangeMpidPort
          form={Forms.EQRC_TOP}
          hideExchange={true}
          hideClearButton={true}
          key="EQRC_Exchange_MPID_Port"
        />
        {Actions}
        <Charts key="EQRC_Charts" />
        <Table key="EQRC_Table" />
      </StyledGrid>
    </Middle>
  );
};

export default EQRC;
