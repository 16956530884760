import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { getColor } from "@nef/style-utils";
import { Views } from "../../viewConstants";
import { useUserContext } from "../user";
import { ptraEmailPath, pvrEmailPath } from ".";
import { FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";
import { RequestResult } from "wksConstants";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const fontSize = "1rem";
const fontWeight = 400;

const Parent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  font-size: ${fontSize};
  font-weight: ${fontWeight};

  &:hover {
    text-decoration: none;
    background-color: ${getColor("gray", 50)};
    color: ${getColor("gray", 900)};
  }
`;

const StyledLink = styled(Link)`
  background-color: ${props => {
    if (props.isActive) {
      return getColor("primary", 500)(props);
    } else {
      return "none";
    }
  }};

  color: ${props => {
    if (props.$isDisabled) {
      return getColor("gray", 200)(props);
    } else if (props.isActive) {
      return getColor("gray", 0)(props);
    } else {
      return "inherit";
    }
  }};
  cursor: ${props => (props.$isDisabled ? "not-allowed !important" : "inherit")};
  &:hover {
    text-decoration: none !important;
    background-color: ${props => {
      if (props.$isDisabled) {
        return "none";
      } else if (props.isActive) {
        return getColor("primary", 500)(props);
      } else {
        return getColor("gray", 50)(props);
      }
    }};
    color: ${props => {
      if (props.$isDisabled) {
        return getColor("gray", 100)(props);
      } else if (props.isActive) {
        return getColor("gray", 0)(props);
      } else {
        return getColor("gray", 900)(props);
      }
    }};
  }

  ${props => `
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-size: ${fontSize};
    font-weight: ${fontWeight};
  `}
`;

const ChildWrapper = styled.div`
  display: ${({ isOpen }) => `${isOpen ? "grid" : "none"}`};
  grid-template-columns: 1fr;
  padding-left: 1rem;
`;

const ParentLink = ({ parent, children }) => {
  const [isOpen, setOpen] = useState(true);

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Container>
      <Parent to="/" onClick={toggleOpen}>
        {parent.label}
        <FontAwesomeIcon iconClassName={isOpen ? `fa-chevron-up` : `fa-chevron-down`} />
      </Parent>
      <ChildWrapper isOpen={isOpen}>{children}</ChildWrapper>
    </Container>
  );
};

const createLink = (item, isActive, $isDisabled) => {
  return (
    <StyledLink
      key={item.label}
      isActive={isActive}
      to={item.to}
      isDisabled={$isDisabled}
      {...($isDisabled && { onClick: e => e.preventDefault() })}
    >
      {item.label}
    </StyledLink>
  );
};

export const SettingsLinks = () => {
  const [user] = useUserContext();
  let { pathname } = useLocation();
  let { url } = useRouteMatch();

  const items = useMemo(
    () => [
      {
        label: "Trade Reporting",
        to: `${url}/tradereporting`,
        views: [Views.REPORT],
      },
      {
        label: "Real Time Stats / Historical Trends",
        to: `${url}/realtimestats`,
        views: [Views.REAL_TIME_STATS],
      },
      {
        label: "Post - Trade Risk",
        to: `${url}/limitmonitor`,
        views: [
          Views.SETTINGS_LIMO,
          Views.PTR_ALERTS,
          Views.PTR_ALERTS_AGU,
          Views.RISK_SETTINGS_UPLOAD,
        ],
        children: [
          { label: "Limit Setting Input", to: `${url}/limitmonitor`, views: [Views.SETTINGS_LIMO] },
          {
            label: "Limit Setting Export",
            to: `${url}/limitsettingexport`,
            views: [Views.SETTINGS_LIMO],
          },
          {
            label: "Limit Setting Audit",
            to: `${url}/${Views.PTR_AUDIT_TRAIL}`,
            views: [Views.PTR_AUDIT_TRAIL],
          },
          {
            label: "Limit Setting Upload",
            to: `${url}/riskUpload`,
            views: [Views.RISK_SETTINGS_UPLOAD],
          },
          {
            label: "Alerts (Clearer/Correspondent)",
            to: `${url}/riskAlertsTable`,
            views: [Views.PTR_ALERTS],
          },
          {
            label: "Alerts (AGU Participant)",
            to: `${url}/riskAlertsAGUTable`,
            views: [Views.PTR_ALERTS_AGU],
          },
          {
            label: "Email Manager",
            to: `${url}/${ptraEmailPath}`,
            views: [Views.PTR_EMAILS],
          },
        ],
      },
      {
        label: "Price Validation Rejects",
        to: `${url}/pvrAlertsTable`,
        views: [Views.PVR_ALERTS, Views.PVR_EMAIL],
        children: [
          {
            label: "Alerts",
            to: `${url}/pvrAlertsTable`,
            views: [Views.PVR_ALERTS],
          },
          {
            label: "Email Manager",
            to: `${url}/${pvrEmailPath}`,
            views: [Views.PVR_EMAIL],
          },
        ],
      },
      {
        label: "EQRC Rules",
        to: `${url}/eqrcAlertsTable`,
        views: [Views.EQRC_RULES],
        disabled: user.eqrcDataResult !== RequestResult.success,
      },
      {
        label: "User Preferences",
        to: `${url}/user`,
        views: [],
      },
      {
        label: "Keycloak Export",
        to: `${url}/keycloakExport`,
        views: [Views.KEYCLOAK_EXPORT],
      },
    ],
    [url, user.eqrcDataResult]
  );

  const createItems = useCallback(
    items => {
      return items.reduce((acc, item) => {
        let allowed = item.views.length === 0;
        item.views.forEach(view => {
          allowed = allowed || user?.allowed.views[view];
        });
        if (allowed) {
          if (Array.isArray(item.children) && item.children.length > 0) {
            let isChildActive = false;
            const childLinks = createItems(item.children);
            acc.push(
              <ParentLink key={item.label} parent={item} isChildActive={isChildActive}>
                {childLinks}
              </ParentLink>
            );
          } else {
            const re = new RegExp(item.to);
            const isActive = re.test(pathname);
            const isdisabled = item.disabled ? item.disabled : false;
            acc.push(createLink(item, isActive, isdisabled));
          }
        }
        return acc;
      }, []);
    },
    [pathname, user.allowed.views]
  );

  const Links = useMemo(() => {
    return createItems(items);
  }, [createItems, items]);

  return <Container>{Links}</Container>;
};
