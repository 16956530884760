import React, { useMemo } from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircleProgress, useUploadCacheContext } from ".";
import "./index.css";

export const UploadProcessing = () => {
  const [uploadCache] = useUploadCacheContext();

  const files = useMemo(() => {
    return Object.values(uploadCache.processing);
  }, [uploadCache.processing]);

  return files.map(file => (
    <CircleProgress
      key={file.id}
      title={"Upload Processing"}
      id={`processing_${file.id}`}
      filename={file.fileName}
      progress={
        ((file.countAccepted + file.countRejected + file.countFailed) / file.countCsvRows) * 100
      }
    />
  ));
};
