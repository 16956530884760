import { EQRC_RULE_STATUS, EqrcFields, getRowValuesForAction } from "../constants";
import { Forms } from "components/fields/fieldConstants";
import { NotificationHub } from "@nef/core";
import React, { memo, useCallback, useState } from "react";
import {
  getSelectedRows,
  getColumnHeadersAndAccessorsFromColumns,
  useStandardTableContext,
} from "components/standardTable";
import { EQRCAlertConfigTableButtons } from "..";

import { alertHistory, deleteRule, updateRule } from "../network";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import { eqrcAlertHistoryColumns } from "../columns/columnGroups";
import { ALL_BREACHES_LABELS } from "components/eqrc";
import { copyRowToForm } from "../transformers/copyRowToForm";
import { useFormContext } from "components/form";
import { createAlertUpdateBody, createDeleteBody, createHistoryBody } from "../transformers/bodies";

export const AlertTableButtons = memo(
  ({
    setNewAlertModalVisible,
    setEditAlertModalVisible,
    activeTable,
    editStatus,
    activeEditForm,
  }) => {
    const [tableData, tableDispatch] = useStandardTableContext();
    const [userData] = useUserContext();
    const [formData, formDispatch] = useFormContext();
    const [isHistoryLoading, setHistoryLoading] = useState(false);

    const handleRowDelete = useCallback(
      (table, json, cbArgs) => {
        const { eqrcId, body } = cbArgs;
        const { mpid, exchange, port, groupId } = body;

        tableDispatch([
          {
            type: "DELETE_ROW_BY_VALUE",
            payload: {
              table,
              key: EqrcFields.eqrcId,
              value: eqrcId,
            },
          },
          { type: "SET_IS_LOADING", payload: { table, isLoading: false } },
        ]);

        NotificationHub.send("success", "Rule deleted successfully", {
          subtitle: `Exchange: ${exchange || "N/A"}, MPID: ${mpid || "N/A"}, Port ${
            port || "N/A"
          }, Group ID: ${groupId || "N/A"}`,
        });
      },
      [tableDispatch]
    );

    const handleDeleteError = useCallback(
      table => {
        NotificationHub.send("danger", "Delete Failed");
        tableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
      },
      [tableDispatch]
    );

    const handleClickNew = useCallback(() => {
      setNewAlertModalVisible(true);
    }, [setNewAlertModalVisible]);

    const handleClickEditAlert = useCallback(() => {
      const index = Object.keys(tableData[activeTable].selected).find(
        key => tableData[activeTable].selected[key] === true
      );
      const row = tableData[activeTable].data[index];

      copyRowToForm(row, activeEditForm, EQRC_RULE_STATUS.Active, true, null, formDispatch);

      setEditAlertModalVisible(true);
    }, [activeEditForm, activeTable, formDispatch, setEditAlertModalVisible, tableData]);

    const handleClickDelete = useCallback(() => {
      const selected = getSelectedRows(tableData[activeTable]);

      tableDispatch([
        {
          type: "DESELECT_ALL_ROWS",
          payload: { table: activeTable },
        },
        { type: "SET_IS_LOADING", payload: { table: activeTable, isLoading: true } },
      ]);

      selected.forEach(row => {
        let body = createDeleteBody(getRowValuesForAction(row));
        let eqrcId = row[EqrcFields.eqrcId];
        deleteRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          activeTable,
          { eqrcId, body },
          handleRowDelete,
          handleDeleteError
        );
      });
    }, [tableData, activeTable, tableDispatch, userData, handleRowDelete, handleDeleteError]);

    const handleRowUpdate = useCallback(
      (table, json, eqrcId) => {
        const data = { [EqrcFields.eqrcId]: eqrcId, ACTIVE: json };

        tableDispatch({
          type: "UPDATE_TABLE_ROW_WITH_ID",
          payload: {
            table: table,
            row: data,
            idField: EqrcFields.eqrcId,
          },
        });
      },
      [tableDispatch]
    );

    const handleAlertToggle = useCallback(
      (toggleValue, errorHandler) => {
        const selected = getSelectedRows(tableData[activeTable]);

        selected.forEach(row => {
          let body = createAlertUpdateBody(row[EQRC_RULE_STATUS.Active], toggleValue);
          let eqrcId = row[EqrcFields.eqrcId];
          updateRule(
            userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
            null,
            JSON.stringify(body),
            activeTable,
            eqrcId,
            handleRowUpdate,
            errorHandler
          );
        });

        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: activeTable },
        });
      },
      [userData, handleRowUpdate, tableData, activeTable, tableDispatch]
    );

    const handleAlertDisableError = useCallback(() => {
      NotificationHub.send("danger", "Alert Disable Failed");
    }, []);

    const handleAlertEnableError = useCallback(() => {
      NotificationHub.send("danger", "Alert Enable Failed");
    }, []);

    const handleClickDisable = useCallback(() => {
      handleAlertToggle(false, handleAlertDisableError);
    }, [handleAlertToggle, handleAlertDisableError]);

    const handleClickEnable = useCallback(() => {
      handleAlertToggle(true, handleAlertEnableError);
    }, [handleAlertToggle, handleAlertEnableError]);

    const handleClickHistory = useCallback(() => {
      let results = [];
      setHistoryLoading(true);
      const handleHistory = json => {
        results = results.concat(json.content);
        if (json.totalResults === 0) {
          setHistoryLoading(false);
          NotificationHub.send("success", "No records matched this query");
          return;
        }
        if (json.number < json.totalPages - 1) {
          makeRequest(json.number + 1);
        } else {
          const { fields } = formData[Forms.EQRC_HISTORY_MENU.key];
          const start = fields[EqrcFields.beginDate];
          const end = fields[EqrcFields.endDate];
          let filename = `EQRC_ALERT_HISTORY`;
          if (start && end) {
            filename += `_${start.replace(/\D/g, "").substr(0, 8)}_${end
              .replace(/\D/g, "")
              .substr(0, 8)}`;
          }
          filename += ".csv";

          const { accessors, headers } = getColumnHeadersAndAccessorsFromColumns(
            eqrcAlertHistoryColumns,
            false
          );

          createAndDownloadTemplate(
            filename,
            convertToCSV(
              headers,
              results.map(row => {
                const newRow = { ...row };
                newRow.alertType = ALL_BREACHES_LABELS[row.alertType];
                return newRow;
              }),
              accessors
            )
          ).then(
            () => {
              setHistoryLoading(false);
              NotificationHub.send("success", "History request has been completed");
            },
            () => setHistoryLoading(false)
          );
        }
      };

      const handleHistoryError = () => {
        setHistoryLoading(false);
        NotificationHub.send("danger", "Error retrieving audit information");
      };

      const makeRequest = pageNumber => {
        const body = createHistoryBody(pageNumber, formData[Forms.EQRC_HISTORY_MENU.key].fields);
        alertHistory(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcHistoryUrl],
          JSON.stringify(body),
          handleHistory,
          handleHistoryError
        );
      };
      makeRequest(0);
    }, [formData, setHistoryLoading, userData]);

    return (
      <EQRCAlertConfigTableButtons
        onClickNew={handleClickNew}
        onClickDelete={handleClickDelete}
        onClickDisable={handleClickDisable}
        onClickEnable={handleClickEnable}
        onClickHistory={handleClickHistory}
        isHistoryLoading={isHistoryLoading}
        onClickEdit={handleClickEditAlert}
      />
    );
  }
);
