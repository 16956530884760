import React from "react";
import { StandardTable } from "../../standardTable";

export const PvrAlertCreateValidation = ({ table }) => {
  return (
    <StandardTable
      header={"Create Validation"}
      table={table}
      enableLayoutExport={false}
      id={"ptra-create-validation-table"}
      isSingleSelect={false}
      isColumnsVirtualized={false}
      isFilterable={true}
      isRowSelectEnabled={false}
    />
  );
};
