import { doFetchWrapper } from "network";
import { methods } from "../network";
import { getHeaders } from "keycloak";
import _ from "lodash";
import { callbacks } from "../utils/callbacks";

export const genericAddRemove = async ({
  url,
  cbArgs,
  rows,
  urlSubRoutes,
  payloadKey,
  responseTransform,
}) => {
  const promises = [];
  let failed = [];
  let success = {};

  const { added, removed, noops } = Object.entries(rows).reduce(
    (acc, [eqrcId, row]) => {
      const { newSymbols = [], currentSymbols = [] } = row;
      const { added, removed, noops } = acc;

      const addedSymbols = _.difference(newSymbols, currentSymbols);
      if (addedSymbols.length) {
        added.push({
          ...row.dto,
          [payloadKey]: addedSymbols,
        });
      }
      const removedSymbols = _.difference(currentSymbols, newSymbols);
      if (removedSymbols.length) {
        removed.push({
          ...row.dto,
          [payloadKey]: removedSymbols,
        });
      }

      if (addedSymbols.length === 0 && removedSymbols.length === 0) {
        noops.push({ dto: row.dto });
      }

      return acc;
    },
    { added: [], removed: [], noops: [] }
  );

  if (noops.length > 0) {
    callbacks.success({
      json: { successful: noops, failed: [] },
      success,
      failed,
      payloadKey,
      noop: true,
    });
  }

  if (added.length) {
    const addedURL = url.replace(/(^.+)(multiple\/\?.+)/, `$1${urlSubRoutes.add}/$2`);
    promises.push(
      doFetchWrapper(
        addedURL,
        {
          method: methods.put,
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify({ inputDTOs: added }),
        },
        json => {
          callbacks.success({ json, success, failed, payloadKey });
        },
        json => {
          callbacks.fail({ json, success, failed, payloadKey, cbArgs });
        }
      )
    );
  }

  if (removed.length) {
    if (promises[0]) {
      await promises[0];
    }

    const removedURL = url.replace(/(^.+)(multiple\/\?.+)/, `$1${urlSubRoutes.remove}/$2`);
    promises.push(
      doFetchWrapper(
        removedURL,
        {
          method: methods.put,
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify({ inputDTOs: removed }),
        },
        json => {
          callbacks.success({ json, success, failed, payloadKey, cbArgs });
        },
        json => {
          callbacks.fail({ json, success, failed, payloadKey, cbArgs });
        }
      )
    );
  }

  await Promise.allSettled(promises);

  Object.entries(success)?.forEach(([key, row], i) => {
    if (responseTransform) success[i] = responseTransform(row);
  });
  return { success, failed, length: promises.length };
};
