import { Forms } from "components/fields/fieldConstants";
import * as fieldSubsets from "./fieldsetConstants";
import {
  eqrcAlertConfigurationFields,
  eqrcAlertConfigurationFromRuleFields,
  eqrcAverageDailyVolumeFields,
  eqrcFatFingerFields,
  eqrcGrossExposureFields,
  eqrcMarketImpactFields,
  eqrcMpidPortExchangeFields,
  eqrcOrderRateThresholdsFields,
  eqrcOrderTypeFields,
  eqrcRestrictedStockFields,
  eqrcShortSaleFields,
  fields,
  rashCancelFields,
  tableActions,
} from "./fieldSubsetsConstants";
export const FormFields = {
  [Forms.TR_REPORT.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.entryFormRight,
  },
  [Forms.TR_SCAN.key]: { left: fieldSubsets.scanFormLeft, right: fieldSubsets.scanFormRight },
  [Forms.TR_REJECTS.key]: {
    left: fieldSubsets.rejectsFormLeft,
    right: fieldSubsets.rejectsFormRight,
  },
  [Forms.TR_MATCH.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.TR_MODIFY.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.TR_REPAIR.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.entryFormRight,
  },
  [Forms.TR_COPY.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.entryFormRight,
  },
  [Forms.UPLOAD_REPAIR.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.RIGHT.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.QUERY.key]: {
    left: fieldSubsets.qtFormLeft,
    right: fieldSubsets.qtFormRight,
  },
  [Forms.ST_MATCH.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.ST_MODIFY.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.ST_REPAIR.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.ST_MIDDLE.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.ST_COPY.key]: {
    left: fieldSubsets.entryFormLeft,
    right: fieldSubsets.commonFormRight,
  },
  [Forms.CV_TOP.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.SETTINGS_RSP.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.SETTINGS_TR.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.SETTINGS_STATS.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.SETTINGS_LIMO.key]: { left: fieldSubsets.settingsLimoAll, right: [] }, // doesn't use <Form />
  [Forms.PTR_AUDIT_TRAIL.key]: { left: fieldSubsets.ptrAuditAll, right: [] }, // doesn't use <Form />
  [Forms.SETTINGS_USER_PREFERENCE.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.ST_ACCEPT.key]: {
    left: [
      {
        key: "STAccept",
        fields: {
          capcity: tableActions.acceptA.capacity,
          contraShortSaleInd: tableActions.acceptB.contraShortSaleInd,
        },
      },
    ],
    right: [],
  }, // doesn't use <Form />
  [Forms.PTR_INTRADAY_LIMITS.key]: {
    left: [],
    right: [],
  },
  [Forms.PTR_INTRADAY_AND_NEXTDAY.key]: {
    left: [],
    right: [],
  },
  [Forms.RD_CLEARING.key]: {
    left: [{ key: "RDClearing", fields: fieldSubsets.RDCLEARING }],
    right: [],
  },
  [Forms.RD_AGU.key]: {
    left: [{ key: "RDAGU", fields: fieldSubsets.RDAGU }],
    right: [],
  },
  [Forms.RD_CUSIP.key]: {
    left: [{ key: "RDCUSIP", fields: fieldSubsets.RDCUSIP }],
    right: [],
  },
  [Forms.PTRA_CONFIG.key]: { left: fieldSubsets.settingsRiskAlertNew, right: [] }, // doesn't use <Form />
  [Forms.PTRA_EDIT.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.PTRA_AGU_CONFIG.key]: { left: fieldSubsets.settingsAguRiskAlertNew, right: [] }, // doesn't use <Form />
  [Forms.PTRA_AGU_EDIT.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.PVR_CONFIG.key]: { left: fieldSubsets.settingsPvrAlertNew, right: [] }, // doesn't use <Form />
  [Forms.PVR_EDIT.key]: { left: [], right: [] }, // doesn't use <Form />
  [Forms.PVR_EMAIL_MANAGER.key]: { left: fieldSubsets.ptraEmailManager, right: [] }, // doesn't use <Form />
  [Forms.EQRC_FAT_FINGER.key]: {
    left: fieldSubsets.eqrcFatFingerForm,
    right: [],
  },
  [Forms.PTRA_EMAIL_MANAGER.key]: { left: fieldSubsets.ptraEmailManager, right: [] },
  [Forms.EQRC_RESTRICTED_STOCK_LIST.key]: { left: fieldSubsets.eqrcRestrictedStockForm, right: [] },
  [Forms.EQRC_GROSS_EXPOSURE.key]: { left: fieldSubsets.eqrcGrossExposureForm, right: [] },
  [Forms.EQRC_MARKET_IMPACT_CHECK.key]: { left: fieldSubsets.eqrcMarketImpactForm, right: [] },
  [Forms.EQRC_ORDER_TYPE.key]: { left: fieldSubsets.eqrcOrderTypeForm, right: [] },
  [Forms.EQRC_ADV_CHECK.key]: { left: fieldSubsets.eqrcAvgDailyVolForm, right: [] },
  [Forms.EQRC_ORDER_RATE.key]: { left: fieldSubsets.eqrcOrderRateForm, right: [] },
  [Forms.EQRC_SHORT_SALE.key]: { left: fieldSubsets.eqrcShortSaleForm, right: [] },
  [Forms.EQRC_ALERT_CONFIG.key]: { left: fieldSubsets.eqrcAlertConfigForm, right: [] },
  [Forms.EQRC_EDIT_FAT_FINGER.key]: { left: fieldSubsets.eqrcFatFingerForm, right: [] },
  [Forms.EQRC_EDIT_RESTRICTED_STOCK.key]: { left: fieldSubsets.eqrcRestrictedStockForm, right: [] },
  [Forms.EQRC_EDIT_GROSS_EXPOSURE.key]: { left: fieldSubsets.eqrcGrossExposureForm, right: [] },
  [Forms.EQRC_EDIT_MARKET_IMPACT.key]: { left: fieldSubsets.eqrcMarketImpactForm, right: [] },
  [Forms.EQRC_EDIT_ORDER_TYPE.key]: { left: fieldSubsets.eqrcOrderTypeForm, right: [] },
  [Forms.EQRC_EDIT_AVG_DAILY_VOLUME.key]: { left: fieldSubsets.eqrcAvgDailyVolForm, right: [] },
  [Forms.EQRC_EDIT_ORDER_RATE.key]: { left: fieldSubsets.eqrcOrderRateForm, right: [] },
  [Forms.EQRC_EDIT_SHORT_SALE.key]: { left: fieldSubsets.eqrcShortSaleForm, right: [] },
  [Forms.EQRC_EDIT_ALERT.key]: { left: fieldSubsets.eqrcAlertConfigForm, right: [] },
  [Forms.EQRC_AUDIT_MENU.key]: {
    left: fieldSubsets.eqrcAuditMenu,
    right: [],
  },
  [Forms.EQRC_HISTORY_MENU.key]: {
    left: fieldSubsets.eqrcHistoryMenu,
    right: [],
  },
  [Forms.EQRC_ACTIONS.key]: {
    left: fieldSubsets.eqrcActionsForm,
    right: [],
  },

  [Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER.key]: {
    left: fieldSubsets.eqrcMaxNotionalOrderForm,
    right: [],
  },
  [Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER.key]: {
    left: fieldSubsets.eqrcMaxSharesPerOrderForm,
    right: [],
  },
  [Forms.EQRC_EDIT_SHARES_LOCATED_CHECK.key]: {
    left: fieldSubsets.eqrcSharesLocatedCheckForm,
    right: [],
  },
  [Forms.EQRC_MAX_NOTIONAL_ORDER.key]: { left: fieldSubsets.eqrcMaxNotionalOrderForm, right: [] },
  [Forms.EQRC_MAX_SHARES_PER_ORDER.key]: {
    left: fieldSubsets.eqrcMaxSharesPerOrderForm,
    right: [],
  },
  [Forms.EQRC_SHARES_LOCATED_CHECK.key]: {
    left: fieldSubsets.eqrcSharesLocatedCheckForm,
    right: [],
  },
  [Forms.ORDER_ENTRY.key]: {
    left: fieldSubsets.orderEntryLeft,
    right: fieldSubsets.orderEntryRight,
  },
  [Forms.ORDER_CANCEL.key]: {
    left: [{ key: "qty", fields: rashCancelFields }],
    right: [],
  },
};
