import { useStandardTableDispatch } from "components/standardTable/standardTableContext";
import moment from "moment-timezone";
import React, { createContext, useContext, useReducer } from "react";
import { StandardTables } from "wksConstants";

export const tabTableDispatch = createContext();
tabTableDispatch.displayName = "TabDispatch";
export const useTabTableDispatch = () => {
  return useContext(tabTableDispatch);
};

export const tabTableContext = createContext();
tabTableContext.displayName = "TabContext";
export const useTabTableContext = () => {
  return useContext(tabTableContext);
};

const defaultState = {};

const TabTables = [
  StandardTables.QUERY_TOOL,
  StandardTables.CV_HELD,
  StandardTables.CV_KILL_REJECT,
];

const noTabText = {
  [StandardTables.QUERY_TOOL]: "<no queries>",
};

// initial state for each tab table
TabTables.forEach(table => {
  defaultState[table] = {
    tabs: {},
    activeTab: undefined,
    loadingList: [], // list of tab names that are loading
    disabled: false,
    noNameCount: 0,
    noTabText: noTabText[table],
  };
});

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "CREATE_TABS": {
      const { table, tabs, dataMap, setLoading, removeOldTabs } = action.payload;
      const newState = { ...state };
      if (removeOldTabs) {
        newState[table].tabs = {};
      }
      tabs.forEach(tab => {
        newState[table].tabs[tab] = dataMap && dataMap[tab] ? dataMap[tab] : [];
        if (tab === newState[table].activeTab) {
          state.standardTableDispatch([
            {
              type: "DESELECT_ALL_ROWS",
              payload: { table },
            },
            {
              type: "SET_TABLE_DATA",
              payload: { table, data: newState[table].tabs[tab] },
            },
          ]);
        }
        if (setLoading) {
          newState[table].loadingList.push(tab);
        }
      });
      return newState;
    }
    case "SET_ACTIVE_TAB": {
      const { table, key } = action.payload;
      const newState = { ...state };
      newState[table].activeTab = key;

      return newState;
    }
    case "REMOVE_TAB": {
      const { table, tab } = action.payload;
      const tabTable = { ...state[table] };
      delete tabTable.tabs[tab];
      const index = tabTable.loadingList.indexOf(tab);
      if (index !== -1) {
        tabTable.loadingList.splice(tabTable.loadingList.indexOf(tab), 1);
      }
      if (tab === state[table].activeTab) {
        const keys = Object.keys(tabTable.tabs);
        let nextId = keys.find(key => !state[table].loadingList.includes(key));
        let data = [];
        if (nextId === undefined) {
          nextId = null;
          data = [];
        } else {
          data = state[table].tabs[nextId].data;
        }
        tabTable.activeTab = nextId;
        state.standardTableDispatch([
          {
            type: "SET_TABLE_DATA",
            payload: { table, data },
          },
          {
            type: "DESELECT_ALL_ROWS",
            payload: { table },
          },
        ]);
      }
      return {
        ...state,
        [table]: tabTable,
      };
    }
    case "QUERY_SUCCESS": {
      const { tab, data, noNameCount } = action.payload;
      const tableData = { ...state[StandardTables.QUERY_TOOL] };
      tableData.tabs[tab] = { name: tab, data, lastQueryDate: new moment() };
      tableData.loadingList.splice(tableData.loadingList.indexOf(tab), 1);
      tableData.noNameCount = noNameCount;
      if (!tableData.activeTab || tab === tableData.activeTab) {
        tableData.activeTab = tab;
        state.standardTableDispatch({
          type: "SET_TABLE_DATA",
          payload: { table: StandardTables.QUERY_TOOL, data },
        });
      }
      const newState = {
        ...state,
        [StandardTables.QUERY_TOOL]: tableData,
      };
      return newState;
    }
    case "POST_TRADE_RISK_SUCCESS": {
      const { table, tabs, active } = action.payload;
      const tableData = { ...state[table] };
      tableData.tabs = tabs;
      tableData.activeTab = active;
      return {
        ...state,
        [table]: tableData,
      };
    }
    case "RESET_TABLE": {
      const { table } = action.payload;
      return {
        ...state,
        [table]: {
          tabs: {},
          activeTab: undefined,
          loadingList: [], // list of tab names that are loading
          disabled: false,
          noNameCount: 0,
          noTabText: noTabText[table],
        },
      };
    }
    case "SET_LAST_QUERY_DATE": {
      const { table, lastQueryDate } = action.payload;
      return {
        ...state,
        [table]: { ...state[table], lastQueryDate: lastQueryDate || new moment() },
      };
    }
    default:
      return { ...state };
  }
};

const TabTableProvider = ({ children, defaultData }) => {
  const standardTableDispatch = useStandardTableDispatch();
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({ standardTableDispatch }, defaultState, defaultData)
  );

  return (
    <tabTableDispatch.Provider value={dispatchF}>
      <tabTableContext.Provider value={[state, dispatchF]}>{children}</tabTableContext.Provider>
    </tabTableDispatch.Provider>
  );
};

export default TabTableProvider;
