import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstants";
import { DATE_FILTER, PvReject } from "./constant";

export enum REJECT_CACHE_FILTER_BY {
  NULL,
  ALL,
  MPID,
  SYMBOL,
  MPID_SYMBOL,
}

type REJECT_CACHE_FILTER =
  | null
  | {
      filterBy: REJECT_CACHE_FILTER_BY.MPID;
      criteria: { mpids: string[]; rejectText: string; date: DATE_FILTER };
    }
  | {
      filterBy: REJECT_CACHE_FILTER_BY.SYMBOL;
      criteria: { symbols: string[]; rejectText: string; date: DATE_FILTER };
    }
  | {
      filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL;
      criteria: {
        mpidToSymbols: { [mpid: string]: string[] };
        rejectText: string;
        date: DATE_FILTER;
      };
    };

const getOffsetKeyForMpidAndSymbol = (mpid: string, symbol: string) => {
  return `${mpid}|${symbol}`;
};

export type RejectCacheState = {
  data: { [rejectId: number]: PvReject };
  offsets: { [key: string]: number };
  filter: REJECT_CACHE_FILTER;
  // keys: string[];
  // filterBy: REJECT_CACHE_FILTER_BY;
  status: string;
  makeInitialRequest: boolean;
  requestFailedCount: number;
  hasMadeInitialRequest: boolean;
  isPolling: boolean;
  isRequesting: boolean;
  timeStamp: number;
  instId: number;
  isLoading: boolean;
  requestAbort: boolean;
  abort: AbortController;
  requestTimer: NodeJS.Timeout | undefined;
};

export type RejectCacheAction =
  | { type: "START_POLLING"; payload: { filter: REJECT_CACHE_FILTER } }
  | { type: "RESET_CACHE" }
  | { type: "STOP_POLLING" }
  | { type: "HANDLE_INITIAL_REQUEST" }
  | { type: "HANDLE_REQUEST_FAILED"; payload: number }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_REQUESTING"; payload: boolean }
  | { type: "SET_REQUEST_STATUS"; payload: string }
  | { type: "SET_ABORT"; payload: AbortController }
  | { type: "SET_REQUEST_TIMER"; payload: NodeJS.Timeout | undefined }
  | { type: "SET_DATA"; payload: { [rejectId: number]: PvReject } }
  | { type: "SET_OFFSETS"; payload: { [key: string]: number } };

const FACTORY = new ContextFactory<RejectCacheState, RejectCacheAction>();

const DEFAULT_STATE = {
  data: {},
  offsets: {},
  filter: null,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isRequesting: false,
  timeStamp: 0,
  instId: 0,
  isLoading: false,
  requestAbort: false,
  abort: new AbortController(),
  requestTimer: undefined,
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<RejectCacheState, RejectCacheAction> = (
  prevState: RejectCacheState,
  action: RejectCacheAction
) => {
  switch (action.type) {
    case "START_POLLING": {
      const { filter } = action.payload;
      prevState.abort.abort();
      clearTimeout(prevState.requestTimer);
      return {
        ...prevState,
        filter,
        isPolling: true,
        makeInitialRequest: true, //!prevState.hasMadeInitialRequest,
        hasMadeInitialRequest: false,
        isRequesting: false,
        isLoading: true,
        data: {},
        offsets: {},
      };
    }
    case "RESET_CACHE": {
      return {
        ...DEFAULT_STATE,
      };
    }
    case "STOP_POLLING": {
      return {
        ...prevState,
        isPolling: false,
      };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...prevState,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...prevState,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_LOADING": {
      return { ...prevState, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...prevState, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...prevState, status: action.payload };
    }
    case "SET_ABORT": {
      return { ...prevState, abort: action.payload };
    }
    case "SET_REQUEST_TIMER": {
      return { ...prevState, requestTimer: action.payload };
    }
    case "SET_DATA": {
      return { ...prevState, data: action.payload, isLoading: false };
    }
    case "SET_OFFSETS": {
      return { ...prevState, offsets: action.payload };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<RejectCacheState, RejectCacheAction>(
  DISPATCH_FN_SWITCH
);

interface RejectCacheProviderProps extends ContextProviderProps {}

const getKeyForReject = (reject: PvReject): number => {
  return reject.id;
};

export const RejectCacheProvider: React.FC<RejectCacheProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<RejectCacheState, RejectCacheAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );

  const getPorData = useCallback(
    (isInitialData: boolean) => {
      const newOffsets = { ...state.offsets };
      const newData = { ...state.data };
      const getSuccessActions = (
        isInitialData: boolean,
        hasUpdate: boolean,
        timer: NodeJS.Timeout
      ) => {
        const actions: RejectCacheAction[] = [
          {
            type: "SET_OFFSETS",
            payload: newOffsets,
          },
          {
            type: "SET_LOADING",
            payload: false,
          },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.SUCCESS,
          },
          {
            type: "SET_REQUEST_TIMER",
            payload: timer,
          },
        ];

        if (isInitialData) {
          actions.push({ type: "HANDLE_INITIAL_REQUEST" });
        }
        if (hasUpdate) {
          actions.push({
            type: "SET_DATA",
            payload: newData,
          });
        }
        return actions;
      };

      const getRejectsByMpidSuccess = (data: { rejects: { [key: string]: any }[] }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, 4000);

        let hasUpdate = false;
        data.rejects.forEach(({ key, reject }) => {
          hasUpdate = true;
          if (newOffsets[key] === undefined) {
            newOffsets[key] = 1;
          } else {
            newOffsets[key]++;
          }
          if (["X", "R", "U", "D"].includes(reject.rejectStatus)) {
            delete newData[getKeyForReject(reject)];
          } else {
            newData[getKeyForReject(reject)] = reject;
          }
        });
        dispatch(getSuccessActions(isInitialData, hasUpdate, timer));
      };

      const getRejectsByMpidAndSymbolSuccess = (data: {
        rejects: { [mpid: string]: { key: string; reject: any }[] };
      }) => {
        const timer = setTimeout(() => {
          dispatch({ type: "SET_REQUESTING", payload: true });
        }, 4000);

        let hasUpdate = false;
        Object.entries(data.rejects).forEach(([mpid, rejects]) => {
          rejects.forEach(({ key, reject }) => {
            hasUpdate = true;
            if (newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)] === undefined) {
              newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)] = 1;
            } else {
              newOffsets[getOffsetKeyForMpidAndSymbol(mpid, key)]++;
            }
            if (["X", "R", "U", "D"].includes(reject.rejectStatus)) {
              delete newData[getKeyForReject(reject)];
            } else {
              newData[getKeyForReject(reject)] = reject;
            }
          });
        });
        dispatch(getSuccessActions(isInitialData, hasUpdate, timer));
      };

      const getRejectsError = () => {
        const actions: RejectCacheAction[] = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
        ];
        actions.push({
          type: "SET_REQUEST_STATUS",
          payload: Status.ERROR,
        });
        if (state.status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting PORs");
        }
        dispatch(actions);
      };

      const abortController = new AbortController();
      dispatch({ type: "SET_ABORT", payload: abortController });
      if (state.filter !== null) {
        switch (state.filter.filterBy) {
          case REJECT_CACHE_FILTER_BY.MPID:
            const mpids = state.filter.criteria.mpids.reduce((acc: any, curr: string) => {
              acc[curr] = state.offsets[curr] || 0;
              return acc;
            }, {} as any);
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsByMpid"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpids,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: 0 /* TODO - use real timestamp */,
                }),
              },
              getRejectsByMpidSuccess,
              getRejectsError
              // getRejectsAbortCb
            );
            break;
          case REJECT_CACHE_FILTER_BY.SYMBOL:
            const symbols = state.filter.criteria.symbols.reduce((acc: any, curr: string) => {
              acc[curr] = state.offsets[curr] || 0;
              return acc;
            }, {} as any);
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsBySymbol"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  symbols,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: 0 /* TODO - use real timestamp */,
                }),
              },
              getRejectsByMpidSuccess,
              getRejectsError
              // getRejectsAbortCb
            );
            break;
          case REJECT_CACHE_FILTER_BY.MPID_SYMBOL:
            const mpidToSymbols = Object.entries(state.filter.criteria.mpidToSymbols).reduce(
              (acc: any, [mpid, symbols]: [string, string[]]) => {
                symbols.forEach(symbol => {
                  if (!acc[mpid]) {
                    acc[mpid] = {};
                  }
                  acc[mpid][symbol] =
                    state.offsets[getOffsetKeyForMpidAndSymbol(mpid, symbol)] || 0;
                });
                return acc;
              },
              {} as any
            );
            doFetchWrapper(
              formatUrl(process.env.REACT_APP_URL_PVR_REJECT_CACHE, "rejectsByMpidAndSymbol"),
              {
                method: "post",
                mode: "cors",
                signal: abortController.signal,
                headers: getHeaders(),
                body: JSON.stringify({
                  mpidToSymbols,
                  rejectText: state.filter.criteria.rejectText,
                  date: state.filter.criteria.date,
                  timestamp: 0 /* TODO - use real timestamp */,
                }),
              },
              getRejectsByMpidAndSymbolSuccess,
              getRejectsError
              // getRejectsAbortCb
            );
            break;
        }
      }
    },
    [state.offsets, state.data, state.filter, state.requestFailedCount, state.status]
  );

  // -- initial request --
  // should only have to run once as long as timeStamp
  // is persisted throughout the entire session
  useEffect(() => {
    if (state.makeInitialRequest && state.isPolling) {
      if (state.requestFailedCount > 0) {
        const timer = setTimeout(() => {
          getPorData(true);
        }, 4000);
        dispatch({ type: "SET_REQUEST_TIMER", payload: timer });
      } else {
        dispatch({ type: "SET_LOADING", payload: true });
        getPorData(true);
      }
    }
  }, [state.makeInitialRequest, getPorData, state.requestFailedCount, state.isPolling]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting && !state.requestAbort) {
      dispatch({ type: "SET_REQUESTING", payload: false });
      getPorData(false);
    } else if (!state.isPolling) {
      dispatch({ type: "SET_REQUEST_STATUS", payload: Status.NO_STATUS });
    }
  }, [state.isPolling, state.isRequesting, state.requestAbort, getPorData]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const useRejectCacheDispatch =
  FACTORY.createContextHook<React.Dispatch<RejectCacheAction | RejectCacheAction[]>>(
    DISPATCH_CONTEXT
  );
export const useRejectCacheState = FACTORY.createContextHook<RejectCacheState>(STATE_CONTEXT);
