import { CSSGrid } from "@nef/core";
import { Middle } from "components/middle";
import { EffectCallback, useEffect, useState } from "react";
import { useLayoutContext } from "components/context";
import { RejectsTable } from "./tables/rejects.table";
import { PorTable } from "./tables/por.table";
import { PORs } from "./tiles/por";
import { PVRs } from "./tiles/pvr";
import { PORProvider, PorOrPvr, usePORCacheContext } from "./context";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch, usePorCacheState } from "../porCache";
import { useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { REJECT_COUNT_FILTER_BY, useRejectCountDispatch } from "../member/rejectCountCache";
import { usePorCountDispatch } from "../porCountCache";
import { TradeDetailRecap, useRecapContext } from "components/tradeDetailRecap";

export const Monitor = () => {
  const [, layoutDispatch] = useLayoutContext();
  const { state } = usePORCacheContext();
  const porCacheState = usePorCacheState();
  const porCacheDispatch = usePorCacheDispatch();
  const rejectCountDispatch = useRejectCountDispatch();
  const porCountDispatch = usePorCountDispatch();
  const tableDispatch = useStandardTableDispatch();
  const [recapData] = useRecapContext();

  useEffect((): ReturnType<EffectCallback> => {
    layoutDispatch({
      type: "SET_HIDE_LEFT",
      payload: true,
    });
    return () => {
      layoutDispatch({
        type: "SET_HIDE_LEFT",
        payload: false,
      });
    };
  }, [layoutDispatch]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const pendingOrAll = `${state[PorOrPvr.POR].allOrPending}`;
    // const mpidOrSymbol = `${state[PorOrPvr.POR].mpidOrSymbol}`;
    if (state?.selected?.length > 0) {
      setTitle(`${pendingOrAll} POR for ${state.selected}`);
    } else {
      setTitle(`${pendingOrAll} POR`);
    }
  }, [state]);

  useEffect(() => {
    tableDispatch({
      type: "DESELECT_ALL_ROWS",
      payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
    });
    porCacheDispatch({
      type: "START_POLLING",
      payload: {
        filterBy: POR_CACHE_FILTER_BY.ALL,
      },
    });
    porCountDispatch({
      type: "START_POLLING",
    });
    rejectCountDispatch({
      type: "START_POLLING",
      payload: {
        filter: {
          filterBy: REJECT_COUNT_FILTER_BY.FINRA_COUNTS,
        },
      },
    });
  }, [porCacheDispatch, porCountDispatch, rejectCountDispatch, tableDispatch]);

  useEffect(
    () => () =>
      porCacheDispatch({
        type: "STOP_POLLING",
      }),
    [porCacheDispatch]
  );

  useEffect(() => {
    if (title.indexOf("Pending") !== -1) {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2, data: porCacheState.pending },
      });
    } else {
      tableDispatch({
        type: "SET_TABLE_DATA",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2, data: porCacheState.data },
      });
    }
  }, [porCacheState.data, porCacheState.pending, tableDispatch, title]);

  return (
    <Middle>
      <CSSGrid
        gap={"1rem"}
        rows={"40% calc(60% - 1rem)"}
        cols={"calc(50% - 1rem) calc(50% - 1rem)"}
      >
        <PORs setTitle={setTitle} />
        <PVRs setTitle={setTitle} />
        <PorTable title={title} />
        <RejectsTable isAllOrPending={state[PorOrPvr.POR].allOrPending} selected={state.selected} />
      </CSSGrid>
      <TradeDetailRecap widthFactor={recapData.trades.length} />
    </Middle>
  );
};

export const MonitorWrapper = () => {
  return (
    <PORProvider>
      <Monitor></Monitor>
    </PORProvider>
  );
};
