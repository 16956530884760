import _ from "lodash";
import { EQRC_RULE_STATUS, getPropertyFromArray } from "../constants";
import { EqrcRuleStatus } from "../constants2";

export const getActiveOrInactiveFromBoolean = value => {
  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  }

  if (typeof value === "boolean") {
    return value ? "Active" : "Inactive";
  } else {
    return undefined;
  }
};

export const formatEqrcNumber = value => {
  return value?.toLocaleString() || value;
};

export const getSortVal = (row, property) => {
  let val;
  if (Array.isArray(property)) {
    val = getPropertyFromArray(row, property, 0);
  } else {
    val = row?.[property];
  }
  if (Array.isArray(val)) {
    val = val[0];
  }
  return val;
};

export const sortEqrcCell = property => {
  return (a, b) => {
    const aValActive = getSortVal(a.original[EQRC_RULE_STATUS.Active], property) || "0";
    const bValActive = getSortVal(b.original[EQRC_RULE_STATUS.Active], property) || "0";
    if (aValActive > bValActive) return 1;
    if (aValActive < bValActive) return -1;

    const aValConfigured = getSortVal(a.original[EQRC_RULE_STATUS.Configured], property) || "0";
    const bValConfigured = getSortVal(b.original[EQRC_RULE_STATUS.Configured], property) || "0";

    if (aValConfigured > bValConfigured) return 1;
    if (aValConfigured < bValConfigured) return -1;
    return 0;
  };
};

export const getEqrcAccessorForField = (row, field) => {
  let value = "";
  if (!Array.isArray(field)) {
    field = [field];
  }

  if (_.get(row?.[EqrcRuleStatus.active], field[0])) {
    value = getPropertyFromArray(row[EqrcRuleStatus.active], field, 0);
  } else if (_.get(row?.[EqrcRuleStatus.configured], field[0])) {
    value = getPropertyFromArray(row[EqrcRuleStatus.configured], field, 0);
  } else if (row?.[field] || row?.[field] === 0) {
    value = row?.[field];
  }
  if (Array.isArray(value)) {
    value = value.join(", ");
  }
  return value;
};

export const auditColPrefix = "rule";
