import { FieldLoop, pvrAlertFields3 } from "components/fields";
import React from "react";
import { PtraFieldSection, SectionFieldsWrapper, SectionHeader } from "../constants";

export const PvrWorkXDeliveryOptions: React.FC<PtraFieldSection> = ({ form }) => {
  return (
    <section>
      <SectionHeader>WorkX Delivery Options</SectionHeader>
      <SectionFieldsWrapper>
        <FieldLoop
          isReactFragment={true}
          form={form}
          fields={pvrAlertFields3}
          classNames={undefined}
          augmentOnChange={undefined}
          augmentOnCreate={undefined}
          portalRef={undefined}
          isDisabled={undefined}
          containerRef={undefined}
          showLabel={undefined}
        />
      </SectionFieldsWrapper>
    </section>
  );
};
