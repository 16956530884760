import { GrossExposure } from "components/settings/eqrc/types.rules";
import { CurrentExposureData, SingleOrderMapDatum } from "./cache.types";
import { BREACHES } from "./eqrc.types";
import { Status } from "wksConstants";

export const NONE = "none";
type ChartsOrTable = "charts" | "table";
export type EqrcCacheAction =
  | {
      type: "SHOW_DETAILS";
      payload: { name: string };
    }
  | { type: "REMOVE_TABLE"; payload: { table: BREACHES } }
  | { type: "TOGGLE_MODAL" }
  | { type: "CLOSE_MODAL" }
  | {
      type: "SET_CURRENTLY_USED_CACHE_KEYS";
      payload: { data: string[] | null; view: ChartsOrTable };
    }
  | {
      type: "SET_DATA_TABLE";
      payload: {
        dataToSet: {
          [exchangeMpidPort: string]: CurrentExposureData;
        };
        rules: Map<string, GrossExposure>;
      };
    }
  | {
      type: "SET_DATA_CHARTS";
      payload: {
        dataToSet: {
          exposure: { [level: string]: { offset: number; data: SingleOrderMapDatum[] } }[];
          singleOrders: { offset: number; data: SingleOrderMapDatum[] }[];
        };
      };
    }
  | {
      type: "SET_STATUS";
      payload: {
        view: ChartsOrTable;
        status: (typeof Status)[keyof typeof Status];
      };
    }
  | {
      type: "SET_IS_REQUESTING";
      payload: {
        view: ChartsOrTable;
        isRequesting: boolean;
        i?: number;
      };
    }
  | {
      type: "SET_SHOULD_REQUEST";
      payload: boolean;
    }
  | {
      type: "SET_REQUEST_TIMEOUT";
      payload: {
        view: ChartsOrTable;
        timeOut: ReturnType<typeof setTimeout>;
      };
    }
  | {
      type: "SET_SHOULD_ABORT";
      payload: boolean;
    }
  | {
      type: "SET_SHOULD_MAKE_FIRST_REQUEST";
      payload: {
        view: ChartsOrTable;
        shouldMake: boolean;
      };
    }
  | {
      type: "SET_IS_WINDOW_FOCUSED";
      payload: boolean;
    }
  | {
      type: "CLEAR_TIMEOUT";
      payload: { view: ChartsOrTable };
    };
