import React from "react";
import { EqrcFields, EQRC_RULE_STATUS } from "../constants";
import { EqrcTableCell } from "../components/tableCell";
import { auditColPrefix, getEqrcAccessorForField, sortEqrcCell } from "./utils";
import { Indicator } from "@nef/core";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import styled from "styled-components";

const doNotRenderZero = value => (value === 0 ? "" : value);

export const MPIDHeader = isAudit => ({
  Header: "MPID",
  id: EqrcFields.mpid,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.mpid]
      : getEqrcAccessorForField(row, EqrcFields.mpid),
  Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.mpid} />,
  sortType: sortEqrcCell(EqrcFields.mpid),
});

export const PortHeader = isAudit => ({
  Header: "Port",
  id: EqrcFields.port,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.port]
      : getEqrcAccessorForField(row, EqrcFields.port),
  Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.port} />,
  sortType: sortEqrcCell(EqrcFields.port),
});

export const ExchangeHeader = isAudit => ({
  Header: "Exchange",
  id: EqrcFields.exchange,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.exchange]
      : getEqrcAccessorForField(row, EqrcFields.exchange),
  Cell: ({ row }) => {
    return <EqrcTableCell row={row.original} property={EqrcFields.exchange} />;
  },
  sortType: sortEqrcCell(EqrcFields.exchange),
});

export const GroupIDHeader = isAudit => ({
  Header: "Group ID",
  id: EqrcFields.groupId,
  accessor: row => {
    return isAudit ? row?.[EqrcFields.groupId] : getEqrcAccessorForField(row, EqrcFields.groupId);
  },
  Cell: ({ row }) => (
    <EqrcTableCell row={row.original} property={EqrcFields.groupId} formatFn={doNotRenderZero} />
  ),
  sortType: sortEqrcCell(EqrcFields.groupId),
});

export const PerOrderSharesLimitHeader = isAudit => ({
  Header: "Per Order Shares Limit",
  id: EqrcFields.perOrderSharesLimit,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.perOrderSharesLimit]
      : getEqrcAccessorForField(row, EqrcFields.perOrderSharesLimit),
  Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.perOrderSharesLimit} />,
  sortType: sortEqrcCell(EqrcFields.perOrderSharesLimit),
});

export const PerOrderNotionalLimitHeader = isAudit => ({
  Header: "Per Order Notional Limit",
  id: EqrcFields.perOrderNotionalLimit,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.perOrderNotionalLimit]
      : getEqrcAccessorForField(row, EqrcFields.perOrderNotionalLimit),
  Cell: ({ row }) => (
    <EqrcTableCell row={row.original} property={EqrcFields.perOrderNotionalLimit} />
  ),
  sortType: sortEqrcCell(EqrcFields.perOrderNotionalLimit),
});

export const sharesLocatedOptionHeader = isAudit => ({
  Header: "Shares Located Check",
  id: EqrcFields.sharesLocatedOption,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.sharesLocatedOption]
      : getEqrcAccessorForField(row, [EqrcFields.sharesLocateRule, EqrcFields.sharesLocatedOption]),
  Cell: ({ row }) => (
    <EqrcTableCell
      row={row.original}
      property={[EqrcFields.sharesLocateRule, EqrcFields.sharesLocatedOption]}
    />
  ),
  sortType: sortEqrcCell([EqrcFields.sharesLocateRule, EqrcFields.sharesLocatedOption]),
  width: 130,
});

export const brokerList = isAudit => ({
  Header: "Broker List",
  id: EqrcFields.brokers,
  accessor: row => {
    return isAudit
      ? row[auditColPrefix]?.[EqrcFields.brokers]?.toString()?.replace(/,/g, ", ")
      : getEqrcAccessorForField(row, [EqrcFields.brokersListRule]);
  },
  Cell: ({ row }) => {
    return (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.brokersListRule, EqrcFields.brokers]}
        formatFn={formatArrayValue}
        tooltipContent={true}
      />
    );
  },
  sortType: sortEqrcCell([EqrcFields.brokersListRule, EqrcFields.brokers]),
});

export const formatArrayValue = value => {
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  return value;
};

export const SymbolsHeader = isAudit => ({
  Header: "Symbols",
  id: EqrcFields.symbols,
  accessor: row =>
    isAudit
      ? row[auditColPrefix]?.[EqrcFields.symbols]?.join(", ")
      : getEqrcAccessorForField(row, EqrcFields.symbols),
  Cell: ({ row }) => (
    <EqrcTableCell
      row={row.original}
      property={EqrcFields.symbols}
      formatFn={formatArrayValue}
      tooltipContent={true}
    />
  ),
  sortType: sortEqrcCell(EqrcFields.symbols),
});

export const RuleTypeHeader = {
  Header: "Rule Type",
  id: EqrcFields.ruleType,
  accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.ruleType],
};

const LeftAlignedP = styled.p`
  text-align: left;
`;

const StyledA = styled.a`
  cursor: pointer;
`;

export const resultColumn = {
  Header: "result",
  id: "result",
  accessor: row => {
    return getEqrcAccessorForField(row, ["requestStatus"]);
  },
  width: 80,
  Cell: ({ row }) => {
    const { status } = row.original;
    const successes = row.original[status].requestStatus?.success;

    return <Indicator color={successes !== undefined ? "success" : "danger"} />;
  },
};

export const errorCountColumn = {
  Header: "Errors",
  id: "errorCount",
  accessor: row => {
    return row[row.status]?.requestStatus?.error?.length || 0;
  },
  width: 80,
  Cell: ({ row }) => {
    const { status } = row.original;
    const errors = row.original[status].requestStatus?.error;
    const successes = row.original[status].requestStatus?.success;
    const id = `eqrcResultToolip_${row.original.eqrcId}`;

    return (
      <TooltipTableCell
        id={id}
        tooltipContent={
          <>
            {successes && (
              <>
                <LeftAlignedP>Successes:</LeftAlignedP>
                {successes}
              </>
            )}
            {errors && (
              <>
                <LeftAlignedP>Errors:</LeftAlignedP>
                {errors}
              </>
            )}
          </>
        }
      >
        <StyledA>{errors?.props?.children?.length}</StyledA>
      </TooltipTableCell>
    );
  },
};
