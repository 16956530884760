import React, { useCallback, useEffect, useMemo, useState } from "react";
import { handleImplicitDecimals } from "..";
import { ActiveForm } from "../components/activeForm";
import { useFormContext } from "components/form";
import {
  getFormValidation,
  getSelectedRows,
  useStandardTableContext,
} from "components/standardTable";
import { updateRule } from "../network";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import {
  EqrcFields,
  getEqrcIdFromResponse,
  EQRC_RULE_STATUS,
  OPPOSITE_ROW,
  EQRC_SHARES_LOCATED_VALUES,
} from "../constants";
import { Forms } from "components/fields";
import { preparePostBody } from "../transformers/bodies";
import { FooterMessage } from "./footerMessage";
import { Header } from "./editHeader";
import { StandardTables } from "wksConstants";

const Response_Parse_Type = {
  simple: "simple",
  complex: "complex",
};

const getDtoIndex = dtoKey => dtoKey.match(/\d+/)[0];

export const EQRCEditForm = ({
  table,
  form,
  fieldSets,
  isOpen,
  onClose,
  setStep,
  currentStep,
  totalSteps,
  ruleType,
  showResults,
  refreshCurrentTableData,
}) => {
  const [tableData, standardTableDispatch] = useStandardTableContext();
  const [userData] = useUserContext();
  const [formData, formDispatch] = useFormContext();
  const [isSaving, setSaving] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSaveSuccess = useCallback(
    (
      table,
      json,
      CbArgs,
      { resetForm = true, doOnClose = true, deselectRows = true, noop = false } = {
        resetForm: true,
        doOnClose: true,
        deselectRows: true,
        noop: false,
      }
    ) => {
      const tableDispatches = [{ type: "SET_IS_LOADING", payload: { table, isLoading: false } }];
      if (!Array.isArray(json)) {
        json = Object.values(json);
      }
      if (json) {
        json.forEach(success => {
          const { originalRow, sentValues } = CbArgs.find(({ eqrcId }) => {
            return eqrcId === getEqrcIdFromResponse(success.dto);
          });

          const newRow = { ...originalRow };
          const type = originalRow[EqrcFields.status];

          handleImplicitDecimals(success.dto);
          const newRowOfType = { ...success.dto };

          newRowOfType[EqrcFields.status] = { ...originalRow[type][EqrcFields.status] };
          if (newRowOfType?.symbols?.length === 0) {
            newRowOfType.symbols = sentValues.symbols;
          }

          newRow[type] = newRowOfType;
          if (table === StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST) {
            if (sentValues[EqrcFields.enableBrokerList] === true) {
              if (sentValues[EqrcFields.brokers]) {
                newRowOfType[EqrcFields.brokersListRule] = {
                  [EqrcFields.brokers]: sentValues[EqrcFields.brokers],
                };
              } else if (sentValues[EqrcFields.brokersListRule]) {
                newRowOfType[EqrcFields.brokersListRule] = sentValues[EqrcFields.brokersListRule];
              }
            } else {
              newRowOfType[EqrcFields.brokersListRule] =
                originalRow[type][EqrcFields.brokersListRule];
            }

            if (sentValues[EqrcFields.sharesLocatedOption]) {
              newRowOfType[EqrcFields.sharesLocateRule] = {
                [EqrcFields.sharesLocatedOption]:
                  sentValues[EqrcFields.sharesLocateRule][EqrcFields.sharesLocatedOption],
              };
            }
          }
          if (noop !== true) {
            tableDispatches.push({
              type: "UPDATE_TABLE_ROW_WITH_ID",
              payload: { table: table, row: newRow, idField: EqrcFields.eqrcId },
            });
          }
        });
      }
      refreshCurrentTableData();
      setSaving(false);

      if (resetForm) {
        formDispatch({ type: "RESET_FORM", payload: { form } });
      }

      if (doOnClose) {
        onClose();
      }

      if (deselectRows) {
        tableDispatches.push({
          type: "DESELECT_ALL_ROWS",
          payload: { table: table },
        });
      }

      if (!Array.isArray(json)) {
        json = Object.values(json);
      }

      const results = {
        data: json.map((row, i) => {
          const { dto } = row;
          const matchedRow = CbArgs.find(({ eqrcId }) => {
            return eqrcId === getEqrcIdFromResponse(dto);
          });
          const sentValues = matchedRow.sentValues;
          if (sentValues[EqrcFields.sharesLocatedOption]) {
            sentValues[EqrcFields.sharesLocateRule] = {
              [EqrcFields.sharesLocatedOption]: sentValues[EqrcFields.sharesLocatedOption],
            };
          }

          handleImplicitDecimals(sentValues);

          const returnValue = {
            eqrcId: matchedRow.eqrcId,
            status: sentValues[EqrcFields.status],
            [sentValues[EqrcFields.status]]: {
              ...sentValues,
              requestStatus: {
                ...dto?.requestStatus,
                success: [],
              },
            },
          };
          return returnValue;
        }),
        show: true,
      };
      showResults(results);
      standardTableDispatch(tableDispatches);
    },
    [refreshCurrentTableData, showResults, standardTableDispatch, formDispatch, form, onClose]
  );

  const handleSaveError = useCallback(
    (table, error, originalRow) => {
      let groupedRows;
      let allowRepost = false;
      const knownBadOriginalRows = {};
      let type = Response_Parse_Type.simple;

      if (Array.isArray(error)) {
        groupedRows = error.map(e => [e.message]);
      } else {
        groupedRows = {};

        Object.entries(error).forEach(([key, message], i) => {
          if (message.message) {
            if (!groupedRows[i]) {
              groupedRows[i] = [];
            }
            groupedRows[i].push(message.message);
          } else if (message[0]?.message) {
            groupedRows[i] = message.map(m => {
              return m.message;
            });
          } else {
            allowRepost = true;
            type = Response_Parse_Type.complex;

            const splitKey = key.split(".");
            const dtoKey = splitKey[0];
            const fieldKey = splitKey[1];
            const dtoIndex = getDtoIndex(dtoKey);
            knownBadOriginalRows[dtoIndex] = true;

            const addSpaces = fieldKey.replace(/([A-Z])/g, " $1");
            const newKey = addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1);

            if (!groupedRows[dtoKey]) {
              groupedRows[dtoKey] = [];
            }

            groupedRows[dtoKey].push(`${newKey}: ${message}`);
          }
        });

        if (!Array.isArray(error)) {
          error = Object.values(error).map((row, i) => row);
        }
      }

      let possibleGoodRows = [];
      if (
        table === StandardTables.EQRC_GROSS_EXPOSURE &&
        allowRepost &&
        Object.keys(originalRow).length > Object.keys(knownBadOriginalRows).length
      ) {
        possibleGoodRows = Object.values(originalRow)
          .filter((value, i) => {
            return !knownBadOriginalRows[i];
          })
          .map(row => row.originalRow);

        handleSave(possibleGoodRows);
      }

      let results;
      if (type === "simple") {
        results = {
          data: error.map((row, i) => {
            const matchedRow = Object.values(originalRow).find(row2 => {
              return row2.eqrcId === getEqrcIdFromResponse(row.dto);
            });
            const sentValues = { ...matchedRow.sentValues };
            const status = sentValues[EqrcFields.status];
            handleImplicitDecimals(sentValues);

            const returnValue = {
              eqrcId: getEqrcIdFromResponse(matchedRow.originalRow[status]),
              status: sentValues[EqrcFields.status],
              [sentValues[EqrcFields.status]]: {
                ...sentValues,
                requestStatus: {
                  error: (
                    <ul>
                      {(groupedRows[i] || []).map(error => {
                        return (
                          <li
                            key={`${getEqrcIdFromResponse(
                              matchedRow.originalRow[status]
                            )}_${error}`}
                          >
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              },
            };

            return returnValue;
          }),
          show: possibleGoodRows.length === 0,
        };
      } else if (type === "complex") {
        results = {
          data: Object.entries(groupedRows).map(([dtoKey, message], i) => {
            const dtoIndex = getDtoIndex(dtoKey);
            const status = originalRow[dtoIndex][EqrcFields.status];
            const sentValues = { ...originalRow[dtoIndex].sentValues };

            handleImplicitDecimals(sentValues);

            const returnValue = {
              eqrcId: getEqrcIdFromResponse(originalRow[dtoIndex].originalRow[status]),
              status: sentValues[EqrcFields.status],
              [sentValues[EqrcFields.status]]: {
                ...sentValues,
                requestStatus: {
                  error: (
                    <ul>
                      {message.map(error => {
                        return (
                          <li
                            key={`${getEqrcIdFromResponse(
                              originalRow[dtoIndex].originalRow[status]
                            )}_${error}`}
                          >
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              },
            };

            return returnValue;
          }),
          show: possibleGoodRows.length === 0,
        };
      }
      showResults(results);
      refreshCurrentTableData();
      standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
      setSaving(false);
    },
    [refreshCurrentTableData, showResults, standardTableDispatch]
  );

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );

  const handleSave = useCallback(
    rows => {
      const valid = getFormValidation(form, formData, formDispatch);
      if (!valid) {
        return;
      }

      standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: true } });
      rows = !!rows ? rows : getSelectedRows(tableData[table]);

      setSaving(true);

      const exchangeMpidPorts = rows.reduce((acc, row) => {
        const { exchange, mpid, port } =
          row[formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status]];

        if (!row.disabled) {
          acc.push({ exchange, mpid, port });
        }

        return acc;
      }, []);

      const filteredRows = rows.filter(row => {
        return !row.disabled;
      });

      const body = preparePostBody(
        {
          ...formData[form.key].fields,
          exchangeMpidPorts,
          status: formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status],
        },
        [...filteredRows],
        table
      );

      const builtCbArgs = body.inputDTOs.map((row, i) => {
        return {
          eqrcId: getEqrcIdFromResponse(row),
          originalRow: { ...rows.find(row2 => row2.eqrcId === getEqrcIdFromResponse(row)) },
          sentValues: row,
          status: formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status],
        };
      });

      updateRule(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
        formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status] ===
          EQRC_RULE_STATUS.Active,
        body,
        table,
        builtCbArgs,
        handleSaveSuccess,
        handleSaveError,
        false
      );
    },
    [
      form,
      formData,
      formDispatch,
      standardTableDispatch,
      table,
      tableData,
      userData,
      handleSaveSuccess,
      handleSaveError,
    ]
  );

  let selected = useMemo(() => getSelectedRows(tableData[table]), [table, tableData]);
  const isDisabled =
    formData[form.key].fields[EqrcFields.sharesLocatedOption]?.value ===
    EQRC_SHARES_LOCATED_VALUES.DISABLED;
  useEffect(() => {
    if ([Forms.EQRC_EDIT_SHARES_LOCATED_CHECK].includes(form)) {
      let disabledBcActive = selected.find(row => {
        const status = row[EqrcFields.status];
        const statusRow = row[status];

        return (
          status === EQRC_RULE_STATUS.Active && statusRow?.[EqrcFields.hasBrokerList] === false
        );
      });

      let disabledBcConfigured = selected.find(row => {
        const status = row[EqrcFields.status];
        let oppositeStatus =
          status === EQRC_RULE_STATUS.Active
            ? EQRC_RULE_STATUS.Configured
            : EQRC_RULE_STATUS.Active;
        let oppositeRow = row[oppositeStatus];

        return (
          status === EQRC_RULE_STATUS.Active && oppositeRow?.[EqrcFields.hasBrokerList] === false
        );
      });

      let flag = "";
      if (disabledBcActive && disabledBcConfigured && isDisabled) {
        flag =
          "Broker list disabled because creating 'Today' broker list is not allowed, updating a 'Today' rule without a matching 'Next Day' rule is not allowed, and Shares Locate is set to 'Disabled'";
      } else if (disabledBcConfigured && isDisabled) {
        flag =
          "Broker list disabled because updating a 'Today' rule without a matching 'Next Day' rule is not allowed and Shares Locate is set to 'Disabled'";
      } else if (disabledBcActive && isDisabled) {
        flag =
          "Broker list disabled because creating 'Today' broker list is not allowed and Shares Locate is set to 'Disabled'";
      } else if (disabledBcActive && disabledBcConfigured) {
        flag =
          "Broker list disabled because updating a 'Today' rule without a matching 'Next Day' rule is not allowed and creating 'Today' broker list is not allowed";
      } else if (disabledBcActive) {
        flag = "Broker list disabled because creating 'Today' broker list is not allowed";
      } else if (disabledBcConfigured) {
        flag =
          "Broker list disabled updating a 'Today' rule without a matching 'Next Day' rule is not allowed";
      } else if (isDisabled) {
        flag = "Broker list disabled because Shares Locate is set to 'Disabled'";
      }

      formDispatch({
        type: "WRITE_FORM_VALUE",
        payload: {
          form,
          fields: {
            [`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`]: flag,
          },
        },
      });
    }
  }, [form, formDispatch, currentStep, selected, isDisabled]);

  return (
    <ActiveForm
      table={table}
      form={form}
      header={
        <Header
          activeTable={table}
          totalSteps={totalSteps}
          currentStep={currentStep}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          ruleType={ruleType}
        />
      }
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      isSaving={isSaving}
      includeExchMpidPort={false}
      setStep={setStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      validate={validate}
      disableMpidPort={true}
      FooterMessage={<FooterMessage selected={selected} />}
    />
  );
};
