import React, { useCallback, useEffect, useState } from "react";
import { EqrcRuleStatus, handleImplicitDecimals } from "../";
import { ActiveForm } from "../components/activeForm";

import {
  EQRC_FIELD_PREFIX,
  EQRC_RULE_STATUS,
  EQRC_SHARES_LOCATED_VALUES,
  EqrcFields,
  OPPOSITE_ROW,
  getEqrcIdFromResponse,
} from "../constants";
import { useFormContext } from "components/form";
import { getFormValidation, useStandardTableDispatch } from "components/standardTable";
import { createRule } from "../network";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { addPrefixToField } from "utils/js.utils";
import { preparePostBody } from "../transformers/bodies";
import { Forms } from "components/fields";
import { StandardTables } from "wksConstants";

export const EQRCNewForm = ({
  table,
  header,
  form,
  fieldSets,
  isOpen,
  onClose,
  setStep,
  currentStep,
  totalSteps,
  showResults,
}) => {
  const standardTableDispatch = useStandardTableDispatch();
  const [formData, formDispatch] = useFormContext();
  const [userData] = useUserContext();
  const [isSaving, setSaving] = useState(false);
  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form },
    });
  }, [form, formDispatch]);

  const handleCreateSuccess = useCallback(
    (table, json, CbArgs) => {
      const newRows = json.map(({ dto }) => {
        const eqrcId = getEqrcIdFromResponse(dto);
        const status =
          table === StandardTables.EQRC_ALERT_CONFIG
            ? EqrcRuleStatus.active
            : dto[EqrcFields.status];

        const newRow = { [EqrcFields.eqrcId]: eqrcId, [status]: dto, [EqrcFields.status]: status };

        handleImplicitDecimals(newRow[EqrcRuleStatus.configured]);
        handleImplicitDecimals(newRow[EqrcRuleStatus.active]);

        return newRow;
      });

      standardTableDispatch([
        {
          type: "ADD_TABLE_DATA",
          payload: { table, data: newRows },
        },
        { type: "SET_IS_LOADING", payload: { table, isLoading: false } },
      ]);

      setSaving(false);
      onClose();
      formDispatch({ type: "RESET_FORM", payload: { form } });

      showResults({
        data: json.map(row => {
          let { dto } = row;

          return {
            ...{
              status: EQRC_RULE_STATUS.Configured,
              [EQRC_RULE_STATUS.Configured]: {
                ...dto,
                requestStatus: { ...dto?.requestStatus, success: [] },
              },
            },
          };
        }),
      });
    },
    [standardTableDispatch, onClose, formDispatch, form, showResults]
  );

  const handleCreateError = useCallback(
    (table, error, originalRow) => {
      let groupedRows;

      if (Array.isArray(error)) {
        groupedRows = error.map(e => [e.message]);
      } else {
        groupedRows = {};
        Object.entries(error).forEach(([key, message]) => {
          const splitKey = key.split(".");
          const dtoKey = splitKey[0];
          const fieldKey = splitKey[1];

          const addSpaces = fieldKey.replace(/([A-Z])/g, " $1");
          const newKey = addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1);

          if (!groupedRows[dtoKey]) {
            groupedRows[dtoKey] = [];
          }
          groupedRows[dtoKey].push(`${newKey}: ${message}`);
        });

        if (!Array.isArray(error)) {
          error = Object.values(error).map((row, i) => row);
        }
      }

      const results = {
        data: Object.values(groupedRows).map((row, i) => {
          const { dto } = error[i];

          handleImplicitDecimals(originalRow[i]);

          const returnValue = {
            eqrcId: getEqrcIdFromResponse(dto),
            status: EQRC_RULE_STATUS.Configured,
            [EQRC_RULE_STATUS.Configured]: {
              ...dto,
              requestStatus: {
                ...dto?.requestStatus,
                error: (
                  <ul>
                    {row.map(row => (
                      <li>{row}</li>
                    ))}
                  </ul>
                ),
              },
            },
          };

          return returnValue;
        }),
      };

      showResults(results);

      standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
      setSaving(false);
    },
    [showResults, standardTableDispatch]
  );

  const validate = useCallback(
    () => getFormValidation(form, formData, formDispatch),
    [form, formData, formDispatch]
  );

  const handleSave = useCallback(() => {
    const valid = getFormValidation(form, formData, formDispatch);
    if (!valid) {
      return;
    }
    standardTableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: true } });
    setSaving(true);

    let fields = formData[form.key].fields;
    let body = preparePostBody(fields, table, form.key);

    if (table === StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST) {
      body.inputDTOs.forEach(dto => {
        if (!dto[EqrcFields.brokersListRule]) {
          dto[EqrcFields.brokersListRule] = { [EqrcFields.brokers]: [] };
        }
      });
    }

    createRule(
      userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
      false,
      body,
      table,
      handleCreateSuccess,
      handleCreateError,
      body.inputDTOs
    );
  }, [
    form,
    formData,
    formDispatch,
    standardTableDispatch,
    table,
    userData,
    handleCreateSuccess,
    handleCreateError,
  ]);

  //wtf
  const clearBrokers = useCallback(() => {
    formDispatch({
      type: "UPDATE_FORM_VALUE",
      payload: {
        form,
        field: EqrcFields.brokersListRule,
        value: undefined,
        id: addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule),
      },
    });
  }, [form, formDispatch]);

  const brokerList = formData[form.key].fields[EqrcFields.allowBrokerList];
  const isDisabled =
    formData[form.key].fields[EqrcFields.sharesLocatedOption]?.value !==
    EQRC_SHARES_LOCATED_VALUES.DISABLED;

  useEffect(() => {
    if (
      [Forms.EQRC_EDIT_SHARES_LOCATED_CHECK, Forms.EQRC_EDIT_SHARES_LOCATED_CHECK].includes(form)
    ) {
      formDispatch({
        type: "WRITE_FORM_VALUE",
        payload: {
          form,
          fields: {
            [`${OPPOSITE_ROW}${EqrcFields.allowBrokerList}`]: brokerList || isDisabled,
          },
        },
      });
    }
  }, [form, formDispatch, currentStep, brokerList, isDisabled]);
  return (
    <ActiveForm
      table={table}
      header={header}
      form={form}
      fieldSets={fieldSets}
      isOpen={isOpen}
      onClose={onClose}
      onSave={handleSave}
      isSaving={isSaving}
      includeExchMpidPort={true}
      setStep={setStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      validate={validate}
      onSecondary={clearBrokers}
    />
  );
};
