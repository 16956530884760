import { getColor } from "@nef/style-utils";
import { POR_CACHE_FILTER_BY, usePorCacheDispatch } from "components/pvRejects/porCache";
import { usePorCountState } from "components/pvRejects/porCountCache";
import { useStandardTableDispatch } from "components/standardTable";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { All_Or_Pending, MpidOrSymbol, PorOrPvr, usePORCacheContext } from "../context";
import { DT } from "./dataArray";
import { HeaderBox } from "./tile.container";
import { TileFront } from "./tile.front";

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

const sortByCount = (a: { label: string; count: number }, b: { label: string; count: number }) => {
  if (a.label === "All") {
    return -1;
  } else if (b.label === "All") {
    return 1;
  } else {
    return b.count - a.count;
  }
};

export const Front = ({
  toggle,
  isAllOrPending,
}: {
  toggle: () => void;
  isAllOrPending: All_Or_Pending;
}) => {
  const { dispatch } = usePORCacheContext();
  const porCountState = usePorCountState();
  const porCacheDispatch = usePorCacheDispatch();
  const tableDispatch = useStandardTableDispatch();

  const handleSetFilterBy = useCallback(
    (filterBy: POR_CACHE_FILTER_BY, keys: string[]) => () => {
      tableDispatch({
        type: "DESELECT_ALL_ROWS",
        payload: { table: StandardTables.PV_SUPERVISOR_MONITOR2 },
      });
      porCacheDispatch({
        type: "START_POLLING",
        payload: {
          filterBy,
          keys,
        },
      });
    },
    [porCacheDispatch, tableDispatch]
  );

  const {
    mpidCountArray,
    mpidPendingArray,
    symbolCountArray,
    symbolPendingArray,
    totalCount,
    pendingCount,
  } = useMemo(() => {
    let totalCount = 0,
      pendingCount = 0;
    const mpidCountArray = Object.entries(porCountState.counts.mpidCounts).map(([mpid, count]) => {
      totalCount += count;
      return {
        label: mpid,
        count,
        value: (
          <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.MPID, [mpid])}>
            {count}
          </ArrayButton>
        ),
      };
    });
    const mpidPendingArray = Object.entries(porCountState.pending.mpidCounts).map(
      ([mpid, count]) => {
        pendingCount += count;
        return {
          label: mpid,
          count,
          value: (
            <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.MPID, [mpid])}>
              {count}
            </ArrayButton>
          ),
        };
      }
    );
    const symbolCountArray = Object.entries(porCountState.counts.symbolCounts).map(
      ([symbol, count]) => {
        return {
          label: symbol,
          count,
          value: (
            <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.SYMBOL, [symbol])}>
              {count}
            </ArrayButton>
          ),
        };
      }
    );
    const symbolPendingArray = Object.entries(porCountState.pending.symbolCounts).map(
      ([symbol, count]) => {
        return {
          label: symbol,
          count,
          value: (
            <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.SYMBOL, [symbol])}>
              {count}
            </ArrayButton>
          ),
        };
      }
    );
    const allTotal = {
      label: "All",
      count: totalCount,
      value: (
        <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.ALL, [])}>
          {totalCount}
        </ArrayButton>
      ),
    };
    const allPending = {
      label: "All",
      count: pendingCount,
      value: (
        <ArrayButton onClick={handleSetFilterBy(POR_CACHE_FILTER_BY.ALL, [])}>
          {pendingCount}
        </ArrayButton>
      ),
    };
    mpidCountArray.push(allTotal);
    symbolCountArray.push(allTotal);
    mpidPendingArray.push(allPending);
    symbolPendingArray.push(allPending);

    mpidCountArray.sort(sortByCount);
    symbolCountArray.sort(sortByCount);
    mpidPendingArray.sort(sortByCount);
    symbolPendingArray.sort(sortByCount);

    return {
      totalCount,
      pendingCount,
      mpidCountArray,
      mpidPendingArray,
      symbolCountArray,
      symbolPendingArray,
    };
  }, [porCountState.counts, porCountState.pending]);

  const onMpidClick = useCallback(
    (id: any, count: any) => () => {
      // dispatch({ type: "SET_REQUEST_TABLE", payload: { count, id } });
      dispatch({ type: "SET_SELECTED", payload: { selected: id } });
    },
    [dispatch]
  );

  return (
    <TileFront
      HeaderBox={
        <HeaderBox
          toggle={toggle}
          hasAllOrPending={true}
          hasMpidOrSymbol={false}
          isAllOrPending={isAllOrPending}
          total={isAllOrPending === "All" ? totalCount : pendingCount}
          title={"POR"}
          isPorOrPvr={PorOrPvr.POR}
        />
      }
      title1="By Symbol"
      title2="By MPID"
      data1={
        <DT
          data={isAllOrPending === "All" ? symbolCountArray : symbolPendingArray}
          isMpidOrSymbol={MpidOrSymbol.SECURITY}
        />
      }
      data2={
        <DT
          data={isAllOrPending === "All" ? mpidCountArray : mpidPendingArray}
          isMpidOrSymbol={MpidOrSymbol.MPID}
        />
      }
    />
  );
};
