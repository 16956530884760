import { getEqrcIdFromResponse } from "../constants";

export const callbacks = {
  success: ({
    json,
    success,
    failed,
    payloadKey,
  }: {
    json: { successful: any[]; failed: any[] };
    success: { [ruleId: string]: any[] };
    failed: { [ruleId: string]: any[] };
    payloadKey: string;
  }) => {
    json.successful.forEach(row => {
      const eqrcId = getEqrcIdFromResponse(row.dto);

      success[eqrcId] = row;
    });
    (json.failed || []).forEach(row => {
      const eqrcId = getEqrcIdFromResponse(row.dto);
      failed[eqrcId] = row;
    });
  },
  fail: ({
    json,
    failed,
    cbArgs,
  }: {
    json: { successful: any[]; failed: any[] };
    failed: { [ruleId: string]: any[] };
    cbArgs: any;
  }) => {
    if (Array.isArray(json)) {
      json.forEach((row: any, i) => {
        const eqrcId = getEqrcIdFromResponse(row.dto);
        if (!failed[eqrcId]) {
          failed[eqrcId] = [];
        }
        failed[eqrcId].push(row);
      });
    } else {
      Object.entries(json).forEach(([dtoKey, message]: any[]) => {
        const [dto] = dtoKey.split(".");
        const index = dto.slice(-2, -1);
        const eqrcId = cbArgs[index].eqrcId;
        if (!failed[eqrcId]) {
          failed[eqrcId] = [];
        }
        const matchedCbArg = cbArgs[index];
        const { originalRow, status } = matchedCbArg;
        failed[eqrcId].push({ dto: { ...originalRow[status], status }, message });
      });
    }
  },
};
