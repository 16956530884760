import { EQRC_SHARES_LOCATED_VALUES, EqrcFields, getEqrcIdFromResponse } from "../constants";
import { StandardTables } from "wksConstants";
import { keyPruner } from "../transformers/keyPruner";
import { genericEQRCRequest, methods, urls } from "../network";
import { genericAddRemove } from "./generic";

export const addRemoveSymbolsUpdateLocateCheck = async ({
  url,
  table,
  body,
  cbArgs,
  callback,
  errorCallback,
}) => {
  const rows = {};

  const { newRules, updatedRules } = body.inputDTOs.reduce(
    (acc, dto) => {
      const eqrcId = getEqrcIdFromResponse(dto);
      const cbArgsRow = cbArgs.find(row1 => row1.originalRow[EqrcFields.eqrcId] === eqrcId);
      const { status } = dto;
      let newBrokers;

      if (dto[EqrcFields.enableBrokerList] !== true) {
        return acc;
      }

      if (dto[EqrcFields.brokersListRule]) {
        newBrokers = dto[EqrcFields.brokersListRule][EqrcFields.brokers];
      } else {
        newBrokers = dto[EqrcFields.brokers];
      }

      if (dto[EqrcFields.enableBrokerList] === true) {
        if (newBrokers === undefined) {
          newBrokers = [];
        }
      }

      const { brokers: currentBrokers } = cbArgsRow?.originalRow?.[status]?.brokersListRule || {
        originalRow: { [status]: { brokers: [] } },
      };

      // are we trying to change brokers
      if (newBrokers) {
        const { originalRow, status } = cbArgsRow;

        // this rule does not yet have a broker list
        if (originalRow[status].hasBrokerList === false) {
          acc.newRules[eqrcId] = {
            dto: keyPruner(StandardTables.EQRC_BROKER_LIST, dto),
            newBrokers,
            currentBrokers,
          };

          // we have a pervious broker list
        } else {
          acc.updatedRules[eqrcId] = {
            dto: keyPruner(StandardTables.EQRC_BROKER_LIST, dto),
            newBrokers,
            currentBrokers,
          };
        }
      }

      rows[eqrcId] = { dto, newSymbols: newBrokers, currentSymbols: currentBrokers };

      return acc;
    },
    { newRules: {}, updatedRules: {} }
  );

  let success = {};
  let failed = {};
  let newBrokers, currentBrokers;

  if (
    body?.inputDTOs?.[0]?.[EqrcFields.sharesLocatedOption] !==
      EQRC_SHARES_LOCATED_VALUES.DISABLED &&
    body?.inputDTOs?.[0]?.[EqrcFields.enableBrokerList] === true
  ) {
    const newBrokerInputDTOs = Object.values(newRules).map(row => {
      return keyPruner(StandardTables.EQRC_BROKER_LIST, {
        ...row.dto,
        brokers: row.newBrokers,
      });
    });
    // create new broker list rules
    if (Object.keys(newRules).length > 0) {
      const result = await genericEQRCRequest({
        url: url.replace(
          urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
          urls[StandardTables.EQRC_BROKER_LIST]
        ),
        method: methods.post,
        body: { inputDTOs: newBrokerInputDTOs },
        table,
        cbArgs: cbArgs.filter(row => {
          return Object.keys(newRules).includes(row.eqrcId);
        }),
        callback,
        errorCallback,
      });

      Object.assign(failed, result);
    }

    // update existing broker list rules
    if (Object.keys(updatedRules).length > 0) {
      const result = await genericAddRemove({
        url: url.replace(
          urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
          urls[StandardTables.EQRC_BROKER_LIST]
        ),
        table,
        body: { inputDTOs: Object.values(updatedRules) },
        rows,
        cbArgs: cbArgs.filter(row => {
          return Object.keys(updatedRules).includes(row.eqrcId);
        }),
        newBrokers,
        currentBrokers,
        callback,
        urlSubRoutes: { add: "addBrokers", remove: "removeBrokers" },
        payloadKey: "brokers",
        responseTransform: row => {
          row.dto[EqrcFields.brokersListRule] = {
            [EqrcFields.brokers]: row.dto[EqrcFields.brokers],
          };

          return row;
        },
      });

      Object.assign(failed, result.failed);
    }
  }

  let { sharesInputDTOs, noops } = body.inputDTOs.reduce(
    (acc, row) => {
      const eqrcId = getEqrcIdFromResponse(row);
      const cbArgsRow = cbArgs.find(row1 => row1.originalRow[EqrcFields.eqrcId] === eqrcId);
      const status = cbArgsRow[EqrcFields.status];
      if (
        row[EqrcFields.sharesLocatedOption] ===
          cbArgsRow.originalRow[status][EqrcFields.sharesLocateRule][
            EqrcFields.sharesLocatedOption
          ] &&
        row[EqrcFields.sharesLocatedSSE] ===
          cbArgsRow.originalRow[status][EqrcFields.sharesLocatedSSE]
      ) {
        acc.noops.push({ dto: row });
      } else {
        acc.sharesInputDTOs.push(row);
      }

      return acc;
    },
    { sharesInputDTOs: [], noops: [] }
  );

  sharesInputDTOs = sharesInputDTOs.map(row =>
    keyPruner(StandardTables.EQRC_SHARES_LOCATED_CHECK, row)
  );

  if (noops.length > 0) {
    callback(table, noops, cbArgs, {
      showNotification: false,
      resetForm: true,
      doOnClose: true,
      noop: true,
    });
  }
  let sharesLocateMessage = undefined;
  if (sharesInputDTOs.length > 0) {
    await genericEQRCRequest({
      url: url.replace(
        urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST],
        urls[StandardTables.EQRC_SHARES_LOCATED_CHECK]
      ),
      method: methods.put,
      body: { inputDTOs: sharesInputDTOs },
      table,
      cbArgs,
      callback,
      errorCallback,
    });
  }
  success = Object.values(success);
  if (success.length > 0 && sharesLocateMessage === undefined) {
    callback(table, success, cbArgs, {
      showNotification: false,
      resetForm: true,
      doOnClose: true,
    });
  }

  if (Object.entries(failed).length > 0) {
    sharesLocateMessage && failed.push({ message: sharesLocateMessage });

    errorCallback(table, failed, cbArgs, {
      showNotification: false,
      resetForm: false,
      doOnClose: false,
      deselectRows: false,
    });
  }
};
