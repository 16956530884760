import { REJ_PRICE_OO_OVERRIDE_RANGE } from "components/pvRejects/constant";
import React, { useCallback } from "react";
import { usePvMemberDispatch } from "./context";

export const RejectCol: React.FC<{ numRejects: number; symbol: string }> = ({
  numRejects,
  symbol,
}) => {
  const pvMemberDispatch = usePvMemberDispatch();

  const handleClickRejects = useCallback(() => {
    pvMemberDispatch({
      type: "SET_FILTER",
      payload: {
        symbol,
        reject: REJ_PRICE_OO_OVERRIDE_RANGE,
      },
    });
  }, [pvMemberDispatch, symbol]);

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a onClick={handleClickRejects}>{numRejects}</a>;
};
