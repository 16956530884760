import { Button, Popover, PopoverBody } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useCallback } from "react";
import styled from "styled-components";
import { useFormContext } from "components/form/formContext";
import {
  EmailManagerForm,
  PVR_EMAIL_ENTITY,
  handleSaveNewPvrEmail,
  usePvrEmailDispatch,
} from "../email";
import { ADD_EMAIL_BTN_ID } from "../constants";

const EmailPopoverButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  justify-content: end;
`;

export const PTRAddEmailPopover: React.FC<{
  isOpen: boolean;
  onToggle: () => void;
  containerRef: React.RefObject<HTMLElement>;
}> = ({ isOpen, onToggle, containerRef }) => {
  const [formData] = useFormContext();
  const emailDispatch = usePvrEmailDispatch();

  const handleClose = useCallback(() => {
    if (typeof onToggle === "function") {
      onToggle();
    }
  }, [onToggle]);

  const handleSave = useCallback(() => {
    const newEmail = {
      [PVR_EMAIL_ENTITY.recipientEmail]:
        formData[Forms.PVR_EMAIL_MANAGER.key].fields[PVR_EMAIL_ENTITY.recipientEmail],
    };
    handleSaveNewPvrEmail({
      baseUrl: process.env.REACT_APP_URL_PVR_ALERT_SERVICE,
      emailDispatch,
      onDone: handleClose,
      newEmail,
    });
  }, [emailDispatch, formData, handleClose]);

  return (
    <Popover
      isOpen={isOpen}
      target={ADD_EMAIL_BTN_ID}
      placement="top-end"
      closeOnScroll={true}
      toggle={onToggle}
      width={350}
      containInParent={true}
      containerRef={containerRef}
    >
      <PopoverBody>
        <EmailManagerForm />
        <EmailPopoverButtonWrapper>
          <Button onClick={handleClose} color="primary" outline={true} size="sm">
            Close
          </Button>
          <Button onClick={handleSave} color="primary" size="sm">
            Save
          </Button>
        </EmailPopoverButtonWrapper>
      </PopoverBody>
    </Popover>
  );
};
