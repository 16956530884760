import { FieldLoop, pvrAlertFields1, pvrAlertFields1_FINRA } from "components/fields";
import { PtraFieldSection, SectionHeader } from "../constants";
import React from "react";

export const PvrMPIDOptions: React.FC<PtraFieldSection & { enableAllMpid: boolean }> = ({
  form,
  enableAllMpid,
}) => {
  return (
    <section>
      <SectionHeader>MPID Options</SectionHeader>
      <FieldLoop
        isReactFragment={true}
        form={form}
        fields={enableAllMpid ? pvrAlertFields1_FINRA : pvrAlertFields1}
        classNames={undefined}
        augmentOnChange={undefined}
        augmentOnCreate={undefined}
        portalRef={undefined}
        isDisabled={undefined}
        containerRef={undefined}
        showLabel={undefined}
      />
    </section>
  );
};
