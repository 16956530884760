import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StandardTables, Status } from "wksConstants";
import { doFetch } from "network";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { getHeaders } from "keycloak";
import { CurrentGrossExposure } from "./eqrc.types";
import { useFormContext } from "components/form";
import { Forms } from "components/fields";
import { EqrcFields } from "components/settings/eqrc/constants";
import { SelectOption } from "types";
import { OutBoundData, Payload } from "./tableTransport.types";
import { useEqrcCacheDispatch, Charts_Or_Table } from "./dataCacheContext";
import { formatUrl } from "utils/js.utils";
import { CurrentExposureData, CurrentExposureMap } from "./cache.types";
import { EqrcRuleContextState, handleImplicitDecimals } from "components/settings";
import { isFulfilled, isRejected, joinToKeyString } from "./utils";
import { useStandardTableDispatch } from "components/standardTable";
import { EqrcRules } from "components/settings/eqrc/types.consts";
import { GrossExposure } from "components/settings/eqrc/types.rules";
import { EqrcCacheAction } from "./dataCacheContext.types";

export type Args = { ports: SelectOption[] | undefined; mpid: undefined | { value: string } };

export const useTableData = ({
  shouldRequest,
  isRequesting,
  shouldMakeFirstRequest,
  isWindowFocused,
  currentExposure,
  rulesData,
  globalError,
}: {
  shouldRequest: boolean;
  isRequesting: boolean;
  shouldMakeFirstRequest: boolean;
  isWindowFocused: boolean;
  currentExposure: CurrentExposureMap;
  rulesData: EqrcRuleContextState;
  globalError: boolean;
}) => {
  const [formData] = useFormContext();
  const eqrcDispatch = useEqrcCacheDispatch();
  const abort = useRef<AbortController | null>(null);
  const eqrcForm = formData[Forms.EQRC_TOP.key].fields;
  const [user] = useUserContext();
  const tableDispatch = useStandardTableDispatch();
  const [args, setArgs] = useState<Args>({
    mpid: undefined,
    ports: undefined,
  });

  const rules = useMemo(() => {
    const mappedRules = new Map();
    (rulesData?.[EqrcRules.grossExposure]?.ACTIVE || []).forEach(r => {
      const cacheKey = joinToKeyString(r);

      mappedRules.set(cacheKey, r);
    });

    return mappedRules;
  }, [rulesData]);

  const onExposureBreachCacheSuccess = useCallback(
    (data: {
      collections: {
        [exchangeMpidPortGroupId: string]: { items: CurrentGrossExposure[]; offset: number };
      };
      isSuccess: boolean;
      isFirst: boolean;
      currentCacheKeys: { [exchangeMpidPortGroupId: string]: {} };
    }) => {
      const rowsToUpdate: { [exchangeMpidPortGroupId: string]: CurrentExposureData } = {};

      if (!rules.size) {
        return;
      }

      rules.forEach((rule, key, map) => {
        if (Object.keys(data.currentCacheKeys).includes(key)) {
          // either we got new current exposure data for this rule,
          // else use cached current exposure values
          // else make an empty row
          let row: CurrentGrossExposure | undefined =
            data?.collections?.[key]?.items[data?.collections?.[key]?.items.length - 1];

          // convert new xhr data, do not convert cached data
          if (row) {
            handleImplicitDecimals(row);
          } else {
            row = currentExposure.get(key)?.data;
          }

          // we have a rule, but we don't have any (cache or new)) exposure data,
          // so synthetic row
          if (!row && !currentExposure.get(key)) {
            row = {
              notionalExposure: 0,
              openExposure: 0,
              execExposure: 0,
              exchange: rule.exchange,
              groupId: rule.groupId,
              port: rule.port,
              mpid: rule.mpid,
              synthetic: true,
            };
          }

          //row matches user's selection
          if (
            row &&
            rule.mpid === args?.mpid?.value &&
            (args.ports === undefined || args.ports.map(p => p.value).includes(rule.port))
          ) {
            rowsToUpdate[key] = {
              offset: data?.collections?.[key]?.offset || 0,
              data: row,
            };
          }
        }
      });

      eqrcDispatch([
        {
          type: "SET_IS_REQUESTING",
          payload: { isRequesting: false, view: Charts_Or_Table.table },
        },
        {
          type: "SET_STATUS",
          payload: {
            view: Charts_Or_Table.table,
            status: data.isSuccess ? Status.SUCCESS : Status.ERROR,
          },
        },
        { type: "SET_DATA_TABLE", payload: { dataToSet: rowsToUpdate, rules } },
      ] as EqrcCacheAction[]);
    },
    [args?.mpid?.value, args.ports, currentExposure, eqrcDispatch, rules]
  );

  const onExposureBreachCacheFail = useCallback(() => {
    eqrcDispatch([
      { type: "SET_IS_REQUESTING", payload: { isRequesting: false, view: Charts_Or_Table.table } },
      { type: "SET_STATUS", payload: { view: Charts_Or_Table.table, status: Status.ERROR } },
    ]);
  }, [eqrcDispatch]);

  const rulesDataMemo = useMemo(() => rulesData[EqrcRules.grossExposure].ACTIVE, [rulesData]);

  const onExposureBreachSuccess = useCallback(async () => {
    const rules = rulesDataMemo;

    // there are no rules
    if (rules?.length === 0) {
      eqrcDispatch([
        {
          type: "SET_IS_REQUESTING",
          payload: { isRequesting: false, view: Charts_Or_Table.table },
        },
        { type: "SET_STATUS", payload: { view: Charts_Or_Table.table, status: Status.SUCCESS } },
        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { view: Charts_Or_Table.table, shouldMake: false },
        },
      ]);

      tableDispatch({
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.EQRC_MONITORING, isLoading: false },
      });
      return;
    }

    let mpid = eqrcForm[EqrcFields.mpid] as SelectOption;
    if (mpid !== null) {
      let ports =
        eqrcForm[EqrcFields.port] && eqrcForm[EqrcFields.port]?.length > 0
          ? eqrcForm[EqrcFields.port].map((p: SelectOption) => p.value)
          : undefined;

      // sort rules by exchange
      const exchanges = Object.entries(rules || []).reduce<OutBoundData>((acc, [k, v]) => {
        if (!acc[v.exchange]) {
          acc[v.exchange] = {};
        }

        const cacheKey = joinToKeyString(v);

        acc[v.exchange][cacheKey] = v;
        return acc;
      }, {} as OutBoundData);

      // track a list of cache keys that have data and should be disaplyed (base on user selection),
      // so the table knows which rows to pluck to render
      const currentCacheKeys: { [exchangeMpidPortGroupId: string]: {} } = {};

      if (abort.current) {
        abort.current.abort();
      }

      // use the same abort controller for all requests in this loop
      const abortController = new AbortController();
      abort.current = abortController;

      Promise.allSettled(
        Object.entries(exchanges).map(async ([exchange, v], i) => {
          const payload: Payload = {};
          Object.entries(v).forEach(async ([k, vv]: [string, GrossExposure]) => {
            // rule mpid must be the users selected mpid
            if (mpid.value === vv.mpid) {
              // user has set port(s), only add rules port(s), that match the users selection, to request
              if (ports?.length && (vv.port === "" || !(ports || []).includes(vv.port))) {
                return;
              }

              // rule does not belong to this exchange
              if (vv.exchange !== exchange) {
                return;
              }

              const cacheKey = joinToKeyString(vv);
              const cacheValue = currentExposure.get(cacheKey);

              // if we have an offset, use it, but only if we have receive data for this key before
              const offset = cacheValue && !cacheValue.data.synthetic ? cacheValue.offset + 1 : 0;

              payload[cacheKey] = currentCacheKeys[cacheKey] = {
                start: offset || 0,
                end: -1,
                keys: {
                  clientId: vv.mpid,
                  ...(vv.port !== "" && { accountName: vv.port }),
                  ...(vv.groupId !== 0 && { groupId: vv.groupId }),
                },
              };
            }
          });

          if (process.env.REACT_APP_USE_EQRC_REQUEST_COMPRESSION === "true") {
            const stream = new Blob([JSON.stringify(payload)], {
              type: "application/json",
            }).stream();

            const compressedReadableStream = stream.pipeThrough(
              new (window as any).CompressionStream("gzip")
            );

            return doFetch(
              formatUrl(
                user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.configApiCacheUrl],
                `cache/eqrc/config-api-cache/collection/range/eqrc-exposure-${exchange.toLowerCase()}`
              ),
              {
                method: "post",
                signal: abortController.signal,
                mode: "cors",

                headers: {
                  ...getHeaders(),
                  "Content-Type": "text/plain; charset=utf-8",
                },
                body: compressedReadableStream,
                duplex: "half",
              }
            );
          } else {
            return doFetch(
              formatUrl(
                user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.configApiCacheUrl],
                `cache/eqrc/config-api-cache/collection/range/eqrc-exposure-${exchange.toLowerCase()}`
              ),
              {
                method: "post",
                mode: "cors",

                signal: abortController.signal,
                headers: {
                  ...getHeaders(),
                },
                body: JSON.stringify(payload),
              }
            );
          }
        })
      )
        .then(results => {
          eqrcDispatch([
            {
              type: "SET_CURRENTLY_USED_CACHE_KEYS",
              payload: { data: Object.keys(currentCacheKeys), view: Charts_Or_Table.table },
            },
          ]);

          let successCount = 0;
          const parsed = results.reduce(
            (acc, curr, i) => {
              if (isFulfilled(curr) && curr?.value?.response?.ok) {
                successCount++;
                Object.assign(acc.success, curr?.value?.json.collections);
              } else if (isRejected(curr)) {
                if (curr.status === "rejected" && curr.reason.code !== 20) {
                  Object.assign(acc.fail, { [i]: curr.reason });
                }
              } else if (!curr.value.response.ok) {
                Object.assign(acc.fail, { [i]: curr.value });
              }

              return acc;
            },
            { success: {}, fail: {} }
          );

          if (successCount > 0) {
            onExposureBreachCacheSuccess({
              collections: parsed.success,
              isSuccess: Object.keys(parsed.fail).length === 0,
              isFirst: shouldMakeFirstRequest,
              currentCacheKeys: currentCacheKeys,
            });
          }

          if (Object.values(parsed.fail).length > 0) {
            onExposureBreachCacheFail();
          }
        })
        .catch(e => {
          console.error(e);
          onExposureBreachCacheFail();
        });
    }
  }, [
    rulesDataMemo,
    eqrcForm,
    eqrcDispatch,
    tableDispatch,
    user,
    currentExposure,
    onExposureBreachCacheSuccess,
    shouldMakeFirstRequest,
    onExposureBreachCacheFail,
  ]);

  // entry point for xhr / rendering path
  const getGrossExposure = useCallback(() => {
    eqrcDispatch([
      {
        type: "SET_SHOULD_MAKE_FIRST_REQUEST",
        payload: { view: Charts_Or_Table.table, shouldMake: false },
      },
      { type: "SET_IS_REQUESTING", payload: { view: Charts_Or_Table.table, isRequesting: true } },
    ]);

    onExposureBreachSuccess();
  }, [eqrcDispatch, onExposureBreachSuccess]);

  useEffect(() => {
    if (globalError) {
      eqrcDispatch({
        type: "SET_STATUS",
        payload: { view: Charts_Or_Table.table, status: Status.ERROR },
      });
      return;
    }

    // handle loss of focus
    if (!isWindowFocused) {
      eqrcDispatch([
        {
          type: "CLEAR_TIMEOUT",
          payload: { view: Charts_Or_Table.table },
        },
        {
          type: "SET_IS_REQUESTING",
          payload: { view: Charts_Or_Table.table, isRequesting: false },
        },
        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { view: Charts_Or_Table.table, shouldMake: true },
        },
      ]);

      if (abort.current) {
        abort.current.abort();
      }
      return;
    }

    // handle args changed
    if (args.mpid === eqrcForm[EqrcFields.mpid] && args.ports === eqrcForm[EqrcFields.port]) {
      if (isRequesting) {
        return;
      }
    } else {
      setArgs({ mpid: eqrcForm[EqrcFields.mpid], ports: eqrcForm[EqrcFields.port] });
      if (eqrcForm[EqrcFields.mpid] === undefined) {
        eqrcDispatch([
          {
            type: "SET_STATUS",
            payload: { view: Charts_Or_Table.table, status: Status.NO_STATUS },
          },
          { type: "SET_SHOULD_ABORT", payload: true },
        ]);
      }
      const dispatches = [
        {
          type: "CLEAR_TIMEOUT",
          payload: { view: Charts_Or_Table.table },
        },

        {
          type: "SET_SHOULD_MAKE_FIRST_REQUEST",
          payload: { view: Charts_Or_Table.table, shouldMake: true },
        },
      ] as EqrcCacheAction[];
      if (eqrcForm[EqrcFields.mpid] !== undefined) {
        dispatches.push({
          type: "SET_CURRENTLY_USED_CACHE_KEYS",
          payload: {
            data: null,
            view: Charts_Or_Table.table,
          },
        });
      } else {
        dispatches.push({
          type: "SET_CURRENTLY_USED_CACHE_KEYS",
          payload: {
            data: [],
            view: Charts_Or_Table.table,
          },
        });
      }
      eqrcDispatch(dispatches);

      if (eqrcForm[EqrcFields.mpid] !== undefined) {
        tableDispatch({
          type: "SET_IS_LOADING",
          payload: { table: StandardTables.EQRC_MONITORING, isLoading: true },
        });
      }
      return;
    }

    if (!rules.size && args.mpid !== undefined) {
      eqrcDispatch([
        {
          type: "SET_STATUS",
          payload: { view: Charts_Or_Table.table, status: Status.SUCCESS },
        },
      ]);

      tableDispatch({
        type: "SET_IS_LOADING",
        payload: { table: StandardTables.EQRC_MONITORING, isLoading: false },
      });

      return;
    }

    let mpid = args.mpid;

    if (!isRequesting && shouldRequest && mpid !== undefined && isWindowFocused) {
      if (shouldMakeFirstRequest) {
        getGrossExposure();
      } else {
        eqrcDispatch([
          {
            type: "CLEAR_TIMEOUT",
            payload: { view: Charts_Or_Table.table },
          },
          {
            type: "SET_REQUEST_TIMEOUT",
            payload: {
              view: Charts_Or_Table.table,
              timeOut: setTimeout(() => {
                getGrossExposure();
              }, 8000),
            },
          },
          {
            type: "SET_SHOULD_MAKE_FIRST_REQUEST",
            payload: { view: Charts_Or_Table.table, shouldMake: false },
          },
        ]);
      }
      return;
    } else if (isRequesting && abort.current === null) {
      eqrcDispatch([
        {
          type: "CLEAR_TIMEOUT",
          payload: { view: Charts_Or_Table.table },
        },
        {
          type: "SET_IS_REQUESTING",
          payload: { view: Charts_Or_Table.table, isRequesting: false },
        },
      ]);
    }
  }, [
    eqrcDispatch,
    args,
    getGrossExposure,
    isRequesting,
    isWindowFocused,
    shouldRequest,
    shouldMakeFirstRequest,
    rules,
    eqrcForm,
    tableDispatch,
    globalError,
  ]);
};
