import { BarChart } from "@nef/charts";
import { SectionBody, Section, SectionHeader, Box } from "@nef/core";
import { LimoLVCIndicator } from "components/limitMonitor";
import { TileData, Tiles } from "./tiles";
import { memo, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useEqrcCacheContext } from "./dataCacheContext";
import {
  ALL_BREACHES,
  BREACHES,
  EXPOSURE_BREACHES,
  EXPOSURE_BREACHES_LABELS_SHORT,
  EXPOSURE_LEVELS,
  ORDER_TYPE_BREACHES,
  ORDER_TYPE_BREACHES_WITH_ORDER_TYPE,
} from "./eqrc.types";
import { enumKeys } from "./utils";
import { RecapProvider, useRecapDispatch } from "../tradeDetailRecap/recapContext";
import { WorkXBottomDrawer } from "components/tradeDetailRecap";
import { SingleOrderTable } from "./singleOrder.table";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";

type TableProps = {
  numberOfTables: number;
};

const Tables = styled.div<TableProps>`
  ${props => `
    overflow: scroll !important;
    height: 97%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(${props.numberOfTables}, 315px);
    grid-gap: 1rem;
  `}
`;

const StyledSection = styled(Section)`
  grid-row: 2 / 3;
  grid-column: span 5;
`;

export const ChartsInner = memo(() => {
  const { state: eqrcCacheData } = useEqrcCacheContext();
  const [userData] = useUserContext();

  const recapDispatch = useRecapDispatch();

  useEffect(() => {
    if (!eqrcCacheData.isDetailsOpen) {
      recapDispatch({ type: "SET_OPEN", payload: false });
    }
  }, [eqrcCacheData.isDetailsOpen, recapDispatch]);

  const singleOrders = useMemo(() => {
    const singleOrder: { [cacheKey: string]: TileData } = {};

    enumKeys(BREACHES).forEach(breach => {
      singleOrder[breach] = { name: BREACHES[breach], value: 0 };
    });

    eqrcCacheData.status.charts?.currentUsedCacheKeys?.forEach((cacheKey, k) => {
      const breachType: ALL_BREACHES = cacheKey.split("-")[0] as ALL_BREACHES;

      if (
        [
          EXPOSURE_BREACHES.GROSS_EXECUTED_EXPOSURE,
          EXPOSURE_BREACHES.GROSS_NOTIONAL_EXPOSURE,
          EXPOSURE_BREACHES.GROSS_OPEN_EXPOSURE,
        ].includes(breachType as EXPOSURE_BREACHES)
      ) {
        return;
      }

      if (userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcShowSubOrderTypes]) {
        if (enumKeys(ORDER_TYPE_BREACHES).includes(breachType as BREACHES)) {
          singleOrder[BREACHES.ORDER_TYPE].value +=
            eqrcCacheData.singleOrder.get(cacheKey)?.data?.length || 0;
        }
      }
      singleOrder[breachType].value += eqrcCacheData.singleOrder.get(cacheKey)?.data?.length || 0;
    });

    return Object.values(singleOrder);
  }, [eqrcCacheData.singleOrder, eqrcCacheData.status.charts?.currentUsedCacheKeys, userData]);

  const exposureBreaches = useMemo(() => {
    const exposure: { [cacheKey: string]: any } = {};

    enumKeys(EXPOSURE_BREACHES).forEach(breach => {
      exposure[EXPOSURE_BREACHES_LABELS_SHORT[breach]] = {
        name: EXPOSURE_BREACHES_LABELS_SHORT[breach],
        values: [
          { name: EXPOSURE_LEVELS.ACTION, value: 0 },
          { name: EXPOSURE_LEVELS.WARN, value: 0 },
          { name: EXPOSURE_LEVELS.WATCH, value: 0 },
        ],
      };
    });

    const LevelToArrayIndex = {
      [EXPOSURE_LEVELS.ACTION]: 0,
      [EXPOSURE_LEVELS.WARN]: 1,
      [EXPOSURE_LEVELS.WATCH]: 2,
    };
    eqrcCacheData.status.charts?.currentUsedCacheKeys?.forEach((cacheKey, k) => {
      const split = cacheKey.split("-");
      const breachType: ALL_BREACHES = split[0] as ALL_BREACHES;
      const level: EXPOSURE_LEVELS = split[1] as EXPOSURE_LEVELS;
      if (
        [
          EXPOSURE_BREACHES.GROSS_EXECUTED_EXPOSURE,
          EXPOSURE_BREACHES.GROSS_NOTIONAL_EXPOSURE,
          EXPOSURE_BREACHES.GROSS_OPEN_EXPOSURE,
        ].includes(breachType as EXPOSURE_BREACHES)
      ) {
        exposure[EXPOSURE_BREACHES_LABELS_SHORT[breachType as EXPOSURE_BREACHES]].values[
          LevelToArrayIndex[level]
        ].value += eqrcCacheData.exposure.get(cacheKey)?.[level]?.data?.length || 0;
      }
    });

    return Object.values(exposure);
  }, [eqrcCacheData.exposure, eqrcCacheData.status.charts?.currentUsedCacheKeys]);

  return (
    <>
      <StyledSection>
        <SectionHeader
          title={
            <>
              <span>Single Order Breaches</span>
              <Box marginLeft={3}>
                <LimoLVCIndicator
                  id="eqrc-single-order-data-feed-status"
                  status={eqrcCacheData.status.charts.feedStatus}
                />
              </Box>
            </>
          }
        />
        <SectionBody>
          <Tiles data={singleOrders} />
        </SectionBody>
      </StyledSection>

      <StyledSection>
        <SectionHeader
          title={
            <>
              <span>Exposure Breaches</span>
              <Box marginLeft={3}>
                <LimoLVCIndicator
                  id="eqrc-exposure-data-feed-status"
                  status={eqrcCacheData.status.charts.feedStatus}
                />
              </Box>
            </>
          }
        />
        <SectionBody>
          {
            <BarChart
              yAxisLabel="Number of Breaches"
              legend={true}
              data={exposureBreaches}
              yGridLines={true}
              logScale={false}
            />
          }
        </SectionBody>
      </StyledSection>
    </>
  );
});

export const Charts = () => {
  const { state: eqrcCacheData, dispatch: eqrcDispatch } = useEqrcCacheContext();
  return (
    <RecapProvider>
      <ChartsInner />
      <WorkXBottomDrawer
        widthFactor={1}
        widthFunction={() => "1030px"}
        title={`
          Single Order Breaches (${
            Object.entries(eqrcCacheData.detailsTablesRows).length
          } displayed)
      `}
      >
        <Tables numberOfTables={Object.entries(eqrcCacheData.detailsTablesRows).length}>
          {Object.entries(eqrcCacheData.detailsTablesRows).map(([table, data]) => (
            <SingleOrderTable table={table as BREACHES} key={`singleOrderTable_${table}`} />
          ))}
        </Tables>
      </WorkXBottomDrawer>
    </RecapProvider>
  );
};
