import { Tag } from "@nef/core";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { POR, PORModel, PORStatus, PvReject, PvRejectModel } from "./constant";
import { RejectCol } from "./rejectColumn";
import moment from "moment-timezone";
import { ReportingParty } from "wksConstants";
import { REPORTING_PARTY_OPTIONS, TRF_LABELS } from "components/fields";
import { getSideLabel } from "utils/js.utils";

export const PVR_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const JAN_1_1970 = "01/01/1970";
const MICRO_TO_SECONDS = 1000000;

const getReportingPartyLabel = (reportingParty: string) => {
  switch (reportingParty) {
    case ReportingParty.Executing:
    case ReportingParty.Contra:
      return REPORTING_PARTY_OPTIONS[reportingParty].label;
    default:
      return `Invalid: ${reportingParty}`;
  }
};

export const pvRejectColumns = [
  {
    Header: "ID",
    id: PvRejectModel.ID,
    accessor: PvRejectModel.ID,
  },
  {
    Header: "Reject Reason",
    id: PvRejectModel.REJECT_TEXT,
    accessor: PvRejectModel.REJECT_TEXT,
    Cell: ({ row }: { row: { original: PvReject } }) => {
      return (
        <TooltipTableCell
          id={`error_message_${row.original[PvRejectModel.ID]}`}
          tooltipContent={undefined}
          align={undefined}
        >
          {row.original[PvRejectModel.REJECT_TEXT]}
        </TooltipTableCell>
      );
    },
    width: 200,
  },
  {
    Header: "Entry MPID",
    id: PvRejectModel.ENTRY_MPID,
    accessor: PvRejectModel.ENTRY_MPID,
  },
  {
    Header: "Exec MPID",
    id: PvRejectModel.EXEC_MPID,
    accessor: PvRejectModel.EXEC_MPID,
    width: 90,
  },
  {
    Header: "Contra MPID",
    id: PvRejectModel.CONTRA_MPID,
    accessor: PvRejectModel.CONTRA_MPID,
    width: 110,
  },
  {
    Header: "Reporting Party",
    id: PvRejectModel.REPORTING_PARTY,
    accessor: (row: PvReject) => getReportingPartyLabel(row[PvRejectModel.REPORTING_PARTY]),
    width: 130,
  },
  {
    Header: "TRF",
    id: PvRejectModel.TRF,
    accessor: (row: PvReject) => TRF_LABELS[row[PvRejectModel.TRF]],
    width: 85,
  },
  {
    Header: "Symbol",
    id: PvRejectModel.SYMBOL,
    accessor: PvRejectModel.SYMBOL,
    width: 90,
  },
  {
    Header: "Side",
    id: PvRejectModel.SIDE,
    accessor: (row: PvReject) => getSideLabel(row[PvRejectModel.SIDE]),
    width: 100,
  },
  {
    Header: "Quantity",
    id: PvRejectModel.QUANTITY,
    accessor: PvRejectModel.QUANTITY,
    width: 90,
  },
  {
    Header: "Price",
    id: PvRejectModel.PRICE,
    accessor: PvRejectModel.PRICE,
    width: 90,
  },
  {
    Header: "Last Updated",
    id: PvRejectModel.LAST_UPDATE_DATE,
    accessor: (row: PvReject) => {
      const d = new Date(JAN_1_1970);
      d.setDate(d.getDate() + row[PvRejectModel.LAST_UPDATE_DATE]);
      const hours = row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS / 3600;
      const minutes =
        row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS / 60 - Math.floor(hours) * 60;
      const seconds =
        row[PvRejectModel.LAST_UPDATE_TIME] / MICRO_TO_SECONDS -
        Math.floor(hours) * 3600 -
        Math.floor(minutes) * 60;
      d.setHours(Math.floor(hours));
      d.setMinutes(Math.floor(minutes));
      d.setSeconds(Math.floor(seconds));
      return moment(d).format(PVR_DATE_TIME_FORMAT);
    },
    width: 130,
  },
];

export const pvRequestColumns = [
  {
    Header: "Status",
    id: PORModel.REQUEST_STATUS,
    accessor: PORModel.REQUEST_STATUS,
    width: 115,
    Cell: ({ row }: { row: { original: PvReject } }) => {
      let color = "success";
      if (row.original[PORModel.REQUEST_STATUS] === PORStatus.DENIED) {
        color = "danger";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.PENDING) {
        color = "primary";
      } else if (row.original[PORModel.REQUEST_STATUS] === PORStatus.EXPIRED) {
        color = "warning";
      }
      return <Tag color={color}>{row.original[PORModel.REQUEST_STATUS]}</Tag>;
    },
  },
  {
    Header: "Symbol",
    id: PORModel.SYMBOL,
    accessor: PORModel.SYMBOL,
    width: 75,
  },
  { Header: "MPID", id: PORModel.MPID, accessor: PORModel.MPID, width: 75 },
  {
    Header: "Rejects",
    id: PORModel.REJECTS,
    accessor: PORModel.REJECTS,
    Cell: ({ row }: { row: { original: POR } }) => (
      <RejectCol numRejects={row.original.rejects} symbol={row.original.symbol} />
    ),
    width: 75,
  },
  {
    Header: "Requester",
    id: PORModel.REQUESTER_EMAIL,
    accessor: PORModel.REQUESTER_EMAIL,
    width: 150,
  },
  { Header: "Request ID", id: PORModel.ID, accessor: PORModel.ID, width: 80 },
  {
    Header: "Last Update",
    id: PORModel.LAST_UDPATE,
    accessor: (row: POR) => {
      if (row[PORModel.LAST_UDPATE]) {
        return moment(row[PORModel.LAST_UDPATE]).format(PVR_DATE_TIME_FORMAT);
      }
      return "N/A";
    },
    width: 160,
  },
];
