import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@nef/core";
import { StandardTable } from "components/standardTable";
import { useRef } from "react";

export const ResultsTable = ({ rows, isOpen, onClose, header, activeTable }) => {
  const containerRef = useRef();

  return (
    <Modal isOpen={isOpen} closeOnOutsideClick={false} toggle={onClose} size="flex">
      <ModalHeader toggle={onClose} title={header} />

      <ModalBody ref={containerRef}>
        <StandardTable
          table={`${activeTable}Results`}
          enableLayoutExport={false}
          id={"eqrc-tables"}
          isSingleSelect={true}
          isColumnsVirtualized={false}
          isFilterable={true}
          hideRowCount={true}
          hideSelectedCount={true}
          style={{ height: "calc(90vh - 170px)" }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
