import * as columns from "./columns";
import { EQRC_RULE_STATUS, EqrcFields } from "../constants";
import { EqrcTableCell } from "../components/tableCell";
import {
  auditColPrefix,
  formatEqrcNumber,
  getActiveOrInactiveFromBoolean,
  getEqrcAccessorForField,
  sortEqrcCell,
} from "./utils";
import { TooltipTableCell } from "components/standardTable/tooltipCell";
import { execOrReturn } from "utils/js.utils";

const resultsColumns = [columns.resultColumn];

export const eqrcFatFingerColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "Range 1 Amount",
    id: EqrcFields.rangeOneAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeOneAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeOneAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeOneAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeOneAmt),
  },
  {
    Header: "Range 1 %",
    id: EqrcFields.rangeOnePct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeOnePct]
        : getEqrcAccessorForField(row, EqrcFields.rangeOnePct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeOnePct} />,
    sortType: sortEqrcCell(EqrcFields.rangeOnePct),
  },
  {
    Header: "Range 2 Amount",
    id: EqrcFields.rangeTwoAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeTwoAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeTwoAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeTwoAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeTwoAmt),
  },
  {
    Header: "Range 2 %",
    id: EqrcFields.rangeTwoPct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeTwoPct]
        : getEqrcAccessorForField(row, EqrcFields.rangeTwoPct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeTwoPct} />,
    sortType: sortEqrcCell(EqrcFields.rangeTwoPct),
  },
  {
    Header: "Range 3 Amount",
    id: EqrcFields.rangeThreeAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeThreeAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeThreeAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeThreeAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeThreeAmt),
  },
  {
    Header: "Range 3 %",
    id: EqrcFields.rangeThreePct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeThreePct]
        : getEqrcAccessorForField(row, EqrcFields.rangeThreePct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeThreePct} />,
    sortType: sortEqrcCell(EqrcFields.rangeThreePct),
  },
  {
    Header: "Range 4 Amount",
    id: EqrcFields.rangeFourAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeFourAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeFourAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeFourAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeFourAmt),
  },
  {
    Header: "Range 4 %",
    id: EqrcFields.rangeFourPct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeFourPct]
        : getEqrcAccessorForField(row, EqrcFields.rangeFourPct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeFourPct} />,
    sortType: sortEqrcCell(EqrcFields.rangeFourPct),
  },
  {
    Header: "Range 5 Amount",
    id: EqrcFields.rangeFiveAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeFiveAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeFiveAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeFiveAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeFiveAmt),
  },
  {
    Header: "Range 5 %",
    id: EqrcFields.rangeFivePct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeFivePct]
        : getEqrcAccessorForField(row, EqrcFields.rangeFivePct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeFivePct} />,
    sortType: sortEqrcCell(EqrcFields.rangeFivePct),
  },
  {
    Header: "Range 6 Amount",
    id: EqrcFields.rangeSixAmt,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeSixAmt]
        : getEqrcAccessorForField(row, EqrcFields.rangeSixAmt),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.rangeSixAmt}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.rangeSixAmt),
  },
  {
    Header: "Range 6 %",
    id: EqrcFields.rangeSixPct,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.rangeSixPct]
        : getEqrcAccessorForField(row, EqrcFields.rangeSixPct),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.rangeSixPct} />,
    sortType: sortEqrcCell(EqrcFields.rangeSixPct),
  },
];

export const eqrcAdvColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "Percent ADV",
    id: EqrcFields.pctAdv,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.pctAdv]
        : getEqrcAccessorForField(row, EqrcFields.pctAdv),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.pctAdv} />,
    sortType: sortEqrcCell(EqrcFields.pctAdv),
  },
  {
    Header: "Minimum Volume",
    id: EqrcFields.minVolume,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.minVolume]
        : getEqrcAccessorForField(row, EqrcFields.minVolume),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.minVolume}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.minVolume),
  },
];

export const eqrcRestrictedStockColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.SymbolsHeader(isAudit),
];

export const eqrcShortSaleColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.SymbolsHeader(isAudit),
];

export const eqrcMaxNotionalOrderColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.PerOrderNotionalLimitHeader(isAudit),
];

export const eqrcMaxSharesPerOrderColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.PerOrderSharesLimitHeader(isAudit),
];

export const eqrcSharesLocatedCheckColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.sharesLocatedOptionHeader(isAudit),
];

export const eqrcBrokerListColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.brokerList(isAudit),
];

export const eqrcSharesLocatedBrokerListColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  columns.sharesLocatedOptionHeader(isAudit),
  columns.brokerList(isAudit),
];

export const eqrcAlertConfigColumns = [
  {
    Header: "Status",
    id: EqrcFields.active,
    accessor: row => (row[EQRC_RULE_STATUS.Active]?.[EqrcFields.active] ? "Enabled" : "Disabled"),
    width: 80,
  },
  {
    Header: "MPID",
    id: EqrcFields.mpid,
    accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.mpid],
    width: 60,
  },
  {
    Header: "Port",
    id: EqrcFields.port,
    accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.port],
  },
  {
    Header: "Exchange",
    id: EqrcFields.exchange,
    accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.exchange],
    width: 100,
  },
  {
    Header: "Group ID",
    id: EqrcFields.groupId,
    accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.groupId],
  },
  columns.RuleTypeHeader,
  {
    Header: "Email Addresses",
    id: EqrcFields.emailAddresses,
    accessor: row => row[EQRC_RULE_STATUS.Active]?.[EqrcFields.emailAddresses]?.join(", "),
    Cell: ({ row }) => {
      return (
        <TooltipTableCell id={`tooltip_message_${row.original[EqrcFields.eqrcId]}`}>
          {`(${row.original[EQRC_RULE_STATUS.Active]?.[EqrcFields.emailAddresses]?.length}) 
        ${row.original[EQRC_RULE_STATUS.Active]?.[EqrcFields.emailAddresses]?.join(", ")}`}
        </TooltipTableCell>
      );
    },
    sortType: sortEqrcCell(EqrcFields.emailAddresses),
  },
];

export const eqrcMarketImpactColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "Market Impact Check",
    id: EqrcFields.marketImpact,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.marketImpact])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.marketImpact)),
    Cell: ({ row }) => {
      return (
        <EqrcTableCell
          row={row.original}
          property={EqrcFields.marketImpact}
          formatFn={getActiveOrInactiveFromBoolean}
        />
      );
    },
    sortType: sortEqrcCell(EqrcFields.marketImpact),
  },
];

export const eqrcOrderTypeColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "ISO Orders",
    id: EqrcFields.isoOrders,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.isoOrders])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.isoOrders)),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.isoOrders}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.isoOrders),
  },
  {
    Header: "Short Sale Orders",
    id: EqrcFields.shortSaleOrders,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.shortSaleOrders])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.shortSaleOrders)),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.shortSaleOrders}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.shortSaleOrders),
  },
  {
    Header: "Non Auction Market Orders",
    id: EqrcFields.nonMarketOrders,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.nonMarketOrders])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.nonMarketOrders)),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.nonMarketOrders}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.nonMarketOrders),
    width: 120,
  },
  {
    Header: "Pre Market Trading",
    id: EqrcFields.premarketTrading,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.premarketTrading])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.premarketTrading)),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.premarketTrading}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.premarketTrading),
  },
  {
    Header: "Post Market Trading",
    id: EqrcFields.postMarketTrading,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.postMarketTrading])
        : getActiveOrInactiveFromBoolean(
            getEqrcAccessorForField(row, EqrcFields.postMarketTrading)
          ),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.postMarketTrading}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.postMarketTrading),
  },
  {
    Header: "Short Sale Exempt Orders",
    id: EqrcFields.shortSaleExempt,
    accessor: row =>
      isAudit
        ? getActiveOrInactiveFromBoolean(row[auditColPrefix]?.[EqrcFields.shortSaleExempt])
        : getActiveOrInactiveFromBoolean(getEqrcAccessorForField(row, EqrcFields.shortSaleExempt)),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.shortSaleExempt}
        formatFn={getActiveOrInactiveFromBoolean}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.shortSaleExempt),
  },
];

export const eqrcOrderRateColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "Max Duplicate Messages",
    id: EqrcFields.duplicateMaxMessages,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.duplicateMaxMessages]
        : getEqrcAccessorForField(row, EqrcFields.duplicateMaxMessages),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.duplicateMaxMessages}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.duplicateMaxMessages),
  },
  {
    Header: "Duplicate Time Window Size",
    id: EqrcFields.duplicateTimeWindowSize,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.duplicateTimeWindowSize]
        : getEqrcAccessorForField(row, EqrcFields.duplicateTimeWindowSize),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.duplicateTimeWindowSize}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.duplicateTimeWindowSize),
  },
  {
    Header: "Max Port Messages",
    id: EqrcFields.portMaxMessages,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.portMaxMessages]
        : getEqrcAccessorForField(row, EqrcFields.portMaxMessages),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.portMaxMessages}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.portMaxMessages),
  },
  {
    Header: "Port Time Window Size",
    id: EqrcFields.portTimeWindowSize,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.portTimeWindowSize]
        : getEqrcAccessorForField(row, EqrcFields.portTimeWindowSize),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.portTimeWindowSize}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.portTimeWindowSize),
  },
  {
    Header: "Max Symbol Messages",
    id: EqrcFields.symbolMaxMessages,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.symbolMaxMessages]
        : getEqrcAccessorForField(row, EqrcFields.symbolMaxMessages),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.symbolMaxMessages}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell(EqrcFields.symbolMaxMessages),
  },
  {
    Header: "Symbol Time Window Size",
    id: EqrcFields.symbolTimeWindowSize,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.symbolTimeWindowSize]
        : getEqrcAccessorForField(row, EqrcFields.symbolTimeWindowSize),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={EqrcFields.symbolTimeWindowSize}
        formatFn={formatEqrcNumber}
      />
    ),
  },
];

export const eqrcGrossExposureColumns = isAudit => [
  columns.MPIDHeader(isAudit),
  columns.PortHeader(isAudit),
  columns.GroupIDHeader(isAudit),
  columns.ExchangeHeader(isAudit),
  {
    Header: "Open Exposure Watch",
    id: EqrcFields.openExposure + EqrcFields.exposureWatch,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.openExposure]?.[EqrcFields.exposureWatch]
        : getEqrcAccessorForField(row, [EqrcFields.openExposure, EqrcFields.exposureWatch]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.openExposure, EqrcFields.exposureWatch]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.openExposure, EqrcFields.exposureWatch]),
  },
  {
    Header: "Open Exposure Warn",
    id: EqrcFields.openExposure + EqrcFields.exposureWarn,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.openExposure]?.[EqrcFields.exposureWarn]
        : getEqrcAccessorForField(row, [EqrcFields.openExposure, EqrcFields.exposureWarn]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.openExposure, EqrcFields.exposureWarn]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.openExposure, EqrcFields.exposureWarn]),
  },
  {
    Header: "Open Exposure Action",
    id: EqrcFields.openExposure + EqrcFields.exposureAction,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.openExposure]?.[EqrcFields.exposureAction]
        : getEqrcAccessorForField(row, [EqrcFields.openExposure, EqrcFields.exposureAction]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.openExposure, EqrcFields.exposureAction]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.openExposure, EqrcFields.exposureAction]),
  },
  {
    Header: "Executed Exposure Watch",
    id: EqrcFields.executedExposure + EqrcFields.exposureWatch,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.executedExposure]?.[EqrcFields.exposureWatch]
        : getEqrcAccessorForField(row, [EqrcFields.executedExposure, EqrcFields.exposureWatch]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.executedExposure, EqrcFields.exposureWatch]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.executedExposure, EqrcFields.exposureWatch]),
  },
  {
    Header: "Executed Exposure Warn",
    id: EqrcFields.executedExposure + EqrcFields.exposureWarn,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.executedExposure]?.[EqrcFields.exposureWarn]
        : getEqrcAccessorForField(row, [EqrcFields.executedExposure, EqrcFields.exposureWarn]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.executedExposure, EqrcFields.exposureWarn]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.executedExposure, EqrcFields.exposureWarn]),
  },
  {
    Header: "Executed Exposure Action",
    id: EqrcFields.executedExposure + EqrcFields.exposureAction,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.executedExposure]?.[EqrcFields.exposureAction]
        : getEqrcAccessorForField(row, [EqrcFields.executedExposure, EqrcFields.exposureAction]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.executedExposure, EqrcFields.exposureAction]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.executedExposure, EqrcFields.exposureAction]),
  },
  {
    Header: "Notional Exposure Watch",
    id: EqrcFields.notionalExposure + EqrcFields.exposureWatch,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.notionalExposure]?.[EqrcFields.exposureWatch]
        : getEqrcAccessorForField(row, [EqrcFields.notionalExposure, EqrcFields.exposureWatch]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.notionalExposure, EqrcFields.exposureWatch]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.notionalExposure, EqrcFields.exposureWatch]),
  },
  {
    Header: "Notional Exposure Warn",
    id: EqrcFields.notionalExposure + EqrcFields.exposureWarn,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.notionalExposure]?.[EqrcFields.exposureWarn]
        : getEqrcAccessorForField(row, [EqrcFields.notionalExposure, EqrcFields.exposureWarn]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.notionalExposure, EqrcFields.exposureWarn]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.notionalExposure, EqrcFields.exposureWarn]),
  },
  {
    Header: "Notional Exposure Action",
    id: EqrcFields.notionalExposure + EqrcFields.exposureAction,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.notionalExposure]?.[EqrcFields.exposureAction]
        : getEqrcAccessorForField(row, [EqrcFields.notionalExposure, EqrcFields.exposureAction]),
    Cell: ({ row }) => (
      <EqrcTableCell
        row={row.original}
        property={[EqrcFields.notionalExposure, EqrcFields.exposureAction]}
        formatFn={formatEqrcNumber}
      />
    ),
    sortType: sortEqrcCell([EqrcFields.notionalExposure, EqrcFields.exposureAction]),
  },
  {
    Header: "Breach Action",
    id: EqrcFields.breachAction,
    accessor: row =>
      isAudit
        ? row[auditColPrefix]?.[EqrcFields.breachAction]
        : getEqrcAccessorForField(row, EqrcFields.breachAction),
    Cell: ({ row }) => <EqrcTableCell row={row.original} property={EqrcFields.breachAction} />,
    sortType: sortEqrcCell(EqrcFields.breachAction),
  },
];

export const eqrcAuditColumns = [
  {
    Header: "Revision Date",
    id: EqrcFields.revision,
    accessor: EqrcFields.revision,
  },
  {
    Header: "Revision Type",
    id: EqrcFields.revType,
    accessor: EqrcFields.revType,
  },
  {
    Header: "User",
    id: EqrcFields.userName,
    accessor: EqrcFields.userName,
  },
];

export const eqrcAlertHistoryColumns = [
  {
    Header: "Date",
    id: EqrcFields.refDate,
    accessor: EqrcFields.refDate,
  },
  {
    Header: "Alert Type",
    id: EqrcFields.alertType,
    accessor: EqrcFields.alertType,
  },
  columns.ExchangeHeader(false),
  columns.MPIDHeader(false),
  columns.PortHeader(false),
  columns.GroupIDHeader(false),
  {
    Header: "Alert Level",
    id: EqrcFields.alertLevel,
    accessor: EqrcFields.alertLevel,
  },
  {
    Header: "Timestamp",
    id: EqrcFields.timestamp,
    accessor: EqrcFields.timestamp,
  },
];

export const eqrcResultsColumns = {};
Object.entries({
  eqrcAdvColumns,
  eqrcAuditColumns,
  eqrcFatFingerColumns,
  eqrcGrossExposureColumns,
  eqrcMarketImpactColumns,
  eqrcOrderRateColumns,
  eqrcOrderTypeColumns,
  eqrcRestrictedStockColumns,
  eqrcShortSaleColumns,
  eqrcMaxNotionalOrderColumns,
  eqrcSharesLocatedCheckColumns,
  eqrcMaxSharesPerOrderColumns,
  eqrcBrokerListColumns,
  eqrcSharesLocatedBrokerListColumns,
}).forEach(([name, fn]) => {
  eqrcResultsColumns[name] = [...resultsColumns, columns.errorCountColumn, ...execOrReturn(fn)];
});
