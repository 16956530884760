import { StackedBar } from "./cache.types";
import {
  BREACHES,
  BREACHES_LABELS,
  EXPOSURE_BREACHES,
  EXPOSURE_BREACHES_LABELS_SHORT,
  EXPOSURE_LEVELS,
  SingleOrderCacheData,
} from "./eqrc.types";

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const ticks = (data: number[]) => {
  let largestValue = 0;
  data.forEach(i => {
    if (i > largestValue) {
      largestValue = i;
    }
  });

  const third = 10 ** Math.floor(largestValue.toString().length * 0.3);
  const twoThird = 10 ** Math.floor(largestValue.toString().length * 0.5);

  const ticks = [1, third];
  if (third !== twoThird) {
    ticks.push(twoThird);
  }

  ticks.push(largestValue);

  return ticks;
};

export const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
  input.status === "rejected";

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === "fulfilled";

export const joinToKeyString = (keyString: {
  mpid: string;
  groupId: number;
  port: string;
  exchange: string;
}) =>
  `             ${keyString.mpid}-
                ${keyString.groupId}-
                ${keyString.port}
              `.replace(/\s+/g, "");

export const exposure = (d?: { [key: string]: { [key: string]: number } }) => {
  const returnData: StackedBar[] = [];

  for (const type of enumKeys(EXPOSURE_BREACHES)) {
    const group: StackedBar = { name: EXPOSURE_BREACHES_LABELS_SHORT[type], values: [] };
    returnData.push(group);

    for (const level of enumKeys(EXPOSURE_LEVELS)) {
      group.values.push({
        name: level,
        value: d?.[EXPOSURE_BREACHES[type]]?.[EXPOSURE_LEVELS[level]] || 0,
      });
    }
  }

  return returnData;
};
