export const KeycloakRoles = {
  COMPLIANCE_ALL: "COMPLIANCE_ALL",
  DEVELOPER: "DEVELOPER",
  MONITORING: "MONITORING",
  REALTIME_STATISTICS: "REALTIME_STATISTICS",
  TRADE_REPORTING: "TRADE_REPORTING",
  TRADE_REPORTING_READONLY: "TRADE_REPORTING_READONLY",
  TRADE_REPORTING_CLEARING_ONLY: "TRADE_REPORTING_CLEARING_ONLY",
  TRADING: "TRADING",
  WORKXUSER: "WORKXUSER",
  SUPERVISOR: "SUPERVISOR",
  offline_access: "offline_access",
  uma_authorization: "uma_authorization",

  ORDER_ENTRY_TEST_TOOL: "ORDER_ENTRY_TEST_TOOL",

  CLEARER: "CLEARER",
  CORRESPONDENT: "CORRESPONDENT",
  CLEARING_CORRESPONDENT: "CLEARING_CORRESPONDENT",
  CLEARING_LIMO: "CLEARING_LIMO",
  CLEARING_LIMO_READONLY: "CLEARING_LIMO_READONLY",
  // Post - Trade Risk
  PTR_MONITOR: "PTR_MONITOR",
  PTR_MONITOR_RO: "PTR_MONITOR_RO",
  PTR_RISK_CONFIG: "PTR_RISK_CONFIG",
  PTR_RISK_CONFIG_RO: "PTR_RISK_CONFIG_RO",
  PTR_ALERTS: "PTR_ALERTS",
  PTR_ALERTS_AGU: "PTR_ALERTS_AGU",
  PTR_CLEARER: "PTR_CLEARER",
  PTR_BROKER: "PTR_BROKER",

  // EQRC
  EQRC_RULES_READ: "EQRC-RULES-API-RULES-READ",
  EQRC_RULES_WRITE: "EQRC-RULES-API-RULES-WRITE",
  EQRC_SAFETY_SWITCH: "EQRC-RULES-API-SAFETY-SWITCH",

  // PV Rejects
  PVR_CLIENT_MONITOR: "PVR_CLIENT_MONITOR",
  PVR_LITE_MONITOR: "PVR_LITE_MONITOR",
  PVR_FINRA_MONITOR: "PVR_FINRA_MONITOR",
  PVR_POR_ACTION: "PVR_POR_ACTION",
  PVR_FINRA_ACTIONS: "PVR_FINRA_ACTIONS",
  PVR_ALERTS: "PVR_ALERTS",
  PVR_ALERTS_FINRA: "PVR_ALERTS_FINRA",
  PVR_RESUBMIT_ACTION: "PVR_RESUBMIT_ACTION",
  FINRA: "FINRA",
};
