import { StandardHeader, StandardTable, useStandardTableContext } from "components/standardTable";
import { StandardTables } from "wksConstants";
import {
  BREACHES,
  BREACHES_LABELS,
  BREACHES_TO_UNBREACHES,
  SINGLE_ORDER_PREFIX,
  UNBREACHABLE_ALERT_TYPES,
} from "./eqrc.types";
import { useCallback, useEffect, useMemo } from "react";
import { CircleButton, Flex, FontAwesomeIcon, NotificationHub } from "@nef/core";
import { useEqrcCacheContext } from "./dataCacheContext";
import TableButtons from "components/standardTable/tableButtons";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { getHeaders } from "keycloak";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";

const style = { width: "1000px" };
export const SingleOrderTable = ({ table }: { table: BREACHES }) => {
  const { state: eqrcCacheData, dispatch: eqrcDispatch } = useEqrcCacheContext();
  const [tableData, tableDispatch] = useStandardTableContext();
  const [user] = useUserContext();

  const onRemoveTable = useCallback(() => {
    eqrcDispatch({ type: "REMOVE_TABLE", payload: { table } });
  }, [eqrcDispatch, table]);

  const Header2 = useCallback(() => {
    const title2 = `${BREACHES_LABELS[table]} Breaches`;
    const Header = (
      <Flex alignItems="center">
        {title2}{" "}
        <CircleButton onClick={onRemoveTable} size="sm" ghost={true}>
          <FontAwesomeIcon iconSetClassName="fas" iconClassName="fa-times-circle" />
        </CircleButton>
      </Flex>
    );
    return <StandardHeader title={Header} />;
  }, [onRemoveTable, table]);

  const sortFn = useCallback((a: any, b: any) => {
    if (a.timeStamp > b.timeStamp) {
      return 1;
    } else if (a.timestamp < b.timestamp) {
      return -1;
    } else {
      return 0;
    }
  }, []);

  // all rows sorted by dateTime
  const effectedTableData = useMemo(
    () => [...eqrcCacheData.detailsTablesRows[table]].sort(sortFn).reverse(),
    [eqrcCacheData.detailsTablesRows, sortFn, table]
  );

  // transform flat rows into rows with subrows
  const effectedTableData2 = useMemo(() => {
    const tableDataWithSubRows: { [key: string]: { children: any[] } } = {};
    const disabledRows: { [rowsIndex: string]: boolean } = {};
    effectedTableData.reduce<any[]>((acc, curr) => {
      const { exchange, port, mpid, alertType } = curr;

      const key = `${exchange}-${mpid}-${port}-${alertType}`;

      if (!tableDataWithSubRows[key]) {
        tableDataWithSubRows[key] = { ...curr, children: [] };
      }

      tableDataWithSubRows[key].children.push(curr);

      return acc;
    }, []);
    const newData = Object.entries(tableDataWithSubRows).map(([k, row], i) => {
      // mark every sub rows as disabled
      row.children.forEach((child, ii) => {
        disabledRows[`${i}.${ii}`] = true;
      });

      // slice the parent (largest date time per exchange/mpid/port combo) out of the subrows
      return {
        ...row,
        subRows: row.children.sort(sortFn).reverse().slice(1),
      };
    });
    return { data: newData, disabledRows };
  }, [effectedTableData, sortFn]);

  useEffect(() => {
    if (effectedTableData2.data) {
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: {
            table: `${SINGLE_ORDER_PREFIX}${table}`,
            data: effectedTableData2.data,
          },
        },
        {
          type: "SET_DISABLED_ROWS",
          payload: {
            table: `${SINGLE_ORDER_PREFIX}${table}`,
            disabledRows: effectedTableData2.disabledRows,
          },
        },
      ]);
    }
  }, [effectedTableData, effectedTableData2, table, tableDispatch]);

  const onSuccess = useCallback((json: any, row: any, add: any[]) => {
    add.push(row);
  }, []);

  const onFail = useCallback((json: any, row: any, add: any[]) => {
    add.push(row);
  }, []);

  const handleUnbreach = useCallback(async () => {
    if (!UNBREACHABLE_ALERT_TYPES.includes(table)) {
      return;
    }
    const selected = tableData[`${SINGLE_ORDER_PREFIX}${table}`].selected;
    const xhrPromises: Promise<void>[] = [];
    const successes: any[] = [];
    const fails: any[] = [];

    Object.entries(selected).forEach(([k, v]) => {
      // don't process sub rows
      if (k.match(/\./)) {
        return;
      } else {
        const row = tableData[`${SINGLE_ORDER_PREFIX}${table}`].data[parseInt(k)];
        const { mpid, port, exchange } = row;
        const payload = {
          mpid,
          port,
          exchange,
          rateThresholdType: BREACHES_TO_UNBREACHES[table],
        };

        xhrPromises.push(
          (() => {
            return doFetchWrapper(
              formatUrl(
                user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL?.eqrcRulesUrl],
                `/orderRateThresholds/unbreach/`
              ),
              {
                method: "post",
                mode: "cors",
                headers: getHeaders(),
                body: JSON.stringify(payload),
              },
              (json: any) => {
                onSuccess(json, row, successes);
              },
              (json: any) => {
                onFail(json, row, fails);
              }
            );
          })()
        );
      }
    });

    const results = await Promise.allSettled(xhrPromises);

    if (successes.length === results.length) {
      NotificationHub.send("success", "Unbreach requests sent successfully");
    } else {
      const messages = fails.map(fail => (
        <li key={`${fail.exchange}-${fail.mpid}-${fail.port}`}>
          {`${fail.exchange} -
          ${fail.mpid} -
          ${fail.port}`}
        </li>
      ));
      NotificationHub.send(
        "danger",
        `Unbreach ${BREACHES_LABELS[table]} requests not sent successfully`,
        {
          subtitle: <ul>{messages}</ul>,
        }
      );
    }
  }, [onFail, onSuccess, table, tableData, user]);

  const tableButtons = useMemo(() => {
    const buttons = [];
    if (UNBREACHABLE_ALERT_TYPES.includes(table)) {
      buttons.push({
        icon: "lock-open",
        text: "Unbreach",
        actionId: "unbreach",
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleUnbreach,
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        disable: false,
      });
    }
    return (
      <TableButtons
        table={(StandardTables as any)[`${SINGLE_ORDER_PREFIX}${table}`]}
        buttons={buttons}
      />
    );
  }, [handleUnbreach, table]);

  const allowSelect = useMemo(() => UNBREACHABLE_ALERT_TYPES.includes(table), [table]);

  return (
    <StandardTable
      subHeader={undefined}
      table={(StandardTables as any)[`${SINGLE_ORDER_PREFIX}${table}`]}
      hideRowCount={false}
      hideSelectedCount={false}
      hideQueryDate={undefined}
      headerMenu={tableButtons}
      enableLayoutExport={true}
      exportCallback={undefined}
      exportFileName={undefined}
      additionalRowClick={undefined}
      isEditableTableEnabled={undefined}
      isFilterable={undefined}
      style={style}
      isRowSelectEnabled={allowSelect}
      header={Header2}
      Header={undefined}
      isExpandingRowsEnabled={true}
    />
  );
};
