import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NotificationHub } from "@nef/core";
import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Forms, eqrcActionsFields } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import { getFormValidation } from "components/standardTable";
import { doFetch, doFetchWrapper } from "network";
import { getHeaders } from "keycloak";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { EqrcExchangeMpidPort } from "components/settings/eqrc/exchangeMpidPort/exchangeMpidPort";
import { useFormContext } from "components/form";
import { execOrReturn, formatUrl } from "utils/js.utils";
import { EqrcFields } from "components/settings/eqrc/constants";
import { Port_Actions } from "components/fields/fieldConstantTypes";
import { isFulfilled } from "./utils";
import { NONE } from "./dataCacheContext.types";
import { UNBREACH_NAMES } from "./eqrc.types";

type ActionsModalProps = {
  isOpen: boolean;
  toggleModal: MouseEventHandler;
};

export const ActionsModal = ({ isOpen, toggleModal }: ActionsModalProps) => {
  const [formData, formDispatch] = useFormContext();
  const [user] = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    formDispatch({
      type: "INIT_FORM_VALIDATION",
      payload: { form: Forms.EQRC_ACTIONS },
    });
  }, [formDispatch]);

  const actionSuccess = useCallback((data: { failed: []; successful: [] }) => {
    const { successful, failed } = data;

    const successPorts = successful.map((s: { dto: { port: string } }) => s.dto.port);
    if (successPorts.length > 0) {
      NotificationHub.send("success", "Successful action taken on ports:", {
        subtitle: successPorts.join(" ,"),
      });
    }

    const failPorts = failed.map((s: { dto: { port: string } }) => s.dto.port);
    if (failPorts.length > 0) {
      NotificationHub.send("danger", "Unsuccessful action taken on ports:", {
        subtitle: failPorts.join(", "),
      });
    }

    setIsLoading(false);
  }, []);

  const actionFail = useCallback(() => {
    NotificationHub.send("danger", "Action failed to send");
    setIsLoading(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    const valid = getFormValidation(Forms.EQRC_ACTIONS, formData, formDispatch);

    if (!valid) {
      return;
    }

    const actionsForm = formData[Forms.EQRC_ACTIONS.key].fields;
    const actionType = actionsForm[EqrcFields.portAction]?.value;

    setIsLoading(true);

    if (actionType !== Port_Actions.UNBREACH) {
      const toSend = execOrReturn(actionsForm[EqrcFields.port]).map((p: { value: string }) => {
        return {
          mpid: actionsForm[EqrcFields.mpid].value,
          port: p.value,
          exchange: actionsForm[EqrcFields.exchange].value,
          actionType: actionsForm[EqrcFields.portAction]?.value || NONE,
          cancelActionType: actionsForm[EqrcFields.cancelAction]?.value || NONE,
        };
      });

      doFetchWrapper(
        formatUrl(
          user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL?.eqrcRulesUrl],
          `/safetySwitches/`
        ),
        {
          method: "post",
          mode: "cors",
          headers: getHeaders(),
          body: JSON.stringify(toSend),
        },
        actionSuccess,
        actionFail
      );
    }

    type Request_Promise = { json: any; response: Response };
    const xhrPromises: Promise<Request_Promise>[] = [];
    if (actionType === Port_Actions.UNBREACH) {
      execOrReturn(actionsForm[EqrcFields.port]).forEach((p: { value: string }) => {
        [
          UNBREACH_NAMES["MESSAGE_RATE"],
          UNBREACH_NAMES["DUPLICATE_RATE"],
          UNBREACH_NAMES["SYMBOL_RATE"],
        ].forEach((type: string) => {
          const payload = {
            mpid: actionsForm[EqrcFields.mpid].value,
            port: p.value,
            exchange: actionsForm[EqrcFields.exchange].value,
            rateThresholdType: type,
          };

          xhrPromises.push(
            doFetch(
              formatUrl(
                user?.[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL?.eqrcRulesUrl],
                `/orderRateThresholds/unbreach/`
              ),
              {
                method: "post",
                mode: "cors",
                headers: getHeaders(),
                body: JSON.stringify(payload),
              },
              () => {}
            )
          );
        });
      });

      const results = await Promise.allSettled(xhrPromises);
      const successes = [];
      const fails = [];
      results.forEach(result => {
        if (isFulfilled(result) && result.value.response.ok) {
          successes.push(result);
        } else {
          fails.push(result);
        }
      });

      setIsLoading(false);

      if (successes.length === results.length) {
        NotificationHub.send("success", "Unbreach requests sent successfully");
      } else {
        NotificationHub.send("danger", "Unbreach requests not sent successfully");
      }
    }
  }, [actionFail, actionSuccess, formData, formDispatch, user]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={"Safety Switch"} toggle={toggleModal} />
      <ModalBody>
        <EqrcExchangeMpidPort form={Forms.EQRC_ACTIONS} hideClearButton={true}>
          <FieldLoop
            form={Forms.EQRC_ACTIONS}
            fields={eqrcActionsFields}
            isReactFragment={true}
            classNames={undefined}
            augmentOnChange={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
          />
        </EqrcExchangeMpidPort>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleModal} outline={true}>
          Close
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? "Sending" : "Send Action"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
