import { NotificationHub } from "@nef/core";
import { ContextProviderProps, DispatchFn, DispatchFnSwitch } from "components/context/constants";
import ContextFactory from "components/context/factory";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, { useCallback, useEffect, useReducer } from "react";
import { formatUrl } from "utils/js.utils";
import { Status } from "wksConstants";
import moment from "moment-timezone";
import { useUserContext } from "components/user";
import { Views } from "viewConstants";

export type PorWindowCacheState = {
  data: any;
  loading: boolean;
  status: string;
  isWindowOpen: boolean;
  windowEventTimers: NodeJS.Timeout[];
};

export type PorWindowCacheAction = { type: any; payload?: any };

const FACTORY = new ContextFactory<PorWindowCacheState, PorWindowCacheAction>();

const DEFAULT_STATE = {
  data: [],
  loading: true,
  status: Status.NO_STATUS,
  isWindowOpen: false,
  windowEventTimers: [],
};

const [DISPATCH_CONTEXT, STATE_CONTEXT] = FACTORY.createContexts(DEFAULT_STATE);

const DISPATCH_FN_SWITCH: DispatchFnSwitch<PorWindowCacheState, PorWindowCacheAction> = (
  prevState: PorWindowCacheState,
  action: PorWindowCacheAction
) => {
  switch (action.type) {
    case "SET_WINDOWS": {
      const { windows, isWindowOpen } = action.payload;
      return {
        ...prevState,
        data: windows,
        isWindowOpen,
        loading: false,
        status: Status.SUCCESS,
      };
    }
    case "SET_IS_WINDOW_OPEN": {
      return {
        ...prevState,
        isWindowOpen: action.payload,
      };
    }
    case "SET_WINDOW_EVENT_TIMERS": {
      return {
        ...prevState,
        windowEventTimers: action.payload,
      };
    }
    case "SET_ERROR": {
      return {
        ...prevState,
        data: [],
        loading: false,
        status: Status.ERROR,
      };
    }
    case "REFRESH_WINDOWS": {
      return {
        ...prevState,
        status: Status.NO_STATUS,
      };
    }
    default:
      return { ...prevState };
  }
};

const DISPATCH_FN = FACTORY.createDispatchFn<PorWindowCacheState, PorWindowCacheAction>(
  DISPATCH_FN_SWITCH
);

interface PorWindowCacheProviderProps extends ContextProviderProps {}

export const PorWindowCacheProvider: React.FC<PorWindowCacheProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<DispatchFn<PorWindowCacheState, PorWindowCacheAction>>(
    DISPATCH_FN,
    DEFAULT_STATE
  );
  const [userData] = useUserContext();

  const getTodaysPorWindows = useCallback(
    (status?: any) => {
      if (status === undefined) {
        status = state.status;
      }
      const getWindowsSuccess = (data: any) => {
        if (status === Status.ERROR) {
          NotificationHub.send("success", "Recovered today's POR Time Windows");
        }

        state.windowEventTimers.forEach(clearTimeout);
        const newWindowEventTimers: NodeJS.Timeout[] = [];
        let isWindowOpen = false;
        data.windows.forEach((window: any) => {
          const currentTimeZone = moment.tz("America/New_York").format("Z");
          const now = new Date().getTime();
          const open = new Date(`${window.startTime}${currentTimeZone}`).getTime();
          const close = new Date(`${window.endTime}${currentTimeZone}`).getTime();
          if (close > now) {
            newWindowEventTimers.push(
              setTimeout(() => {
                dispatch({ type: "SET_IS_WINDOW_OPEN", payload: false });
              }, close - now)
            );
            if (open > now) {
              newWindowEventTimers.push(
                setTimeout(() => {
                  dispatch({ type: "SET_IS_WINDOW_OPEN", payload: true });
                }, open - now)
              );
            } else {
              isWindowOpen = true;
            }
          }
        });
        dispatch([
          { type: "SET_WINDOW_EVENT_TIMERS", payload: newWindowEventTimers },
          { type: "SET_WINDOWS", payload: { windows: data.windows, isWindowOpen } },
        ]);
      };

      const getWindowsError = () => {
        setTimeout(() => {
          getTodaysPorWindows(Status.ERROR);
        }, 3000);
        if (status !== Status.ERROR) {
          NotificationHub.send("danger", "Error getting POR Time Windows for today");
          dispatch({ type: "SET_ERROR" });
        }
      };

      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por-window/today"),
        {
          method: "get",
          mode: "cors",
          headers: getHeaders(),
        },
        getWindowsSuccess,
        getWindowsError
      );
    },
    [state.status, state.windowEventTimers]
  );

  useEffect(() => {
    if (
      (userData.allowed.views[Views.PVR_CLIENT_MONITOR] ||
        userData.allowed.views[Views.PVR_FINRA_MONITOR] ||
        userData.allowed.views[Views.PVR_LITE_MONITOR]) &&
      state.status === Status.NO_STATUS
    ) {
      getTodaysPorWindows();
    }
  }, [getTodaysPorWindows, state.status, userData.allowed.views]);

  return (
    <DISPATCH_CONTEXT.Provider value={dispatch}>
      <STATE_CONTEXT.Provider value={state}>{children}</STATE_CONTEXT.Provider>
    </DISPATCH_CONTEXT.Provider>
  );
};

export const usePorWindowCacheDispatch =
  FACTORY.createContextHook<React.Dispatch<PorWindowCacheAction | PorWindowCacheAction[]>>(
    DISPATCH_CONTEXT
  );
export const usePorWindowCacheState = FACTORY.createContextHook<PorWindowCacheState>(STATE_CONTEXT);
