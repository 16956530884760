import { useMemo } from "react";
import { StandardTables, eqrcRuleTypeMap } from "wksConstants";
import {
  eqrcGroupId,
  eqrcFatFingerFields,
  eqrcRestrictedStockFields,
  eqrcShortSaleFields,
  eqrcMarketImpactFields,
  eqrcOrderTypeFields,
  eqrcAverageDailyVolumeFields,
  eqrcOrderRateThresholdsFields,
  eqrcGrossExposureFields,
  eqrcAlertConfigurationFields,
  Forms,
} from "components/fields";
import {
  eqrcMaxNotionalOrder,
  eqrcSharesLocatedCheck,
  eqrcMaxSharesPerOrder,
  eqrcEditAlertConfigurationFields,
  eqrcBrokerList,
} from "components/fields/fieldSubsetsConstants";

export const useActiveVars = ({ activeTable, currentStep }) => {
  return useMemo(() => {
    let activeCreateFieldSets;
    let activeEditFieldSets;
    let activeNewForm;
    let activeEditForm;
    let totalSteps = 0;

    switch (activeTable) {
      case StandardTables.EQRC_FAT_FINGER:
        activeCreateFieldSets = [{ fields: eqrcFatFingerFields }];
        activeEditFieldSets = [{ fields: eqrcFatFingerFields }];
        activeNewForm = Forms.EQRC_FAT_FINGER;
        activeEditForm = Forms.EQRC_EDIT_FAT_FINGER;
        break;
      case StandardTables.EQRC_RESTRICTED_STOCK_LIST:
        activeCreateFieldSets = [{ fields: eqrcRestrictedStockFields }];
        activeEditFieldSets = [{ fields: eqrcRestrictedStockFields }];
        activeNewForm = Forms.EQRC_RESTRICTED_STOCK_LIST;
        activeEditForm = Forms.EQRC_EDIT_RESTRICTED_STOCK;
        break;
      case StandardTables.EQRC_SHORT_SALE:
        activeCreateFieldSets = [{ fields: eqrcShortSaleFields }];
        activeEditFieldSets = [{ fields: eqrcShortSaleFields }];
        activeNewForm = Forms.EQRC_SHORT_SALE;
        activeEditForm = Forms.EQRC_EDIT_SHORT_SALE;
        break;
      case StandardTables.EQRC_MARKET_IMPACT_CHECK:
        activeCreateFieldSets = [{ fields: eqrcMarketImpactFields }];
        activeEditFieldSets = [{ fields: eqrcMarketImpactFields }];
        activeNewForm = Forms.EQRC_MARKET_IMPACT_CHECK;
        activeEditForm = Forms.EQRC_EDIT_MARKET_IMPACT;
        break;
      case StandardTables.EQRC_ORDER_TYPE:
        activeCreateFieldSets = [{ fields: eqrcOrderTypeFields }];
        activeEditFieldSets = [{ fields: eqrcOrderTypeFields }];
        activeNewForm = Forms.EQRC_ORDER_TYPE;
        activeEditForm = Forms.EQRC_EDIT_ORDER_TYPE;
        break;
      case StandardTables.EQRC_ADV_CHECK:
        activeCreateFieldSets = [{ fields: eqrcAverageDailyVolumeFields }];
        activeEditFieldSets = [{ fields: eqrcAverageDailyVolumeFields }];
        activeNewForm = Forms.EQRC_ADV_CHECK;
        activeEditForm = Forms.EQRC_EDIT_AVG_DAILY_VOLUME;
        break;
      case StandardTables.EQRC_ORDER_RATE_THRESHOLDS:
        activeCreateFieldSets = [{ fields: eqrcOrderRateThresholdsFields }];
        activeEditFieldSets = [{ fields: eqrcOrderRateThresholdsFields }];
        activeNewForm = Forms.EQRC_ORDER_RATE;
        activeEditForm = Forms.EQRC_EDIT_ORDER_RATE;
        break;
      case StandardTables.EQRC_GROSS_EXPOSURE:
        activeCreateFieldSets = [{ fields: eqrcGroupId }, { fields: eqrcGrossExposureFields }];
        activeEditFieldSets = [{ fields: eqrcGrossExposureFields }];
        activeNewForm = Forms.EQRC_GROSS_EXPOSURE;
        activeEditForm = Forms.EQRC_EDIT_GROSS_EXPOSURE;
        break;
      case StandardTables.EQRC_ALERT_CONFIG:
        activeCreateFieldSets = [{ fields: eqrcGroupId }, { fields: eqrcAlertConfigurationFields }];
        activeEditFieldSets = [{ fields: eqrcEditAlertConfigurationFields }];
        activeNewForm = Forms.EQRC_ALERT_CONFIG;
        activeEditForm = Forms.EQRC_EDIT_ALERT;
        break;
      case StandardTables.EQRC_MAX_NOTIONAL_ORDER:
        activeCreateFieldSets = [{ fields: eqrcMaxNotionalOrder }];
        activeEditFieldSets = [{ fields: eqrcMaxNotionalOrder }];
        activeNewForm = Forms.EQRC_MAX_NOTIONAL_ORDER;
        activeEditForm = Forms.EQRC_EDIT_MAX_NOTIONAL_ORDER;
        break;
      case StandardTables.EQRC_MAX_SHARES_PER_ORDER:
        activeCreateFieldSets = [{ fields: eqrcMaxSharesPerOrder }];
        activeEditFieldSets = [{ fields: eqrcMaxSharesPerOrder }];
        activeNewForm = Forms.EQRC_MAX_SHARES_PER_ORDER;
        activeEditForm = Forms.EQRC_EDIT_MAX_SHARES_PER_ORDER;
        break;
      case StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST:
        activeCreateFieldSets = [
          [{ fields: eqrcSharesLocatedCheck }],
          [{ fields: eqrcBrokerList }],
        ][currentStep];
        activeEditFieldSets = [[{ fields: eqrcSharesLocatedCheck }], [{ fields: eqrcBrokerList }]][
          currentStep
        ];
        activeNewForm = Forms.EQRC_SHARES_LOCATED_CHECK;
        activeEditForm = Forms.EQRC_EDIT_SHARES_LOCATED_CHECK;
        totalSteps = 2;

        break;
      default:
        break;
    }
    return {
      activeCreateFieldSets,
      activeEditFieldSets,
      activeNewForm,
      activeEditForm,
      activeRuleType: eqrcRuleTypeMap[activeTable],
      totalSteps,
    };
  }, [activeTable, currentStep]);
};
