import { FieldBox } from "components/styled";
import { memo } from "react";

const WorkXText = memo(({ children, style }) => {
  return (
    <FieldBox marginTop={0} paddingBottom={3} marginRight={3} style={style}>
      {children}
    </FieldBox>
  );
});

export default WorkXText;
