import Decimal from "decimal.js";
import { EQRC_SHARES_LOCATED_VALUES, EqrcFields } from "../constants";
import { FIELDS_WITH_IMPLICIT_DECIMAL, IMPLICIT_DECIMAL_DIVISOR } from "../constants2";
import { originatingFormKey } from "components/form/constants";
import { keyPruner } from "./keyPruner";
import { EqrcRules } from "../types.consts";
import { StandardTables } from "wksConstants";

export const createDeleteBody = row => {
  let body = {};
  if (row?.[EqrcFields.mpid]) {
    body[EqrcFields.mpid] = row[EqrcFields.mpid];
  }
  if (row?.[EqrcFields.port]) {
    body[EqrcFields.port] = row[EqrcFields.port];
  }
  // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
  if (row.hasOwnProperty(EqrcFields.groupId)) {
    body[EqrcFields.groupId] = row[EqrcFields.groupId];
  }
  if (row?.[EqrcFields.exchange]) {
    body[EqrcFields.exchange] = row[EqrcFields.exchange];
  }
  if (row?.[EqrcFields.ruleType]) {
    body[EqrcFields.ruleType] = row[EqrcFields.ruleType];
  }
  return body;
};

export const createAlertUpdateBody = (row, toggleValue) => {
  let body = {};
  if (row?.[EqrcFields.mpid]) {
    body[EqrcFields.mpid] = row[EqrcFields.mpid];
  }
  if (row?.[EqrcFields.port]) {
    body[EqrcFields.port] = row[EqrcFields.port];
  }
  // Needs to be hasOwnProperty since the groupId is 0 default (0 returns false on row? check)
  if (row.hasOwnProperty(EqrcFields.groupId)) {
    body[EqrcFields.groupId] = row[EqrcFields.groupId];
  }
  if (row?.[EqrcFields.exchange]) {
    body[EqrcFields.exchange] = row[EqrcFields.exchange];
  }
  if (row?.[EqrcFields.ruleType]) {
    body[EqrcFields.ruleType] = row[EqrcFields.ruleType];
  }
  if (row?.[EqrcFields.emailAddresses]) {
    body[EqrcFields.emailAddresses] = row[EqrcFields.emailAddresses];
  }
  body[EqrcFields.active] = toggleValue;
  return body;
};

export const createHistoryBody = (pageNumber, fields) => {
  const bodyArgs = [
    EqrcFields.mpid,
    EqrcFields.exchange,
    EqrcFields.port,
    EqrcFields.groupId,
    EqrcFields.beginDate,
    EqrcFields.endDate,
  ];

  const body = bodyArgs.reduce(
    (acc, curr) => {
      switch (curr) {
        case EqrcFields.mpid:
        case EqrcFields.exchange:
        case EqrcFields.alertLevel:
        case EqrcFields.ruleType:
          acc[curr] = fields[curr]?.value;
          break;
        case EqrcFields.port:
          if (fields[curr]) {
            acc[curr] = fields[curr].value;
          }
          break;
        case EqrcFields.groupId:
          if (fields[curr]) {
            acc[curr] = fields[curr].value;
          }
          break;
        case EqrcFields.beginDate:
        case EqrcFields.endDate:
          acc[curr] = fields[curr]?.substr(0, 10);
          break;
        default:
          break;
      }
      return acc;
    },
    {
      [EqrcFields.pageNumber]: pageNumber,
      [EqrcFields.pageSize]: 1000,
    }
  );

  return body;
};

export const createAuditBody = (pageNumber, fields) => {
  const bodyArgs = [
    EqrcFields.mpid,
    EqrcFields.exchange,
    EqrcFields.port,
    EqrcFields.groupId,
    EqrcFields.beginDate,
    EqrcFields.endDate,
  ];

  const body = bodyArgs.reduce(
    (acc, curr) => {
      switch (curr) {
        case EqrcFields.mpid:
        case EqrcFields.exchange:
          acc[curr] = fields[curr].value;
          break;
        case EqrcFields.port:
          if (fields[curr]) {
            acc[curr] = fields[curr].value;
          } else {
            acc[curr] = "";
          }
          break;
        case EqrcFields.groupId:
          if (fields[curr]) {
            acc[curr] = fields[curr].value;
          } else {
            acc[curr] = 0;
          }
          break;
        case EqrcFields.beginDate:
        case EqrcFields.endDate:
          acc[curr] = fields[curr];
          break;
        default:
          break;
      }
      return acc;
    },
    { pageNumber, pageSize: 1000 }
  );

  return body;
};

const pickCorrectValue = ({ fields, prefix, suffix }) => {
  if (fields[`${prefix}${suffix}`]) {
    return new Decimal(fields[`${prefix}${suffix}`]).times(IMPLICIT_DECIMAL_DIVISOR).toNumber();
  } else {
    return new Decimal(fields[prefix][suffix]).times(IMPLICIT_DECIMAL_DIVISOR).toNumber();
  }
};
export const updateBodyForGrossExposure = (body, fields) => {
  const a = fields[`${EqrcFields.openExposure}${EqrcFields.exposureWatch}`];
  body[EqrcFields.openExposure] = {
    [EqrcFields.exposureWatch]: pickCorrectValue({
      fields,
      prefix: EqrcFields.openExposure,
      suffix: EqrcFields.exposureWatch,
    }),

    [EqrcFields.exposureWarn]: pickCorrectValue({
      fields,
      prefix: EqrcFields.openExposure,
      suffix: EqrcFields.exposureWarn,
    }),
    [EqrcFields.exposureAction]: pickCorrectValue({
      fields,
      prefix: EqrcFields.openExposure,
      suffix: EqrcFields.exposureAction,
    }),
  };
  body[EqrcFields.executedExposure] = {
    [EqrcFields.exposureWatch]: pickCorrectValue({
      fields,
      prefix: EqrcFields.executedExposure,
      suffix: EqrcFields.exposureWatch,
    }),
    [EqrcFields.exposureWarn]: pickCorrectValue({
      fields,
      prefix: EqrcFields.executedExposure,
      suffix: EqrcFields.exposureWarn,
    }),
    [EqrcFields.exposureAction]: pickCorrectValue({
      fields,
      prefix: EqrcFields.executedExposure,
      suffix: EqrcFields.exposureAction,
    }),
  };
  body[EqrcFields.notionalExposure] = {
    [EqrcFields.exposureWatch]: pickCorrectValue({
      fields,
      prefix: EqrcFields.notionalExposure,
      suffix: EqrcFields.exposureWatch,
    }),
    [EqrcFields.exposureWarn]: pickCorrectValue({
      fields,
      prefix: EqrcFields.notionalExposure,
      suffix: EqrcFields.exposureWarn,
    }),
    [EqrcFields.exposureAction]: pickCorrectValue({
      fields,
      prefix: EqrcFields.notionalExposure,
      suffix: EqrcFields.exposureAction,
    }),
  };
};

export const preparePostBody = (fields, rows, rule = EqrcRules.fatFinger) => {
  let bodies = [];
  let mpids = fields[EqrcFields.mpid];
  if (!Array.isArray(mpids)) {
    mpids = [mpids];
  }
  let ports = fields[EqrcFields.port];
  if (!Array.isArray(ports)) {
    ports = [ports];
  }

  // did we get exchangeMpidPorts from the form (new) or from table rows (edit)
  if (fields.exchangeMpidPorts) {
    fields.exchangeMpidPorts.forEach((row, i) => {
      if (
        [StandardTables.EQRC_RESTRICTED_STOCK_LIST, StandardTables.EQRC_SHORT_SALE].includes(
          rule
        ) &&
        [null, undefined, []].includes(fields[EqrcFields.symbols])
      ) {
        fields[EqrcFields.symbols] = [];
      }
      const body = {
        [EqrcFields.mpid]: row[EqrcFields.mpid],
        [EqrcFields.port]: row[EqrcFields.port],
        [EqrcFields.exchange]: row[EqrcFields.exchange],
        status: rows[i][rows[i].status],
        ...transformFieldsForPostBody(fields, rows[i][rows[i].status]),
        row,
      };

      bodies.push(body);
    });
  } else {
    mpids.forEach(mpid => {
      ports.forEach(port => {
        const body = {
          [EqrcFields.mpid]: mpid?.value,
          ...(port && { [EqrcFields.port]: port?.value }),
          ...transformFieldsForPostBody(fields),
        };

        bodies.push(body);
      });
    });
  }

  bodies.forEach((body, i) => (bodies[i] = keyPruner(rule, body)));

  return { inputDTOs: bodies };
};

// we can be edit or create here.  If we are edit, we probably don't have all the fields' values,
// so we need to assign the missing fields from the table row
const transformFieldsForPostBody = (fields, row = {}) => {
  let addGrossExposureFields = false;
  const body = {};

  fields = Object.assign({}, row, fields);
  Object.entries(fields).forEach(([key, fieldVal]) => {
    if (key === EqrcFields.ruleType) {
      body[key] = fieldVal?.value;
    } else if (key === EqrcFields.breachAction || key === EqrcFields.groupId) {
      body[key] = fieldVal?.value ? fieldVal?.value : fieldVal;
    } else if (key === EqrcFields.mpid || key === EqrcFields.port) {
      // noop
    } else if (key === EqrcFields.sharesLocatedOption || key === EqrcFields.sharesLocatedSSE) {
      const previousSharesLocatedOption =
        fields?.[EqrcFields.sharesLocateRule]?.[EqrcFields.sharesLocatedOption];
      let value = fields[EqrcFields.sharesLocatedOption]?.value || previousSharesLocatedOption;
      if (
        value === EQRC_SHARES_LOCATED_VALUES.ENABLED &&
        fields?.[EqrcFields.sharesLocatedSSE]?.value === "true"
      ) {
        value = EQRC_SHARES_LOCATED_VALUES.ENABLED_SSE;
      }
      body[EqrcFields.sharesLocateRule] = { [EqrcFields.sharesLocatedOption]: value };
      body[EqrcFields.sharesLocatedOption] = value;

      if (key === EqrcFields.sharesLocatedSSE) {
        body[EqrcFields.sharesLocatedSSE] = value;
      }
    } else if (key === EqrcFields.sharesLocateRule) {
      let value = fieldVal[EqrcFields.sharesLocatedOption];
      if (
        value === EQRC_SHARES_LOCATED_VALUES.ENABLED &&
        fields?.[EqrcFields.sharesLocatedSSE]?.value === "true"
      ) {
        value = EQRC_SHARES_LOCATED_VALUES.ENABLED_SSE;
      }
      fieldVal = { [EqrcFields.sharesLocatedOption]: value };
      body[key] = fieldVal;
      body[EqrcFields.sharesLocatedOption] = fieldVal[EqrcFields.sharesLocatedOption];
    } else if (
      key.includes(EqrcFields.openExposure) ||
      key.includes(EqrcFields.notionalExposure) ||
      key.includes(EqrcFields.executedExposure)
    ) {
      addGrossExposureFields = true;
      return;
    } else if (key.includes(EqrcFields.symbols) || key.includes(EqrcFields.emailAddresses)) {
      body[key] = Object.values(fieldVal).map(item => (item.value ? item.value : item));
    } else if (key.includes(originatingFormKey)) {
      return;
    } else if (key === EqrcFields.brokersListRule) {
      let list;
      if (fieldVal === null) {
        list = null;
      } else if (fieldVal === undefined) {
        list = undefined;
      } else if (fieldVal && !Array.isArray(fieldVal)) {
        if (fieldVal.length > 0) {
          list = fieldVal[EqrcFields.brokers] ? fieldVal[EqrcFields.brokers] : fieldVal;
        } else {
          list = undefined;
        }
      } else {
        list = fieldVal;
      }

      const mapped = list?.map(field => field.value);
      body[key] = { [EqrcFields.brokers]: mapped };
      body[EqrcFields.brokers] = mapped;
      body[EqrcFields.brokersListRule] = { [EqrcFields.brokers]: mapped };
    } else {
      if (fieldVal?.value) {
        body[key] = fieldVal?.value;
      } else {
        body[key] = fieldVal;
      }
    }

    if (addGrossExposureFields) {
      updateBodyForGrossExposure(body, fields);
    }

    Object.entries(fields).forEach(([key, fieldVal]) => {
      if (FIELDS_WITH_IMPLICIT_DECIMAL.includes(key)) {
        const d = new Decimal(fields[key]);
        body[key] = d.times(IMPLICIT_DECIMAL_DIVISOR).toNumber();
      }
    });
  });

  return body;
};
