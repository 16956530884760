import { DataArray, FormField } from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { memo, useCallback, useMemo, useState } from "react";
import { MpidOrSymbol, Mpid_Or_Security } from "../context";
import { filterForDataArray } from "components/pvRejects/constant";

export const DT = memo(
  ({
    data,
    isMpidOrSymbol,
  }: {
    data: { label: string; count: number; value: React.ReactNode }[];
    isMpidOrSymbol: Mpid_Or_Security;
  }) => {
    const [filterText, setFilterText] = useState<string>("");

    const wrappedSetFilterText = useCallback(
      (event: CustomChangeEvent) => setFilterText(event?.value ? event.value.toString() : ""),
      [setFilterText]
    );

    const filteredDataArray = useMemo(() => {
      return filterForDataArray(data, filterText);
    }, [filterText, data]);

    return (
      <>
        <FormField
          value={filterText?.toString()}
          onChange={wrappedSetFilterText}
          size={"sm"}
          placeholder={isMpidOrSymbol === MpidOrSymbol.MPID ? "MPID" : "Symbol"}
        />
        <DataArray data={filteredDataArray} expandable={true} />
      </>
    );
  }
);
