import React from "react";
import { FieldLoop, ptraEmailManagerFields, Forms } from "components/fields";

export const EmailManagerForm = () => {
  return (
    <FieldLoop
      isReactFragment={true}
      form={Forms.PVR_EMAIL_MANAGER}
      fields={ptraEmailManagerFields}
    />
  );
};
