import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { MAX_RETRY } from "wksConstants";
import { PVR_ALERT_MODEL } from "./constants";

export enum PROMISE_STATUS {
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

enum AlertService {
  PTR = "PostTradeRisk",
}

enum AlertReadStatus {
  READ = "Read",
  UNREAD = "Unread",
}

interface AlertRead {
  service: AlertService;
  alertId: string;
  userId: string;
  status: AlertReadStatus;
}

const cacheCallback = (resolve: Function) => (json: PVR_ALERT_MODEL[]) => {
  resolve(json);
};

const readCallback = (resolve: Function) => (json: AlertRead[]) => {
  resolve(json);
};

const errorCallback = (reject: Function) => () => {
  reject();
};

export const getAllAlertData = (abortSignal: AbortSignal) => {
  const getCacheAlerts = new Promise<PVR_ALERT_MODEL[]>((resolve, reject) => {
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_PVR_ALERT_SERVICE, `alert/subscription/userAlerts`),
      {
        method: "post",
        mode: "cors",
        signal: abortSignal,
        headers: getHeaders(),
        body: JSON.stringify({ offset: 0 }),
      },
      cacheCallback(resolve),
      errorCallback(reject),
      undefined,
      undefined,
      MAX_RETRY
    );
  });

  const getReadAlerts = new Promise<AlertRead[]>((resolve, reject) => {
    doFetchWrapper(
      formatUrl(process.env.REACT_APP_URL_USER_WS, "getpvralerts"),
      {
        method: "post",
        headers: getHeaders(),
      },
      readCallback(resolve),
      errorCallback(reject)
    );
  });
  return Promise.allSettled([getCacheAlerts, getReadAlerts]);
};
