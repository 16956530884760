import { getSelectedRows } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { EqrcServerActionToRule } from "../types.consts";
import { EQRC_RULE_STATUS } from "../constants";

export const generateCreateFormHeader = (table, ruleType, totalSteps, currentStep) => {
  const steps = totalSteps > 0 ? ` - Step ${currentStep + 1} of ${totalSteps}` : "";
  if (table === StandardTables.EQRC_ALERT_CONFIG) {
    return "Create Alert Configuration";
  } else {
    return `Create ${ruleType.label} Rule${steps}`;
  }
};

export const generateNewAlertFormHeader = (table, ruleType) => {
  if (table !== StandardTables.EQRC_ALERT_CONFIG) {
    return "Create " + ruleType.label + " Alert";
  } else {
    return "Create Alert Configuration";
  }
};

export const generateEditAlertFormHeader = (activeTable, tableData) => {
  const selected = getSelectedRows(tableData[activeTable]);
  return `Edit ${EqrcServerActionToRule[selected?.[0]?.[EQRC_RULE_STATUS.Active]?.ruleType]} Alert`;
};

export const generateResultsHeaders = ruleType => {
  return `${ruleType.label} - Transaction Results`;
};
