import { NotificationHub } from "@nef/core";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { MAX_RETRY, Status } from "wksConstants";
import { useUserContext, INITIAL_DATA_MODEL, USER_CONFIG_MODEL } from "components/user";
import { Views } from "viewConstants";
import {
  PTRA_BREACH_CACHE_MODEL,
  getAlertIdentifier,
  getIdentifierFromAlert,
  getHashKey,
  PTRA_CONFIG_CACHE_MODEL,
  getConfigHashKey,
} from "./constants";
import { usePtraConfigContext } from "components/settings/ptra/configContext";
import { LIMIT_SIDE_BREACH_VALUES } from "components/fields";
import { PTRA_CONFIGURATION_ENTITY, PTR_ALERT_TYPE_LABEL } from "components/settings/ptra/fields";
import { AlertBody, BreachAlertBody } from "./breachAlertBody";
import { getAllAlertData, PROMISE_STATUS } from "./network";
import { formatUrl } from "utils/js.utils";
import { ConfigAlertBody } from "./configAlertBody";

const MAX_POPUPS_DISPLAYED = 2;
const POPUP_TITLE = "Post - Trade Risk Alert";

const AlertRead = {
  service: "service",
  alertId: "alertId",
  userId: "userId",
  status: "status",
};

const READ_STATUS = {
  READ: "Read",
  UNREAD: "Unread",
};

const ALERT_ID = "id";

const createBreachPopupContent = popup => {
  if (popup[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own) {
    return PTR_ALERT_TYPE_LABEL[popup[PTRA_BREACH_CACHE_MODEL.alertTypes]?.[0]];
  } else {
    return "Counterparty Breach Alert";
  }
};

export const createConfigPopupTitle = popup => {
  return `${
    popup[PTRA_CONFIG_CACHE_MODEL.intradayNextDay] === "I" ? "Intraday" : "Next Day"
  } Setting Change`;
};

const createBreachPopupSubtitle = popup => {
  return <BreachAlertBody alert={popup} includeMarkAsRead={false} />;
};

const createConfigPopupSubtitle = popup => {
  return <ConfigAlertBody alert={popup} includeMarkAsRead={false} includeTooltip={false} />;
};

const ptraAlertDispatch = createContext();
ptraAlertDispatch.displayName = "PtraAlertDispatch";
export const usePtraAlertDispatch = () => {
  return useContext(ptraAlertDispatch);
};

const ptraAlertContext = createContext();
ptraAlertContext.displayName = "PtraAlertContext";
export const usePtraAlertContext = () => {
  return useContext(ptraAlertContext);
};
const defaultState = {
  breachAlerts: {},
  readBreachAlerts: {},
  unreadBreachAlerts: {},
  configAlerts: {},
  readConfigAlerts: {},
  unreadConfigAlerts: {},
  activeAlerts: [],
  readMap: {},
  breachOffset: 0,
  configOffset: 0,
  status: Status.NO_STATUS,
  makeInitialRequest: false,
  requestFailedCount: 0,
  hasMadeInitialRequest: false,
  isPolling: false,
  isRequesting: false,
  isLoading: false,
  requestAbort: false,
  isMarkAllLoading: false,
};

const evaluateBreachAlertsForLogAndPopups = (data, hashMap, readMap) => {
  let alertData = data;
  return alertData.reduce(
    (acc, alert) => {
      // add breach alerts
      alert[PTRA_BREACH_CACHE_MODEL.alertTypes].forEach(alertType => {
        const breachKey = getHashKey(
          alert[PTRA_BREACH_CACHE_MODEL.breachPartyMpid],
          alert[PTRA_BREACH_CACHE_MODEL.breachPartyClearingNum],
          LIMIT_SIDE_BREACH_VALUES.Own,
          alert[PTRA_BREACH_CACHE_MODEL.breachPartyGU],
          alertType
        );
        const breachAlert = {
          ...alert,
          [PTRA_CONFIGURATION_ENTITY.limitBreachSide]: LIMIT_SIDE_BREACH_VALUES.Own,
        };
        if (hashMap[breachKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]) {
          acc.breachPopups.push(breachAlert);
        }
        if (hashMap[breachKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]) {
          const breachIdentifier = getAlertIdentifier(breachKey, alert[PTRA_BREACH_CACHE_MODEL.id]);
          acc.breachAlerts[breachIdentifier] = breachAlert;
          if (readMap[breachIdentifier]) {
            acc.readBreachAlerts[breachIdentifier] = breachAlert;
          } else {
            acc.unreadBreachAlerts[breachIdentifier] = breachAlert;
          }
        }
        // add counter alerts (only if user won't receive it as a breach alert)
        const counterKey = getHashKey(
          alert[PTRA_BREACH_CACHE_MODEL.counterPartyMpid],
          alert[PTRA_BREACH_CACHE_MODEL.counterPartyClearingNum],
          LIMIT_SIDE_BREACH_VALUES.Counter,
          alert[PTRA_BREACH_CACHE_MODEL.counterPartyGU],
          alertType
        );
        const counterAlert = {
          ...alert,
          [PTRA_CONFIGURATION_ENTITY.limitBreachSide]: LIMIT_SIDE_BREACH_VALUES.Counter,
        };
        if (
          hashMap[counterKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive] &&
          !hashMap[breachKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]
        ) {
          acc.breachPopups.push(counterAlert);
        }
        if (
          hashMap[counterKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive] &&
          !hashMap[breachKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]
        ) {
          const counterIdentifier = getAlertIdentifier(
            counterKey,
            alert[PTRA_BREACH_CACHE_MODEL.id]
          );
          acc.breachAlerts[counterIdentifier] = counterAlert;
          if (readMap[counterIdentifier]) {
            acc.readBreachAlerts[counterIdentifier] = counterAlert;
          } else {
            acc.unreadBreachAlerts[counterIdentifier] = counterAlert;
          }
        }
      });
      return acc;
    },
    { breachPopups: [], breachAlerts: {}, readBreachAlerts: {}, unreadBreachAlerts: {} }
  );
};

const evaluateConfigAlertsForLogAndPopups = (data, hashMap, readMap) => {
  let alertData = data;
  return alertData.reduce(
    (acc, alert) => {
      // add config alerts
      let wasPopupAdded = false;
      let wasLogAdded = false;
      alert[PTRA_CONFIG_CACHE_MODEL.alertTypes].forEach(alertType => {
        const configKey = getConfigHashKey(
          alertType,
          alert[PTRA_CONFIG_CACHE_MODEL.correspondentMPID],
          alert[PTRA_CONFIG_CACHE_MODEL.intradayNextDay],
          alert[PTRA_CONFIG_CACHE_MODEL.clearingNumber]
        );
        if (!wasPopupAdded && hashMap[configKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertPopupActive]) {
          acc.configPopups.push(alert);
          wasPopupAdded = true;
        }
        if (!wasLogAdded && hashMap[configKey]?.[PTRA_CONFIGURATION_ENTITY.isAlertLogActive]) {
          const configIdentifier = getAlertIdentifier(configKey, alert[PTRA_CONFIG_CACHE_MODEL.id]);
          acc.configAlerts[configIdentifier] = alert;
          if (readMap[configIdentifier]) {
            acc.readConfigAlerts[configIdentifier] = alert;
          } else {
            acc.unreadConfigAlerts[configIdentifier] = alert;
          }
          wasLogAdded = true;
        }
      });
      return acc;
    },
    { configPopups: [], configAlerts: {}, readConfigAlerts: {}, unreadConfigAlerts: {} }
  );
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};
const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_POLLING": {
      let isPolling = action.payload;
      let makeInitialRequest = false;
      if (isPolling && !state.hasMadeInitialRequest) {
        makeInitialRequest = true;
      }
      return { ...state, isPolling, makeInitialRequest };
    }
    case "HANDLE_INITIAL_REQUEST": {
      return {
        ...state,
        makeInitialRequest: false,
        hasMadeInitialRequest: true,
      };
    }
    case "HANDLE_REQUEST_FAILED": {
      return {
        ...state,
        makeInitialRequest: true,
        hasMadeInitialRequest: false,
        requestFailedCount: action.payload,
      };
    }
    case "SET_REQUEST_ABORT": {
      return { ...state, requestAbort: action.payload };
    }
    case "SET_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUESTING": {
      return { ...state, isRequesting: action.payload };
    }
    case "SET_REQUEST_STATUS": {
      return { ...state, status: action.payload };
    }
    case "SET_DATA": {
      const { alertData, readResult, hashMap } = action.payload;
      const configData = alertData[0];
      const breachData = alertData[1];
      const readMap = {};
      if (readResult.status === PROMISE_STATUS.FULFILLED) {
        readResult.value?.forEach(readAlert => {
          if (readAlert[AlertRead.status] === READ_STATUS.READ) {
            readMap[readAlert[AlertRead.alertId]] = true;
          }
        });
      } else {
        NotificationHub.send(
          "danger",
          "Error retrieving the read status for Post - Trade Risk alerts",
          {
            subtitle:
              "This feature will be disabled and all alerts will appear as unread until recovered",
          }
        );
      }
      const { breachAlerts, readBreachAlerts, unreadBreachAlerts } =
        evaluateBreachAlertsForLogAndPopups(breachData, hashMap, readMap);
      const { configAlerts, readConfigAlerts, unreadConfigAlerts } =
        evaluateConfigAlertsForLogAndPopups(configData, hashMap, readMap);
      const numAlerts = Object.keys(breachAlerts).length + Object.keys(configAlerts).length;
      const message = `Received ${numAlerts} Post - Trade Risk alert(s) today`;
      if (state.requestFailedCount > 0) {
        NotificationHub.send("success", "Reconnected to Post - Trade Risk alerts", {
          title: POPUP_TITLE,
          subtitle: message,
        });
      } else if (numAlerts > 0) {
        NotificationHub.send("primary", message, {
          title: POPUP_TITLE,
        });
      }
      return {
        ...state,
        breachAlerts,
        readBreachAlerts,
        unreadBreachAlerts,
        configAlerts,
        readConfigAlerts,
        unreadConfigAlerts,
        readMap,
        requestFailedCount: 0,
        status: Status.SUCCESS,
        breachOffset: breachData.length,
        configOffset: configData.length,
      };
    }
    case "ADD_DATA": {
      const { data, hashMap } = action.payload;
      const configData = data[0];
      const breachData = data[1];
      const { breachAlerts, unreadBreachAlerts, breachPopups } =
        evaluateBreachAlertsForLogAndPopups(breachData, hashMap, state.readMap);
      const { configAlerts, unreadConfigAlerts, configPopups } =
        evaluateConfigAlertsForLogAndPopups(configData, hashMap, state.readMap);
      if (breachPopups.length + configPopups.length > MAX_POPUPS_DISPLAYED) {
        NotificationHub.send(
          "primary",
          `Recieved ${
            breachPopups.length + configPopups.length
          } Post - Trade Risk alerts configured for pop-ups`,
          {
            title: POPUP_TITLE,
            subtitle: `A max of ${MAX_POPUPS_DISPLAYED} pop-ups can be displayed at once`,
          }
        );
      } else {
        breachPopups.forEach(popup => {
          const color =
            popup[PTRA_CONFIGURATION_ENTITY.limitBreachSide] === LIMIT_SIDE_BREACH_VALUES.Own
              ? "danger"
              : "primary";
          NotificationHub.send(color, createBreachPopupContent(popup), {
            subtitle: createBreachPopupSubtitle(popup),
            title: POPUP_TITLE,
          });
        });
        configPopups.forEach(popup => {
          const color = "primary";
          NotificationHub.send(color, createConfigPopupTitle(popup), {
            subtitle: createConfigPopupSubtitle(popup),
            title: POPUP_TITLE,
          });
        });
      }
      return {
        ...state,
        breachOffset: state.breachOffset + breachData.length,
        configOffset: state.configOffset + configData.length,
        readBreachAlerts: state.readBreachAlerts,
        breachAlerts: { ...state.breachAlerts, ...breachAlerts },
        unreadBreachAlerts: { ...state.unreadBreachAlerts, ...unreadBreachAlerts },
        readConfigAlerts: state.readConfigAlerts,
        configAlerts: { ...state.configAlerts, ...configAlerts },
        unreadConfigAlerts: { ...state.unreadConfigAlerts, ...unreadConfigAlerts },
      };
    }
    case "SET_READ": {
      const newReadMap = { ...state.readMap };
      const newReadBreachAlerts = { ...state.readBreachAlerts };
      const newUnreadBreachAlerts = { ...state.unreadBreachAlerts };
      const newReadConfigAlerts = { ...state.readConfigAlerts };
      const newUnreadConfigAlerts = { ...state.unreadConfigAlerts };
      action.payload.forEach(alert => {
        const alertId = alert[AlertRead.alertId];
        newReadMap[alertId] = alert[AlertRead.status] === READ_STATUS.READ;
        delete newUnreadBreachAlerts[alertId];
        delete newUnreadConfigAlerts[alertId];
        if (state.breachAlerts[alertId]) {
          newReadBreachAlerts[alertId] = state.breachAlerts[alertId];
        } else if (state.configAlerts[alertId]) {
          newReadConfigAlerts[alertId] = state.configAlerts[alertId];
        } else {
          console.error("PTRA Read ID not found: " + alertId);
        }
      });
      return {
        ...state,
        readMap: newReadMap,
        readBreachAlerts: newReadBreachAlerts,
        unreadBreachAlerts: newUnreadBreachAlerts,
        readConfigAlerts: newReadConfigAlerts,
        unreadConfigAlerts: newUnreadConfigAlerts,
      };
    }
    case "SET_UNREAD": {
      const newReadMap = { ...state.readMap };
      const newReadBreachAlerts = { ...state.readBreachAlerts };
      const newUnreadBreachAlerts = { ...state.unreadBreachAlerts };
      const newReadConfigAlerts = { ...state.readConfigAlerts };
      const newUnreadConfigAlerts = { ...state.unreadConfigAlerts };
      action.payload.forEach(alert => {
        const alertId = getIdentifierFromAlert(alert);
        delete newReadMap[alertId];
        delete newReadBreachAlerts[alertId];
        delete newReadConfigAlerts[alertId];
        if (state.breachAlerts[alertId]) {
          newUnreadBreachAlerts[alertId] = state.breachAlerts[alertId];
        } else if (state.configAlerts[alertId]) {
          newUnreadConfigAlerts[alertId] = state.configAlerts[alertId];
        } else {
          console.error("PTRA Unread ID not found: " + alertId);
        }
      });
      return {
        ...state,
        readMap: newReadMap,
        readBreachAlerts: newReadBreachAlerts,
        unreadBreachAlerts: newUnreadBreachAlerts,
        readConfigAlerts: newReadConfigAlerts,
        unreadConfigAlerts: newUnreadConfigAlerts,
      };
    }
    case "SET_ALL_UNREAD": {
      const newReadMap = { ...state.readMap };
      const newReadBreachAlerts = { ...state.readBreachAlerts };
      const newUnreadBreachAlerts = { ...state.unreadBreachAlerts };
      const newReadConfigAlerts = { ...state.readConfigAlerts };
      const newUnreadConfigAlerts = { ...state.unreadConfigAlerts };
      action.payload.forEach(alert => {
        const alertId = alert[ALERT_ID];
        delete newReadMap[alertId];
        delete newReadBreachAlerts[alertId];
        delete newReadConfigAlerts[alertId];
        if (state.breachAlerts[alertId]) {
          newUnreadBreachAlerts[alertId] = state.breachAlerts[alertId];
        } else if (state.configAlerts[alertId]) {
          newUnreadConfigAlerts[alertId] = state.configAlerts[alertId];
        } else {
          console.error("PTRA Unread ID not found: " + alertId);
        }
      });
      return {
        ...state,
        readMap: newReadMap,
        readBreachAlerts: newReadBreachAlerts,
        unreadBreachAlerts: newUnreadBreachAlerts,
        readConfigAlerts: newReadConfigAlerts,
        unreadConfigAlerts: newUnreadConfigAlerts,
      };
    }
    case "SET_MARK_ALL_LOADING": {
      return { ...state, isMarkAllLoading: action.payload };
    }
    case "SET_ACTIVE_ALERTS": {
      return { ...state, activeAlerts: action.payload };
    }
    default:
      return { ...state };
  }
};

export const PtraAlertProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [abort, setAbort] = useState(new AbortController());
  const [user] = useUserContext();
  const [configs] = usePtraConfigContext();

  useEffect(() => {
    if (state.requestAbort && abort) {
      abort.abort();
      dispatchF({ type: "SET_REQUEST_ABORT", payload: false });
    }
  }, [abort, state.requestAbort]);

  const pollAfterTimeout = useCallback(() => {
    setTimeout(() => {
      dispatchF({ type: "SET_REQUESTING", payload: true });
    }, 7500);
  }, []);

  const getPtraAlertData = useCallback(
    resetFlag => {
      const getPtraAlertError = () => {
        console.warn(`An error retrieving PTR alerts happened [${new Date()}]`);
        const actions = [
          {
            type: "SET_LOADING",
            payload: false,
          },
          { type: "HANDLE_REQUEST_FAILED", payload: state.requestFailedCount + 1 },
          {
            type: "SET_REQUEST_STATUS",
            payload: Status.ERROR,
          },
        ];
        if (state.status !== Status.ERROR) {
          NotificationHub.send(
            "danger",
            "An error occurred while retrieving Post - Trade Risk alert data."
          );
        }
        dispatchF(actions);
      };

      const getPtraAlertCallback = json => {
        pollAfterTimeout();
        if (json?.[0]?.length > 0 || json?.[1]?.length > 0) {
          dispatchF({
            type: "ADD_DATA",
            payload: { data: json, hashMap: configs.hashMap },
          });
        }
      };

      const abortController = new AbortController();
      setAbort(abortController);

      if (resetFlag) {
        getAllAlertData(user, abortController.signal).then(
          results => {
            const cacheResult = results[0];
            const readResult = results[1];
            if (cacheResult.status === PROMISE_STATUS.FULFILLED) {
              dispatchF(
                {
                  type: "SET_DATA",
                  payload: { alertData: cacheResult.value, readResult, hashMap: configs.hashMap },
                },
                {
                  type: "SET_REQUEST_STATUS",
                  payload: Status.SUCCESS,
                }
              );
              pollAfterTimeout();
            } else {
              getPtraAlertError();
            }
          },
          reject => {
            console.warn({ reject });
            getPtraAlertError();
          }
        );
      } else {
        doFetchWrapper(
          formatUrl(
            user[INITIAL_DATA_MODEL.config][USER_CONFIG_MODEL.ptraCacheUrl],
            `/profile/range/categories/${user.userId}`
          ),
          {
            method: "post",
            mode: "cors",
            signal: abortController.signal,
            headers: getHeaders(),
            body: JSON.stringify([
              {
                category: "ptra-risk-config-alerts",
                profile: "riskConfigAlert",
                from: state.configOffset,
                to: -1,
              },
              {
                category: "ptra-breach-alerts",
                profile: "breachAlert",
                from: state.breachOffset,
                to: -1,
              },
            ]),
          },
          getPtraAlertCallback,
          getPtraAlertError,
          undefined,
          undefined,
          MAX_RETRY
        );
      }
    },
    [
      state.requestFailedCount,
      state.status,
      state.configOffset,
      state.breachOffset,
      pollAfterTimeout,
      configs.hashMap,
      user,
    ]
  );

  useEffect(() => {
    if (
      user.allowed.views[Views.PTR_ALERTS] &&
      user[INITIAL_DATA_MODEL.config] &&
      configs.hashMap
    ) {
      dispatchF({
        type: "SET_POLLING",
        payload: true,
      });
    }
  }, [configs.hashMap, user]);

  useEffect(() => {
    if (!state.hasMadeInitialRequest && state.makeInitialRequest && state.isPolling) {
      const initialRequest = () => {
        dispatchF([{ type: "SET_LOADING", payload: true }, { type: "HANDLE_INITIAL_REQUEST" }]);
        getPtraAlertData(true);
      };
      if (state.requestFailedCount > 0) {
        setTimeout(() => {
          initialRequest();
        }, 7500);
      } else {
        initialRequest();
      }
    }
  }, [
    state.hasMadeInitialRequest,
    state.makeInitialRequest,
    getPtraAlertData,
    state.requestFailedCount,
    state.isPolling,
  ]);

  useEffect(() => {
    if (state.isPolling && state.isRequesting) {
      dispatchF({ type: "SET_REQUESTING", payload: false });
      getPtraAlertData(false);
    }
  }, [state.isPolling, state.isRequesting, getPtraAlertData]);

  return (
    <ptraAlertDispatch.Provider value={dispatchF}>
      <ptraAlertContext.Provider value={[state, dispatchF]}>{children}</ptraAlertContext.Provider>
    </ptraAlertDispatch.Provider>
  );
};
