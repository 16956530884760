import { Tab, Tabs } from "@nef/core";
import { enumKeys } from "components/eqrc/utils";
import { EqrcRules, EqrcRulesLabelMap } from "../types.consts";
import { StandardTables } from "wksConstants";
import { RULE_TO_ROUTE, RULE_TO_TABLE } from "../constants2";

export const Tab2 = ({ activeTable, handleTabClick }) => {
  const tabs = [];
  for (const type of enumKeys(EqrcRules)) {
    tabs.push(
      <Tab
        key={type}
        active={activeTable === StandardTables[RULE_TO_TABLE[type]]}
        onClick={handleTabClick(StandardTables[RULE_TO_TABLE[type]])}
      >
        {EqrcRulesLabelMap[RULE_TO_ROUTE[type]]}
      </Tab>
    );
  }

  return <Tabs>{tabs.map(t => t)}</Tabs>;
};
