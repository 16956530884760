import { Tooltip } from "@nef/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledFooter = styled.div`
  margin-right: 2rem;
`;

const StyledLink = styled(Link)`
  display: inline;
  &::after {
    content: " ";
    white-space: pre;
  }
  &::before {
    content: " ";
    white-space: pre;
  }
`;

const AlignedUl = styled.ul`
  text-align: left;
`;

export const FooterMessage = ({ selected }) => {
  const [isEditableOpen, setIsEditableOpen] = useState(false);
  const [isNotEditableOpen, setNotIsEditableOpen] = useState(false);

  const toggleIsEditableOpen = e => {
    if (e.type === "mouseenter" || e.type === "focus") {
      setIsEditableOpen(true);
    } else {
      setIsEditableOpen(false);
    }
  };

  const toggleIsNotEditableOpen = e => {
    if (e.type === "mouseenter" || e.type === "focus") {
      setNotIsEditableOpen(true);
    } else {
      setNotIsEditableOpen(false);
    }
  };

  const { editable, notEditable } = selected.reduce(
    (acc, row) => {
      if (row.disabled) {
        acc.notEditable.push(row);
      } else {
        acc.editable.push(row);
      }

      return acc;
    },
    { editable: [], notEditable: [] }
  );

  const editableLength = editable.length;
  const editableRulesText = editableLength === 1 ? "rule" : "rules";

  const notEditableLength = notEditable.length;
  const notEditableRulesText = notEditableLength === 1 ? "rule" : "rules";

  const totalLength = editableLength + notEditableLength;
  const totalRulesText = totalLength === 1 ? "rule" : "rules";

  return (
    <StyledFooter>
      <Tooltip
        target="EQRC-edit-editable-length"
        isOpen={isEditableOpen}
        toggle={toggleIsEditableOpen}
        placement="left-end"
      >
        <AlignedUl>
          {editable.length > 0
            ? editable.map(row => {
                const status = row.status;
                const { exchange, mpid, port } = row[status];

                return (
                  <li key={`editRuleToolTip-${exchange}-${mpid}${port ? `-${port}` : ""}`}>
                    {exchange}-{mpid}
                    {port ? `-${port}` : ""}
                  </li>
                );
              })
            : "N/A"}
        </AlignedUl>
      </Tooltip>
      <Tooltip
        target="EQRC-edit-notEditable-length"
        isOpen={isNotEditableOpen}
        toggle={toggleIsNotEditableOpen}
      >
        {notEditable.length > 0
          ? notEditable.map(row => {
              const status = row.status;
              const { exchange, mpid, port } = row[status];
              return `${exchange}-${mpid}${port ? `-${port}` : ""}`;
            })
          : "N/A"}
      </Tooltip>
      {editable.length + notEditable.length} {totalRulesText} Selected -
      {editable.length > 0 && (
        <>
          <StyledLink id="EQRC-edit-editable-length" onClick={e => e.preventDefault()}>
            {editable.length} {editableRulesText}
          </StyledLink>
          will be updated.
        </>
      )}
      {notEditable.length > 0 && (
        <>
          <StyledLink id="EQRC-edit-notEditable-length">
            {notEditable.length} {notEditableRulesText}
          </StyledLink>
          will not be edited.
        </>
      )}
    </StyledFooter>
  );
};
