import React, { createContext, memo, useContext, useReducer } from "react";
import { RequestResult, Status } from "wksConstants";
import { defaultEqrcCacheState, EqrcCacheState, SingleOrderMapDatum } from "./cache.types";
import { produce } from "immer";
import { useChartsData } from "./useChartsData";
import { useTableData } from "./useTableData";
import { useEqrcRuleContext } from "components/settings";
import { NotificationHub } from "@nef/core";
import { enumKeys } from "./utils";
import { BREACHES, EXPOSURE_BREACHES, ORDER_TYPE_BREACHES } from "./eqrc.types";
import { INITIAL_DATA_MODEL, useUserContext } from "components/user";
import { EqrcCacheAction } from "./dataCacheContext.types";

export type ChartsOrTable = "charts" | "table";
export enum Charts_Or_Table {
  charts = "charts",
  table = "table",
}

const DispatchFn = (
  state: EqrcCacheState,
  actions: EqrcCacheAction | EqrcCacheAction[]
): EqrcCacheState => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }

  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), {
    ...state,
  });
};

const DispatchFnSwitch = (state: EqrcCacheState, action: EqrcCacheAction): EqrcCacheState => {
  switch (action.type) {
    case "TOGGLE_MODAL": {
      return produce(state, draft => {
        draft.isDetailsOpen = !state.isDetailsOpen;
      });
    }
    case "CLOSE_MODAL": {
      return produce(state, draft => {
        draft.isDetailsOpen = false;
      });
    }
    case "REMOVE_TABLE": {
      const { table } = action.payload;
      return produce(state, draft => {
        delete draft.detailsTablesRows[table];

        draft.isDetailsOpen = Object.keys(draft.detailsTablesRows).length > 0;
      });
    }
    // when a tile is clicked, figure out which rows this table should display,
    // based on cache keys, and set
    case "SHOW_DETAILS": {
      const { name }: { name: string } = action.payload;

      if (Object.keys(state.detailsTablesRows).includes(name)) {
        return produce(state, draft => {});
      }

      return produce(state, draft => {
        const usedKeysForDetails = state.status.charts.currentUsedCacheKeys?.filter(key => {
          const keysType = key.split("-")[0] as BREACHES;

          if (Object.keys(ORDER_TYPE_BREACHES).includes(keysType) && name === BREACHES.ORDER_TYPE) {
            return true;
          } else {
            return keysType === name;
          }
        });

        let rows: SingleOrderMapDatum[] = [];
        if (!state.status.charts.cacheKeysAsObj[name]) {
          draft.status.charts.cacheKeysAsObj[name] = [];
        }
        usedKeysForDetails?.forEach(key => {
          const cacheDatum = draft.singleOrder.get(key);
          draft.status.charts.cacheKeysAsObj[name].push(key);

          if (cacheDatum?.data) {
            rows = rows.concat(cacheDatum.data);
          }
        });

        draft.isDetailsOpen = true;

        draft.detailsTablesRows[name] = rows;
      });
    }
    case "SET_CURRENTLY_USED_CACHE_KEYS": {
      return produce(state, draft => {
        const { data, view } = action.payload;

        if (state.status[view].currentUsedCacheKeys?.toString() === data?.toString()) {
          return;
        }
        draft.status[view].currentUsedCacheKeys = data;

        switch (view) {
          case "table": {
            draft.tableData = {};
            break;
          }
          case "charts": {
            // produce this object:
            // {
            //   [alertType: string] : `singleOrder-${alertType}-${mpid}-${?port}`[]
            // }
            // so we can take an alert type and reference all keys currently set for it
            draft.status.charts.cacheKeysAsObj = {};
            const cacheKeysAsObj = draft.status.charts.cacheKeysAsObj;

            data?.forEach(key => {
              const s = key.split("-")[1];

              if (
                [
                  EXPOSURE_BREACHES.GROSS_EXECUTED_EXPOSURE,
                  EXPOSURE_BREACHES.GROSS_NOTIONAL_EXPOSURE,
                  EXPOSURE_BREACHES.GROSS_OPEN_EXPOSURE,
                ].includes(s as EXPOSURE_BREACHES) ||
                !draft.detailsTablesRows[s as BREACHES] // exclude tables the user has not requested to see
              ) {
                return;
              }

              if (!cacheKeysAsObj[s]) {
                cacheKeysAsObj[s] = [];
              }

              cacheKeysAsObj[s].push(key);
            });

            // when keys change, make sure the breach details tables are updated to use the latest keys data
            // this is from cache
            Object.entries(draft.status.charts.cacheKeysAsObj).forEach(
              ([alertType, alertTypeKeys]) => {
                draft.detailsTablesRows[alertType] = [];

                alertTypeKeys.forEach(key => {
                  const cacheDatum = draft.singleOrder.get(key);
                  if (cacheDatum !== undefined) {
                    draft.detailsTablesRows[alertType] = draft.detailsTablesRows[alertType].concat(
                      cacheDatum.data
                    );
                  }
                });
              }
            );
            break;
          }
        }
      });
    }
    case "SET_DATA_TABLE": {
      return produce(state, draft => {
        Object.keys(draft.tableData).forEach(d => {
          delete draft.tableData[d];
        });

        Object.entries(action.payload.dataToSet).forEach(([k, v]) => {
          draft.currentExposure.set(k, v);
        });

        if (
          state?.status.table.currentUsedCacheKeys?.length === 0 ||
          state.status.table.currentUsedCacheKeys === null
        ) {
          draft.tableData = {};
        } else {
          state.status.table.currentUsedCacheKeys.forEach(k => {
            const datum = draft.currentExposure.get(k);
            if (datum === undefined) {
              draft.counter = state.counter + 1;
              return;
            }
            const { data } = datum;
            const rule = action.payload?.rules.get(k);

            if (!rule) {
              return;
            }

            // the 3 types of exposure comes in 1 row, so we have to split
            draft.tableData[`${k}_open`] = {
              ...data,
              check: "Open",
              id: `${k}_open`,
              exchange: rule.exchange,
              currentExposure: data?.openExposure || 0,
              watch: rule.openExposure.watch,
              warn: rule.openExposure.warn,
              action: rule.openExposure.action,
              clientId: rule.mpid,
              accountName: rule.port,
              groupId: rule.groupId,
            };

            draft.tableData[`${k}_notional`] = {
              ...data,
              check: "Notional",
              id: `${k}_notional`,
              exchange: rule.exchange,
              currentExposure: data?.notionalExposure || 0,
              watch: rule.notionalExposure.watch,
              warn: rule.notionalExposure.warn,
              action: rule.notionalExposure.action,
              clientId: rule.mpid,
              accountName: rule.port,
              groupId: rule.groupId,
            };

            draft.tableData[`${k}_executed`] = {
              ...data,
              check: "Executed",
              id: `${k}_executed`,
              exchange: rule.exchange,
              currentExposure: data?.execExposure || 0,
              watch: rule.executedExposure.watch,
              warn: rule.executedExposure.warn,
              action: rule.executedExposure.action,
              clientId: rule.mpid,
              accountName: rule.port,
              groupId: rule.groupId,
            };
          });
        }
      });
    }
    case "SET_DATA_CHARTS": {
      const { singleOrders, exposure } = action.payload.dataToSet;
      return produce(state, draft => {
        Object.entries(singleOrders).forEach(([cacheKey, singleOrderData]) => {
          // if we have multiple ports selected, we will get multiple singleOrderData,
          // so we have to combine all the data
          if (draft?.singleOrder?.get(cacheKey)?.data === undefined) {
            draft.singleOrder.set(cacheKey, singleOrderData);
          } else {
            const newData = draft?.singleOrder
              ?.get(cacheKey)
              ?.data.concat(singleOrderData.data || []);
            draft.singleOrder.set(cacheKey, {
              offset: singleOrderData.offset,
              data: newData as SingleOrderMapDatum[],
            });
          }
          // update the breach detail table rows since we have new data
          let alertType = cacheKey.split("-")[0] as BREACHES;

          if (enumKeys(ORDER_TYPE_BREACHES).includes(alertType)) {
            alertType = BREACHES.ORDER_TYPE;
          }

          if (draft.detailsTablesRows[alertType]) {
            draft.detailsTablesRows[alertType] = [];

            const alertTypeCacheKeys = state.status.charts.cacheKeysAsObj[alertType];
            alertTypeCacheKeys.forEach(cacheKey => {
              if (!draft.detailsTablesRows[alertType]) {
                draft.detailsTablesRows[alertType] = [];
              }
              const cacheDatum = draft.singleOrder.get(cacheKey);
              if (cacheDatum !== undefined) {
                draft.detailsTablesRows[alertType] = draft.detailsTablesRows[alertType].concat(
                  cacheDatum.data
                );
              }
            });
          }
        });

        Object.entries(exposure).forEach(([cacheKey, exposureData]) => {
          const split = cacheKey.split("-");
          const level = split[2];
          if (draft?.exposure?.get(cacheKey) === undefined) {
            draft.exposure.set(cacheKey, { ...exposureData });
          } else {
            const newData = draft?.exposure
              ?.get(cacheKey)
              ?.[level]?.data?.concat(exposureData.data || []);
            const rowToReplace = draft?.exposure?.get(cacheKey);

            if (rowToReplace !== undefined && rowToReplace[level] !== undefined) {
              newData && (rowToReplace[level].data = newData);
              draft.exposure.set(cacheKey, rowToReplace);
            }
          }
        });
      });
    }

    case "SET_SHOULD_MAKE_FIRST_REQUEST": {
      return produce(state, draft => {
        const { view, shouldMake } = action.payload;

        draft.status[view].shouldMakeFirstRequest = shouldMake;
      });
    }
    case "SET_STATUS": {
      const { view, status } = action.payload;
      if (status === Status.ERROR && state.status[view].feedStatus !== Status.ERROR) {
        NotificationHub.send(
          "danger",
          `Error reading ${
            view === "charts" ? "Single Order and Exposure Breach counts" : "Current Exposure"
          }`
        );
      }

      return produce(state, draft => {
        draft.status[view].feedStatus = status;
      });
    }
    case "SET_IS_REQUESTING": {
      return produce(state, draft => {
        const { view, isRequesting } = action.payload;

        draft.status[view].isRequesting = isRequesting;
      });
    }
    case "SET_REQUEST_TIMEOUT": {
      return produce(state, draft => {
        const { view, timeOut } = action.payload;

        draft.status[view].timeout = timeOut;
      });
    }
    case "SET_SHOULD_REQUEST": {
      return produce(state, draft => {
        draft.status.charts.shouldRequest = action.payload;
        draft.status.table.shouldRequest = action.payload;
      });
    }
    case "SET_SHOULD_ABORT": {
      return produce(state, draft => {
        draft.shouldAbort = action.payload;
      });
    }
    case "SET_IS_WINDOW_FOCUSED": {
      return produce(state, draft => {
        draft.isWindowFocused = action.payload;
      });
    }
    case "CLEAR_TIMEOUT": {
      const { view } = action.payload;
      clearTimeout(state.status[view].timeout);
      return produce(state, draft => {
        draft.status[view].timeout = undefined;
      });
    }
    default:
      return produce(state, draft => {});
  }
};

const EqrcCacheDispatch = createContext<React.Dispatch<EqrcCacheAction | EqrcCacheAction[]>>(
  () => null
);
EqrcCacheDispatch.displayName = "EqrcCacheDispatch";
export const useEqrcCacheDispatch = () => useContext(EqrcCacheDispatch);

const EqrcCacheContext = createContext<{
  state: EqrcCacheState;
  dispatch: React.Dispatch<EqrcCacheAction | EqrcCacheAction[]>;
}>({ state: defaultEqrcCacheState, dispatch: () => null });
EqrcCacheContext.displayName = "EqrcCacheContext";
export const useEqrcCacheContext = () => useContext(EqrcCacheContext);

const EqrcCacheProvider = ({
  defaultData,
  children,
}: {
  defaultData?: EqrcCacheState;
  children: React.ReactNode;
}) => {
  const [state, dispatchF] = useReducer(
    DispatchFn,
    Object.assign({}, defaultEqrcCacheState, defaultData)
  );

  return (
    <>
      <EqrcCacheDispatch.Provider value={dispatchF}>
        <EqrcCacheContext.Provider value={{ state, dispatch: dispatchF }}>
          <SillyWrapper>{children}</SillyWrapper>
        </EqrcCacheContext.Provider>
      </EqrcCacheDispatch.Provider>
    </>
  );
};

export default memo(EqrcCacheProvider);
const SillyWrapper = memo(({ children }: { children: React.ReactNode }) => {
  const { state: eqrcCacheData } = useEqrcCacheContext();
  const [userData] = useUserContext();

  const isRequesting = eqrcCacheData.status.charts.isRequesting;
  const shouldRequest = eqrcCacheData.status.charts.shouldRequest;
  const shouldMakeFirstRequest = eqrcCacheData.status.charts.shouldMakeFirstRequest;

  const isRequestingTable = eqrcCacheData.status.table.isRequesting;
  const shouldRequestTable = eqrcCacheData.status.table.shouldRequest;
  const shouldMakeFirstRequestTable = eqrcCacheData.status.table.shouldMakeFirstRequest;

  const isWindowFocused = eqrcCacheData.isWindowFocused;

  const globalError = userData[INITIAL_DATA_MODEL.eqrcDataResult] === RequestResult.error;
  const [eqrcRuleData] = useEqrcRuleContext();

  useChartsData({
    shouldRequest,
    isRequesting,
    shouldMakeFirstRequest,
    isWindowFocused,
    singleOrders: eqrcCacheData.singleOrder,
    globalError,
  });

  useTableData({
    shouldRequest: shouldRequestTable,
    isRequesting: isRequestingTable,
    shouldMakeFirstRequest: shouldMakeFirstRequestTable,
    isWindowFocused: isWindowFocused,
    currentExposure: eqrcCacheData.currentExposure,
    rulesData: eqrcRuleData,
    globalError,
  });

  return <>{children}</>;
});
