export const PVR_EMAIL_FIELD_PREFIX = "PTRA_EMAIL_";

export const PVR_EMAIL_ENTITY = {
  id: "id",
  recipientEmail: "recipientEmail",
  creator: "creator",
  lastUpdate: "lastUpdate",
  status: "status",
};

export const PVR_EMAIL_STATUS = {
  Enabled: "E",
  Disabled: "D",
};
