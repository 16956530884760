import { FieldNames } from "./fieldConstants";
import { EqrcFields } from "components/settings/eqrc/constants";
import { final, getMpid } from "./fields";
import { addPrefixToField } from "utils/js.utils";
import pick from "lodash/pick";
import {
  PTRA_FIELD_PREFIX,
  PTRA_CONFIGURATION_ENTITY,
  PTRA_AGU_ENTITY,
} from "components/settings/ptra/fields";
import { PTRA_EMAIL_FIELD_PREFIX, PTRA_EMAIL_ENTITY } from "components/settings/ptra/email/fields";
import { EQRC_FIELD_PREFIX } from "../settings/eqrc/constants";
import { RASH_FIELD_PREFIX, RASH_ORDER } from "components/orderEntry/constants";
import { PVR_CONFIGURATION_ENTITY, PVR_FIELD_PREFIX } from "components/settings/pvr/fields";

export const tradeReportingFields1 = pick(final, [
  FieldNames.stickyFieldsSubmit,
  FieldNames.stickyFieldsSwitch,
]);

export const tradeReportingFields2 = pick(final, [FieldNames.confirmTableHotButtons]);

export const tradeReportingFields3 = pick(final, [
  FieldNames.confirmReportQuantity,
  FieldNames.confirmReportQuantityNumber,
]);

export const userPreferenceFields = pick(final, [
  FieldNames.workXTheme,
  FieldNames.workXNotificationPlacement,
]);

export const statsFields = pick(final, [
  "incExecMPID",
  "incExecGUP",
  "incContraMPID",
  "incContraGUP",
]);

export const queryToolOne = pick(final, [
  FieldNames.dateRange,
  FieldNames.reportDateYear,
  FieldNames.controlNum,
  "tradeStatusQueryTool",
]);

export const queryToolTwo = pick(final, [
  FieldNames.mpid,
  FieldNames.trf,
  FieldNames.clearReportRiskVals,
  FieldNames.reportingParty,
  FieldNames.agreement,
  FieldNames.counterMPID,
]);

export const queryToolThree = pick(final, [
  FieldNames.side,
  FieldNames.symbol,
  FieldNames.quantity,
  FieldNames.price,
  FieldNames.priceOverride,
  FieldNames.timeRange,
]);

export const queryToolFour = pick(final, [
  FieldNames.capacity,
  FieldNames.giveUpMPID,
  FieldNames.clearingNum,
]);

export const queryToolFive = pick(final, [
  FieldNames.counterCapacity,
  FieldNames.counterGiveUpMPID,
  FieldNames.counterClearingNum,
]);

export const queryToolSix = pick(final, [
  FieldNames.relatedMarketFlag,
  FieldNames.intendedMarketFlag,
  FieldNames.stepInOut,
  FieldNames.clearingPrice,
  FieldNames.special,
  FieldNames.specialInstructions,
  FieldNames.memo,
  FieldNames.tradeReferenceNum,
]);

export const commonPriceFields = pick(final, [
  FieldNames.price,
  FieldNames.priceOverride,
  FieldNames.quantity,
]);

export const entryFormPriceFields = pick(final, [
  FieldNames.priceTradeDigit,
  FieldNames.contract,
  FieldNames.price,
  FieldNames.priceOverride,
  FieldNames.quantity,
]);
export const exportLimitType = pick(final, [FieldNames.exportLimitType]);

export const intradayBuySellLimits = pick(final, [
  FieldNames.buyAlertLimit,
  FieldNames.sellAlertLimit,
  FieldNames.buyHoldLimit,
  FieldNames.sellHoldLimit,
  FieldNames.buyRejectLimit,
  FieldNames.sellRejectLimit,
  FieldNames.maxTradeLimit,
]);

export const intradayBuyLimits = pick(final, [
  FieldNames.buyAlertLimit,
  FieldNames.buyHoldLimit,
  FieldNames.buyRejectLimit,
]);

export const intradaySellLimits = pick(final, [
  FieldNames.sellAlertLimit,
  FieldNames.sellHoldLimit,
  FieldNames.sellRejectLimit,
]);

export const intradayNetLimits = pick(final, [
  FieldNames.netAlertLimit,
  FieldNames.netHoldLimit,
  FieldNames.netRejectLimit,
  FieldNames.maxTradeLimit,
]);

export const riskConfigMPIDs = pick(final, [
  FieldNames.clearingFirmMPID,
  FieldNames.correspondentMPID,
]);

export const ptrAuditFields1 = pick(final, [
  FieldNames.clearingFirmMPID,
  FieldNames.correspondentMPID,
]);

export const ptrAuditFields2 = pick(final, [
  FieldNames.dateRange,
  addPrefixToField(PTRA_FIELD_PREFIX, FieldNames.action),
]);

export const ptrNextDayCheckbox = pick(final, [FieldNames.ptrNextDayCheckbox]);

export const isPostTradeRiskServiceActive = pick(final, [FieldNames.isPostTradeRiskServiceActive]);

export const riskConfig1a = pick(final, [FieldNames.dataSources]);

export const riskConfig1b = pick(final, [FieldNames.holdDefaultAction]);

export const riskConfig2 = pick(final, [FieldNames.useNetTrading]);

export const riskConfig2BuySell = pick(final, [
  FieldNames.isAlertActive,
  FieldNames.buyAlertLimit,
  FieldNames.sellAlertLimit,

  FieldNames.isHoldActive,
  FieldNames.buyHoldLimit,
  FieldNames.sellHoldLimit,

  FieldNames.isRejectActive,
  FieldNames.buyRejectLimit,
  FieldNames.sellRejectLimit,
]);

export const riskConfig2Net = pick(final, [
  FieldNames.isAlertActive,
  FieldNames.netAlertLimit,

  FieldNames.isHoldActive,
  FieldNames.netHoldLimit,

  FieldNames.isRejectActive,
  FieldNames.netRejectLimit,
]);

export const riskConfig3 = pick(final, [
  FieldNames.isPerTradeActive,
  FieldNames.perTradeBuyLimit,
  FieldNames.perTradeSellLimit,
]);

export const riskConfig4 = pick(final, [FieldNames.perTradeBuyAction]);

export const riskConfig5 = pick(final, [FieldNames.isMaxTradeActive, FieldNames.maxTradeLimit]);

export const riskConfig6 = pick(final, [FieldNames.trfLimits]);

export const riskAlertFields1 = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.correspondentMPID),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.includeGiveups),
]);

export const riskAlertFieldsAguMPID = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.executingMPID),
]);

export const riskAlertFieldsAguCorrespondentMPIDs = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_AGU_ENTITY.participantMPID),
]);

export const riskAlertFields3a = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.limitBreachSide),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isHeldTradeActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateKillActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive),
]);

export const riskAlertFields3b = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive),
]);

export const riskAlertFields4 = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertLogActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertPopupActive),
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertSoundActive),
]);

export const riskAlertFields5a = pick(final, [
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive),
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.includeWorkXUserRecipient),
]);

export const riskAlertFields5b = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.emailId),
]);

export const riskAlertEditFieldsGiveUp = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.includeGiveups),
]);

export const riskAlertEditFields1 = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isNextDayChangeActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isIntraDayChangeActive),
]);

export const riskAlertEditFields2a = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.limitBreachSide),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateAlertActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isHeldTradeActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateHoldActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isKillLimitRejectActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggregateKillActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeAlertActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeHoldActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isMaxTradeAlertActive),
]);

export const riskAlertEditFields2b = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.intradayNextDay),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAggSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isPerTradeSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isMaxTradeSettingActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isDefaultActionSettingActive),
]);

export const riskAlertEditFields3 = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertLogActive),
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertPopupActive),
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isAlertSoundActive),
]);

export const riskAlertEditFields4 = pick(final, [
  addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEmailRealTimeActive),
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.isEmailEndOfDayActive),
  // addPrefixToField(PTRA_FIELD_PREFIX, PTRA_CONFIGURATION_ENTITY.emailId),
]);

export const pvrAlertFields1 = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.mpid),
]);

export const pvrAlertFields1_FINRA = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.mpid),
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.isAllMpid),
]);

export const pvrAlertFields2 = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.porStatusAlertActive),
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.pvWindowAlertActive),
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.pvResubmitAlertActive),
]);

export const pvrAlertFields3 = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.logActive),
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.popupActive),
]);

export const pvrAlertFields4a = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.includeWorkXUserRecipient),
]);

export const pvrAlertFields4b = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.emailId),
]);

export const pvrAlertEditFields4 = pick(final, [
  addPrefixToField(PVR_FIELD_PREFIX, PVR_CONFIGURATION_ENTITY.emailActive),
]);

export const eqrcMonitorGrossExposure = {
  ...pick(final, [[addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpidPort)]]),
};

export const eqrcActionsFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.cancelAction),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portAction),
]);

export const eqrcMpidPortExchangeFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.exchange),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port),
]);

export const eqrcMpidPortFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.mpid),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.port),
]);

export const eqrcGroupId = pick(final, [addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.groupId)]);

export const eqrcRuleTypeAlertLevel = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertType),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.alertLevel),
]);

export const eqrcDateRange = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.dateRange),
]);

export const eqrcFatFingerFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeOneAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeOnePct),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeTwoAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeTwoPct),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeThreeAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeThreePct),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFourAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFourPct),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFiveAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeFivePct),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeSixAmt),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.rangeSixPct),
]);

export const eqrcRestrictedStockFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols),
]);

export const eqrcShortSaleFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbols),
]);

export const eqrcMarketImpactFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.marketImpact),
]);

export const eqrcOrderTypeFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.isoOrders),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleOrders),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.nonMarketOrders),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.premarketTrading),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.postMarketTrading),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.shortSaleExempt),
]);

export const eqrcAverageDailyVolumeFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.pctAdv),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.minVolume),
]);

export const eqrcOrderRateThresholdsFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateMaxMessages),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.duplicateTimeWindowSize),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portMaxMessages),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.portTimeWindowSize),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolMaxMessages),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.symbolTimeWindowSize),
]);

export const eqrcGrossExposureFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWatch),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureWarn),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.openExposure + EqrcFields.exposureAction),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWatch),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureWarn),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.executedExposure + EqrcFields.exposureAction),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWatch),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureWarn),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.notionalExposure + EqrcFields.exposureAction),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.breachAction),
]);

export const eqrcAlertConfigurationFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.ruleType),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.active),
]);

export const eqrcEditAlertConfigurationFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.active),
]);

export const eqrcAlertConfigurationFromRuleFields = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.emailAddresses),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.active),
]);

export const eqrcActiveOrConfiguredStatus = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.status),
]);

export const eqrcMaxNotionalOrder = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderNotionalLimit),
]);

export const eqrcMaxSharesPerOrder = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.perOrderSharesLimit),
]);

export const eqrcSharesLocatedCheck = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedOption),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocatedSSE),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.sharesLocateRuleWarning),
]);

export const eqrcBrokerList = pick(final, [
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.enableBrokerList),
  addPrefixToField(EQRC_FIELD_PREFIX, EqrcFields.brokersListRule),
]);

export const fields = pick(final, [
  FieldNames.mpid,
  FieldNames.trf,
  FieldNames.clearReportRiskVals,
  FieldNames.reportingParty,
  FieldNames.agreement,
  FieldNames.counterMPID,
  FieldNames.correspondentMPID,
]);

export const fields2 = pick(final, [
  FieldNames.side,
  FieldNames.symbol,
  FieldNames.actDate,
  FieldNames.actTime,
  FieldNames.settlement,
  FieldNames.sellerDays,
]);

export const fields2ForScan = pick(final, [
  FieldNames.side,
  FieldNames.symbol,
  FieldNames.settlement,
  FieldNames.sellerDays,
]);

export const fields3 = pick(final, [
  FieldNames.capacity,
  FieldNames.giveUpMPID,
  FieldNames.branchSeqNum,
  FieldNames.clearingNum,
]);

export const fields3b = pick(final, [
  FieldNames.counterCapacity,
  FieldNames.counterGiveUpMPID,
  FieldNames.counterBranchSeqNum,
  FieldNames.counterClearingNum,
]);

export const hiddenFields = pick(final, [
  FieldNames.modifier2,
  FieldNames.modifier2Time,
  FieldNames.modifier3,
  FieldNames.modifier4,
  FieldNames.modifier4Time,
  FieldNames.tradeThrough,
]);

export const hiddenFields2 = pick(final, [
  FieldNames.relatedMarketFlag,
  FieldNames.intendedMarketFlag,
  FieldNames.stepInOut,
  FieldNames.fee,
  FieldNames.contractFee,
  FieldNames.clearingPrice,
  FieldNames.special,
  FieldNames.specialInstructions,
  FieldNames.memo,
  FieldNames.tradeReferenceNum,
]);

export const scanOnly = pick(final, [
  FieldNames.scanDateType,
  FieldNames.dateRange,
  FieldNames.tradeTimeRange,
  FieldNames.reportDateRange,
  FieldNames.reportTimeRange,
  FieldNames.controlNum,
  FieldNames.tradeStatusVals,
  FieldNames.asOf,
  FieldNames.portUser,
]);

export const scanOnly2 = pick(final, [
  FieldNames.modifier2Input,
  FieldNames.modifier3Input,
  FieldNames.modifier4Input,
]);

export const scanOnly3 = pick(final, [
  FieldNames.originalControlNum,
  FieldNames.originalControlDate,
  FieldNames.referenceReportingVenue,
]);

export const reversalFields = pick(final, [
  FieldNames.isReversal,
  FieldNames.reversalOriginalControlNum,
  FieldNames.reversalOriginalControlDate,
  FieldNames.reversalReferenceReportingVenue,
]);

export const tableActions = {
  acceptA: pick(final, [FieldNames.capacity]),
  acceptB: pick(final, [FieldNames.contraShortSaleInd]),
};

export const ptraEmailManagerFields = pick(
  final,
  addPrefixToField(PTRA_EMAIL_FIELD_PREFIX, PTRA_EMAIL_ENTITY.recipientEmail)
);

export const rashCancelFields = pick(
  final,
  addPrefixToField(RASH_FIELD_PREFIX, RASH_ORDER.orderQuantity)
);
