import { AlertForm, Divider } from "../constants";
import { PvrWorkXDeliveryOptions } from "../form/workxDeliveryOptions";
// import { PtrClearCorrespondentMPIDOptions } from "./mpidOptionsClearCorr";
// import { PtrSettingAlerts } from "../form/riskLimitSettingAlerts";
import { Forms } from "components/fields";
import { PvrEmailDeliveryOptions } from "../form/emailDeliveryOptions";
import { PvrAlertTypes } from "../form/alertTypes";
import { PvrMPIDOptions } from "../form/mpids";
import { KeycloakRoles, useUserContext } from "components/user";

export const PvrAlertForm = () => {
  const [userData] = useUserContext();

  return (
    <AlertForm>
      <PvrMPIDOptions
        form={Forms.PVR_CONFIG}
        enableAllMpid={userData.entitlements[KeycloakRoles.PVR_ALERTS_FINRA]}
      />
      <Divider />
      <PvrAlertTypes form={Forms.PVR_CONFIG} />
      <Divider />
      {/* <PtrSettingAlerts form={Forms.PVR_CONFIG} />
      <Divider /> */}
      <PvrEmailDeliveryOptions form={Forms.PVR_CONFIG} />
      <Divider />
      <PvrWorkXDeliveryOptions form={Forms.PVR_CONFIG} />
    </AlertForm>
  );
};
