import { NotificationHub } from "@nef/core";
import { Forms } from "components/fields/fieldConstants";
import { useFormDispatch } from "components/form";
import { useStandardTableDispatch } from "components/standardTable";
import { StandardTables } from "wksConstants";
import { network, useUploadContext } from ".";
import { useViewModelDispatch } from "../context";
import { useUserContext } from "../user";
import "./index.css";
import { useEffect } from "react";

export const useDetailsForUpload = (id, fileName) => {
  const [, uploadDispatch] = useUploadContext();
  const formDispatch = useFormDispatch();
  const standardTableDispatch = useStandardTableDispatch();
  const [user] = useUserContext();
  const viewDispatch = useViewModelDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      uploadDispatch({
        type: "SET_ACTIVE_ID",
        payload: { uploadId: id, filename: fileName },
      });

      const standardTableDispatches = [
        {
          type: "SET_SELECTED_ROWS_FROM_VALUE",
          payload: {
            table: StandardTables.UPLOAD_FILES,
            column: "id",
            value: id,
          },
        },
        {
          type: "HANDLE_ROWS_SELECTED",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
            rows: {},
          },
        },
      ];

      if (typeof id === "string") {
        standardTableDispatches.push({
          type: "SET_LOADING",
          payload: {
            table: StandardTables.UPLOAD_FILES,
          },
        });
        standardTableDispatches.push({
          type: "SET_LOADING",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
          },
        });
      }

      standardTableDispatch(standardTableDispatches);

      const detailsCallback = details => {
        const errors = [];
        const successes = [];
        let hasParseException = false;
        details.forEach(detail => {
          try {
            const data = JSON.parse(detail.data);
            const row = { ...detail, ...data, uploadDetailId: detail.id };
            if (detail.status === "R" || detail.status === "F") {
              errors.push(row);
            } else if (detail.status === "A") {
              successes.push(row);
            }
          } catch (e) {
            console.error(e);
            hasParseException = true;
          }
          if (hasParseException) {
            NotificationHub.send("danger", "An error occurred parsing at least one upload record.");
          }
        });
        const tableDispatches = [
          {
            type: "SET_TABLE_DATA",
            payload: {
              table: StandardTables.UPLOAD_ERRORS,
              data: errors,
            },
          },
          {
            type: "SET_TABLE_DATA",
            payload: {
              table: StandardTables.UPLOAD_SUCCESS,
              data: successes,
            },
          },
          {
            type: "SET_NOT_LOADING",
            payload: {
              table: StandardTables.UPLOAD_FILES,
            },
          },
          {
            type: "SET_LAST_QUERY_DATE",
            payload: { table: StandardTables.UPLOAD_SUCCESS },
          },
          {
            type: "SET_LAST_QUERY_DATE",
            payload: { table: StandardTables.UPLOAD_ERRORS },
          },
        ];
        standardTableDispatch(tableDispatches);
        viewDispatch({
          type: "DISABLE_FORM",
          payload: { form: Forms.UPLOAD_REPAIR },
        });
        formDispatch({
          type: "RESET_FORM",
          payload: { form: Forms.UPLOAD_REPAIR, entitlements: user.entitlements },
        });
      };
      const detailsErrorCallback = reject => {
        let message = `Something went wrong when fetching the upload details for ${fileName}`;
        NotificationHub.send("danger", message, {
          subtitle: reject?.message,
        });
        standardTableDispatch({
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_FILES,
          },
        });
        standardTableDispatch({
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.UPLOAD_ERRORS,
          },
        });
      };

      if (typeof id === "string") {
        network().getDetails(id, detailsCallback, detailsErrorCallback);
      } else {
        console.info("Ignoring get upload details request. Upload ID is not a string.");
      }
    }
  }, [
    id,
    fileName,
    uploadDispatch,
    standardTableDispatch,
    viewDispatch,
    formDispatch,
    user.entitlements,
  ]);
};
