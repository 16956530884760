import { StandardTables } from "wksConstants";
import { getHeaders } from "keycloak";
import { doFetchWrapper } from "network";
import { formatUrl } from "utils/js.utils";
import { addRemoveSymbols } from "./addRemove/Symbols";
import { addRemoveSymbolsUpdateLocateCheck } from "./addRemove/LocateCheck";

export const getUrl = url => {
  if (url === StandardTables.EQRC_ALERT_CONFIG) {
    return urls[url];
  } else {
    return `${urls[url]}multiple`;
  }
};

export const urls = {
  [StandardTables.EQRC_FAT_FINGER]: "fatFinger/",
  [StandardTables.EQRC_RESTRICTED_STOCK_LIST]: "restrictedStockList/",
  [StandardTables.EQRC_GROSS_EXPOSURE]: "grossExposure/",
  [StandardTables.EQRC_MARKET_IMPACT_CHECK]: "marketImpactCheck/",
  [StandardTables.EQRC_ORDER_TYPE]: "orderType/",
  [StandardTables.EQRC_ADV_CHECK]: "advCheck/",
  [StandardTables.EQRC_ORDER_RATE_THRESHOLDS]: "orderRateThresholds/",
  [StandardTables.EQRC_SHORT_SALE]: "shortSale/",
  [StandardTables.EQRC_ALERT_CONFIG]: "alert/configuration/",
  [StandardTables.EQRC_MAX_NOTIONAL_ORDER]: "maxNotionalOrder/",
  [StandardTables.EQRC_MAX_SHARES_PER_ORDER]: "maxSharesPerOrder/",
  [StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]: "sharesLocatedBroker/",
  [StandardTables.EQRC_BROKER_LIST]: "brokerList/",
  [StandardTables.EQRC_SHARES_LOCATED_CHECK]: "sharesLocatedCheck/",
};

export const methods = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const genericEQRCRequest = async ({
  url,
  method,
  body,
  table,
  cbArgs,
  callback,
  errorCallback,
  onClose,
}) => {
  let preparedBody = body;
  if (typeof body !== "string") {
    preparedBody = JSON.stringify(body);
  }
  let parsedUrl = url;
  if (
    (url.includes(urls[StandardTables.EQRC_SHORT_SALE]) ||
      url.includes(urls[StandardTables.EQRC_RESTRICTED_STOCK_LIST])) &&
    method === methods.put
  ) {
    addRemoveSymbols({ url, table, body, cbArgs, callback, errorCallback, onClose });
  } else if (
    url.includes(urls[StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST]) &&
    method === methods.put
  ) {
    addRemoveSymbolsUpdateLocateCheck({
      url,
      table,
      body,
      cbArgs,
      callback,
      errorCallback,
      onClose,
    });
  } else {
    await doFetchWrapper(
      parsedUrl,
      {
        method: method,
        mode: "cors",
        headers: getHeaders(),
        body: preparedBody,
      },

      json => {
        const { successful, failed } = json;
        if (successful || failed) {
          if (successful?.length > 0) {
            callback(table, json.successful, cbArgs);
          }
          if (failed?.length > 0) {
            errorCallback(table, json.failed, cbArgs);
          }
        } else {
          // this is for alert configs
          callback(table, json, cbArgs);
        }
      },
      json => {
        errorCallback(table, json, cbArgs);
      }
    );
  }
};

export const getAlertConfigs = async (baseUrl, callback, errorCallback) => {
  let url = `${baseUrl}${urls[StandardTables.EQRC_ALERT_CONFIG]}`;
  genericEQRCRequest({
    url,
    method: methods.get,
    body: null,
    table: StandardTables.EQRC_ALERT_CONFIG,
    cbArgs: null,
    callback,
    errorCallback,
  });
};

const getRuleErrorMsg = "Error retrieving rules";
const getRules = async (baseUrl, status, table, callback, errorCallback) => {
  const url = formatUrl(baseUrl, `${urls[table]}?status=${status}`);
  genericEQRCRequest({
    url,
    method: methods.get,
    body: null,
    table,
    cbArgs: null,
    callback,
    errorCallback,
  });
};

export const getRulesPromise = async (baseUrl, status, table) => {
  return new Promise((resolve, reject) => {
    const callback = (table, json) => {
      resolve({ status, table, json });
    };
    const error = () => {
      reject(getRuleErrorMsg);
    };
    getRules(baseUrl, status, table, callback, error);
  });
};

export const createRule = async (
  baseUrl,
  intraday,
  body,
  table,
  callback,
  errorCallback,
  cbArgs
) => {
  intraday = intraday != null ? intraday : false;
  const url = formatUrl(baseUrl, `${getUrl(table)}/?intraday=${intraday}`);
  genericEQRCRequest({
    url,
    method: methods.post,
    body,
    table,
    cbArgs,
    callback,
    errorCallback,
  });
};

export const updateRule = async (
  baseUrl,
  intraday,
  body,
  table,
  cbArgs,
  callback,
  errorCallback
) => {
  intraday = intraday != null ? intraday : false;
  const url = formatUrl(baseUrl, `${getUrl(table)}/?intraday=${intraday}`);
  genericEQRCRequest({ url, method: methods.put, body, table, cbArgs, callback, errorCallback });
};

export const deleteRule = async (baseUrl, body, table, cbArgs, callback, errorCallback) => {
  const url = formatUrl(baseUrl, urls[table]);
  genericEQRCRequest({ url, method: methods.delete, body, table, cbArgs, callback, errorCallback });
};

export const getOneRule = async (baseUrl, body, table, callback, errorCallback) => {
  const url = formatUrl(baseUrl, urls[table]);
  genericEQRCRequest({
    url,
    method: methods.get,
    body,
    table,
    cbArgs: null,
    callback,
    errorCallback,
  });
};

export const auditRule = async (baseUrl, body, table, callback, errorCallback) => {
  const url = formatUrl(formatUrl(baseUrl, urls[table]), "audit");
  genericEQRCRequest({
    url,
    method: methods.post,
    body,
    table,
    cbArgs: null,
    callback,
    errorCallback,
  });
};

export const alertHistory = async (baseUrl, body, callback, errorCallback) => {
  const url = formatUrl(baseUrl, "alert/dateAlert/filter");
  doFetchWrapper(
    url,
    {
      method: methods.post,
      mode: "cors",
      headers: getHeaders(),
      body,
    },
    json => {
      callback(json);
    },
    () => errorCallback()
  );
};
