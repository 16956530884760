import { getColor } from "@nef/style-utils";
import { FlipCard } from "components/flipCard";
import {
  PV_REJECT_MESSAGE,
  REJ_PRICE_OO_OVERRIDE_RANGE,
  REJ_PRICE_OO_RANGE,
  RejectLabel,
} from "components/pvRejects/constant";
import { DATE_FILTER } from "components/pvRejects/member/constant";
import {
  REJECT_CACHE_FILTER_BY,
  useRejectCacheDispatch,
} from "components/pvRejects/member/rejectCache";
import { useRejectCountState } from "components/pvRejects/member/rejectCountCache";
import { useStandardTableDispatch } from "components/standardTable";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { ArraySection } from "../../member";
import { PorOrPvr, usePORCacheContext } from "../context";
import { Back } from "./pvr.back";
import { Front } from "./pvr.front";

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoRejectsFiller = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
`;

const NoRejectsFillerJsx = <NoRejectsFiller>No Rejects Found</NoRejectsFiller>;

export const PVRs = ({ setTitle }: { setTitle: (title: string) => void }) => {
  const [isFlipped, setIsFlipped] = useState(true);
  const toggle = useCallback(() => {
    setIsFlipped(!isFlipped);
  }, [isFlipped]);
  const { state } = usePORCacheContext();
  const rejectCountState = useRejectCountState();
  const rejectCacheDispatch = useRejectCacheDispatch();
  const tableDispatch = useStandardTableDispatch();

  const handleSetFilter = useCallback(
    ({
        type,
        key,
        reject,
      }: {
        type: REJECT_CACHE_FILTER_BY.MPID | REJECT_CACHE_FILTER_BY.SYMBOL;
        key: string;
        reject: string;
      }) =>
      () => {
        const criteria: any = {
          rejectText: reject,
          date: DATE_FILTER.ALL,
        };
        switch (type) {
          case REJECT_CACHE_FILTER_BY.MPID:
            criteria["mpids"] = [key];
            break;
          case REJECT_CACHE_FILTER_BY.SYMBOL:
            criteria["symbols"] = [key];
            break;
        }
        tableDispatch({
          type: "DESELECT_ALL_ROWS",
          payload: { table: StandardTables.PV_SUPERVISOR_MONITOR },
        });
        rejectCacheDispatch({
          type: "START_POLLING",
          payload: {
            filter: {
              filterBy: type,
              criteria,
            },
          },
        });
      },
    [rejectCacheDispatch, tableDispatch]
  );

  const { mpidArrayCounts, symbolArrayCounts } = useMemo(() => {
    const { mpidArrayCounts } = Object.entries(rejectCountState.finraCounts.mpidCounts).reduce(
      (acc, [mpid, count]) => {
        const subRows = [];
        if (count.priceCount > 0) {
          subRows.push({
            label: REJ_PRICE_OO_RANGE,
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.MPID,
                  key: mpid,
                  reject: REJ_PRICE_OO_RANGE,
                })}
              >
                {count.priceCount}
              </ArrayButton>
            ),
            count: count.priceCount,
          });
        }
        if (count.priceOverrideCount > 0) {
          subRows.push({
            label: REJ_PRICE_OO_OVERRIDE_RANGE,
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.MPID,
                  key: mpid,
                  reject: REJ_PRICE_OO_OVERRIDE_RANGE,
                })}
              >
                {count.priceOverrideCount}
              </ArrayButton>
            ),
            count: count.priceOverrideCount,
          });
        }
        acc.mpidArrayCounts.push({
          label: mpid,
          value: count.priceCount + count.priceOverrideCount,
          subRows,
        });
        acc.pieChartData[0].value += count.priceCount;
        acc.pieChartData[1].value += count.priceOverrideCount;
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      {
        mpidArrayCounts: [],
        pieChartData: [
          { name: REJ_PRICE_OO_RANGE, value: 0 },
          { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
        ],
        totalRejectCount: 0,
      } as any
    );

    const { symbolArrayCounts } = Object.entries(rejectCountState.finraCounts.symbolCounts).reduce(
      (acc, [symbol, count]) => {
        const subRows = [];
        if (count.priceCount > 0) {
          subRows.push({
            label: REJ_PRICE_OO_RANGE,
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.SYMBOL,
                  key: symbol,
                  reject: REJ_PRICE_OO_RANGE,
                })}
              >
                {count.priceCount}
              </ArrayButton>
            ),
            count: count.priceCount,
          });
        }
        if (count.priceOverrideCount > 0) {
          subRows.push({
            label: REJ_PRICE_OO_OVERRIDE_RANGE,
            value: (
              <ArrayButton
                onClick={handleSetFilter({
                  type: REJECT_CACHE_FILTER_BY.SYMBOL,
                  key: symbol,
                  reject: REJ_PRICE_OO_OVERRIDE_RANGE,
                })}
              >
                {count.priceOverrideCount}
              </ArrayButton>
            ),
            count: count.priceOverrideCount,
          });
        }
        acc.symbolArrayCounts.push({
          label: symbol,
          value: count.priceCount + count.priceOverrideCount,
          subRows,
        });
        acc.pieChartData[0].value += count.priceCount;
        acc.pieChartData[1].value += count.priceOverrideCount;
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      {
        symbolArrayCounts: [],
        pieChartData: [
          { name: REJ_PRICE_OO_RANGE, value: 0 },
          { name: REJ_PRICE_OO_OVERRIDE_RANGE, value: 0 },
        ],
        totalRejectCount: 0,
      } as any
    );
    return { mpidArrayCounts, symbolArrayCounts };
  }, [handleSetFilter, rejectCountState.finraCounts]);

  const { mpidChartData, symbolChartData, totalRejectCount } = useMemo(() => {
    const { mpidData, totalRejectCount } = Object.entries(
      rejectCountState.finraCounts.mpidCounts
    ).reduce(
      (acc, [mpid, count]) => {
        acc.mpidData[mpid] = {
          [REJ_PRICE_OO_RANGE]: count.priceCount,
          [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
        };
        acc.totalRejectCount += count.priceCount + count.priceOverrideCount;
        return acc;
      },
      { mpidData: {}, totalRejectCount: 0 } as {
        mpidData: { [mpid: string]: { [rejMsg: string]: number } };
        totalRejectCount: number;
      }
    );
    const mpidArrData = Object.entries(mpidData).map(([key, val]) => {
      return { mpid: key, rejects: val };
    });
    mpidArrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const mpidChartData = [];
    for (let i = 0; i < 5 && i < mpidArrData.length; i++) {
      mpidChartData.push({
        name: mpidArrData[i].mpid,
        values: Object.entries(mpidArrData[i].rejects).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOtherMpids = mpidArrData.slice(5, mpidArrData.length).reduce((acc, curr) => {
      Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
        if (!acc[rejMsg]) {
          acc[rejMsg] = 0;
        }
        acc[rejMsg] += count;
      });
      return acc;
    }, {} as { [rejMsg: string]: number });
    const mpidVals = Object.values(countOfAllOtherMpids);
    if (mpidVals.length > 0 && mpidVals.reduce((acc, curr) => acc + curr) > 0) {
      mpidChartData.push({
        name: "Other",
        values: Object.entries(countOfAllOtherMpids).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }

    const symbolData = Object.entries(rejectCountState.finraCounts.symbolCounts).reduce(
      (acc, [symbol, count]) => {
        acc[symbol] = {
          [REJ_PRICE_OO_RANGE]: count.priceCount,
          [REJ_PRICE_OO_OVERRIDE_RANGE]: count.priceOverrideCount,
        };
        return acc;
      },
      {} as { [symbol: string]: { [rejMsg: string]: number } }
    );
    const symbolArrData = Object.entries(symbolData).map(([key, val]) => {
      return { symbol: key, rejects: val };
    });
    symbolArrData.sort((a, b) => {
      return (
        Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
        Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
      );
    });
    const symbolChartData = [];
    for (let i = 0; i < 5 && i < symbolArrData.length; i++) {
      symbolChartData.push({
        name: symbolArrData[i].symbol,
        values: Object.entries(symbolArrData[i].rejects).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    const countOfAllOtherSymbols = symbolArrData
      .slice(5, symbolArrData.length)
      .reduce((acc, curr) => {
        Object.entries(curr.rejects).forEach(([rejMsg, count]) => {
          if (!acc[rejMsg]) {
            acc[rejMsg] = 0;
          }
          acc[rejMsg] += count;
        });
        return acc;
      }, {} as { [rejMsg: string]: number });
    const symbolVals = Object.values(countOfAllOtherSymbols);
    if (symbolVals.length > 0 && symbolVals.reduce((acc, curr) => acc + curr) > 0) {
      symbolChartData.push({
        name: "Other",
        values: Object.entries(countOfAllOtherSymbols).map(([rejMsg, count]) => ({
          name: RejectLabel[rejMsg as PV_REJECT_MESSAGE],
          value: count,
        })),
      });
    }
    return { mpidChartData, symbolChartData, totalRejectCount };
  }, [rejectCountState.finraCounts]);

  return (
    <ArraySection>
      <LoaderWrapper>
        {/* <Loader isLoading={rejectCountState.isLoading}> TODO - NEF ticket, loader doesn't work with SegmentedControls + Buttons as children */}
        {totalRejectCount === 0 ? NoRejectsFillerJsx : <></>}
        <FlipCard
          front={
            <Front
              toggle={toggle}
              isMpidOrSymbol={state[PorOrPvr.PVR].mpidOrSymbol}
              mpidArrayCounts={mpidArrayCounts}
              symbolArrayCounts={symbolArrayCounts}
              totalRejectCount={totalRejectCount}
            />
          }
          back={
            <Back
              toggle={toggle}
              isAllOrPending={state[PorOrPvr.PVR].allOrPending}
              isMpidOrSymbol={state[PorOrPvr.PVR].mpidOrSymbol}
              mpidChartData={mpidChartData}
              symbolChartData={symbolChartData}
              totalRejectCount={totalRejectCount}
            />
          }
          isFlipped={isFlipped}
        />
        {/* </Loader> */}
      </LoaderWrapper>
    </ArraySection>
  );
};
