import { FieldLoop, pvrAlertEditFields4 } from "components/fields";
import { Forms } from "components/fields/fieldConstants";
import { AlertEditForm, Divider, SectionFieldsWrapper, SectionHeader } from "../constants";
// import { PtrBreachAlerts } from "../form/breachAlerts";
import { PvrWorkXDeliveryOptions } from "../form/workxDeliveryOptions";
import { PvrAlertTypes } from "../form/alertTypes";
// import { PtrSettingAlerts } from "../form/riskLimitSettingAlerts";

export const PvrAlertEditForm = () => {
  return (
    <AlertEditForm>
      <PvrAlertTypes form={Forms.PVR_EDIT} />
      <Divider />
      <section>
        <SectionHeader>Email Delivery Options</SectionHeader>
        <SectionFieldsWrapper>
          <FieldLoop
            isReactFragment={true}
            form={Forms.PVR_EDIT}
            fields={pvrAlertEditFields4}
            classNames={undefined}
            augmentOnChange={undefined}
            augmentOnCreate={undefined}
            portalRef={undefined}
            isDisabled={undefined}
            containerRef={undefined}
            showLabel={undefined}
          />
        </SectionFieldsWrapper>
      </section>
      <Divider />
      <PvrWorkXDeliveryOptions form={Forms.PVR_EDIT} />
    </AlertEditForm>
  );
};
