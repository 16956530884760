import { EqrcFields, EQRC_RULE_STATUS, getRowValuesForAction } from "../constants";
import { Forms } from "components/fields/fieldConstants";
import { NotificationHub } from "@nef/core";
import { useFormContext } from "components/form";
import React, { useCallback, useMemo } from "react";
import {
  useStandardTableContext,
  getSelectedRows,
  getColumnHeadersAndAccessorsFromColumns,
  getFormValidation,
} from "components/standardTable";
import { StandardTables } from "wksConstants";
import { EQRCTableButtons } from "./eqrcTableButtons";
import { auditRule, deleteRule } from "../network";
import { INITIAL_DATA_MODEL, USER_CONFIG_MODEL, useUserContext } from "components/user";
import { convertToCSV, createAndDownloadTemplate } from "components/standardTable/utils";
import { copyRowToForm } from "../transformers/copyRowToForm";
import { createAuditBody, createDeleteBody } from "../transformers/bodies";
import { AuditColumnMap } from "../columns/AuditColumnMap";

export const RulesTableButtons = ({
  table,
  activeNewForm,
  status,
  setNewModalVisible,
  setEditModalVisible,
  setNewAlertModalVisible,
  setAuditLoading,
  isAuditLoading,
  refreshCurrentTableData,
  activeOrConfigured,
  selected,
}) => {
  const [tableData, tableDispatch] = useStandardTableContext();
  const [userData] = useUserContext();
  const [formData, formDispatch] = useFormContext();
  const handleClickNew = useCallback(() => {
    setNewModalVisible(true);
  }, [setNewModalVisible]);

  const handleClickEdit = useCallback(() => {
    const indexes = Object.keys(tableData[table].selected).filter(
      key => tableData[table].selected[key] === true
    );
    const rows = [];
    indexes.forEach(i => rows.push(tableData[table].data[i]));
    const rowsToCopy = rows.filter(row => row[status]);

    if (rowsToCopy.length > 0) {
      setEditModalVisible(true);
    } else {
      NotificationHub.send("danger", `${status} rule is not available for edit`);
    }
  }, [tableData, table, status, setEditModalVisible]);

  const handleRowDelete = useCallback(
    (table, json, cbArgs) => {
      const { eqrcId, body } = cbArgs;
      const { mpid, exchange, port, groupId } = body;

      tableDispatch([
        {
          type: "DELETE_ROW_BY_VALUE",
          payload: {
            table,
            key: EqrcFields.eqrcId,
            value: eqrcId,
          },
        },
        { type: "SET_IS_LOADING", payload: { table, isLoading: false } },
      ]);

      NotificationHub.send("success", "Rule deleted successfully", {
        subtitle: `Exchange: ${exchange || "N/A"}, MPID: ${mpid || "N/A"}, Port ${
          port || "N/A"
        }, Group ID: ${groupId || "N/A"}`,
      });
    },
    [tableDispatch]
  );

  const handleDeleteError = useCallback(
    table => {
      NotificationHub.send("danger", "Delete Failed");
      tableDispatch({ type: "SET_IS_LOADING", payload: { table, isLoading: false } });
    },
    [tableDispatch]
  );

  const handleClickDelete = useCallback(() => {
    const selected = getSelectedRows(tableData[table]);

    tableDispatch([
      {
        type: "DESELECT_ALL_ROWS",
        payload: { table: table },
      },
      { type: "SET_IS_LOADING", payload: { table: table, isLoading: true } },
    ]);

    selected.forEach(row => {
      let body = createDeleteBody(getRowValuesForAction(row));
      let eqrcId = row[EqrcFields.eqrcId];
      deleteRule(
        userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
        JSON.stringify(body),
        table,
        { eqrcId, body },
        handleRowDelete,
        handleDeleteError
      );
    });
  }, [tableData, table, tableDispatch, userData, handleRowDelete, handleDeleteError]);

  const handleClickCopy = useCallback(() => {
    const index = Object.keys(tableData[table].selected).find(
      key => tableData[table].selected[key] === true
    );
    const row = tableData[table].data[index];
    copyRowToForm(
      row,
      activeNewForm,
      status,
      false,
      row[
        status === EQRC_RULE_STATUS.Active ? EQRC_RULE_STATUS.Configured : EQRC_RULE_STATUS.Active
      ],
      formDispatch
    );
    setNewModalVisible(true);
  }, [tableData, table, activeNewForm, status, formDispatch, setNewModalVisible]);

  const handleClickNewAlert = useCallback(() => {
    const index = Object.keys(tableData[table].selected).find(
      key => tableData[table].selected[key] === true
    );
    const row = tableData[table].data[index][activeOrConfigured];
    const fields = {
      [EQRC_RULE_STATUS.Active]: {
        [EqrcFields.exchange]: row[EqrcFields.exchange],
        [EqrcFields.mpid]: row[EqrcFields.mpid],
        [EqrcFields.port]: row[EqrcFields.port],
        [EqrcFields.ruleType]: row[EqrcFields.ruleType],
      },
    };

    copyRowToForm(
      fields,
      Forms.EQRC_ALERT_CONFIG,
      EQRC_RULE_STATUS.Active,
      true,
      row,
      formDispatch
    );

    setNewAlertModalVisible(true);
  }, [tableData, table, activeOrConfigured, formDispatch, setNewAlertModalVisible]);

  const handleClickAudit = useCallback(() => {
    const valid = getFormValidation(Forms.EQRC_AUDIT_MENU, formData, formDispatch);

    if (!valid) {
      return;
    }

    setAuditLoading(true);
    let results = [];
    const handleAudit = (table, json) => {
      results = results.concat(json.results);
      if (json.totalResults === 0) {
        setAuditLoading(false);
        NotificationHub.send("success", "No records matched this query");
        return;
      }

      if (json.pageNumber < json.totalPages - 1) {
        makeRequest(json.pageNumber + 1);
      } else {
        const { fields } = formData[Forms.EQRC_AUDIT_MENU.key];
        const start = fields[EqrcFields.beginDate];
        const end = fields[EqrcFields.endDate];
        let filename = `AUDIT_${table.toLocaleUpperCase()}`;
        if (start && end) {
          filename += `_${start.replace(/\D/g, "").substr(0, 8)}_${end
            .replace(/\D/g, "")
            .substr(0, 8)}`;
        }
        filename += ".csv";

        const { accessors, headers } = getColumnHeadersAndAccessorsFromColumns(
          AuditColumnMap[table],
          false
        );
        createAndDownloadTemplate(filename, convertToCSV(headers, results, accessors)).then(
          () => {
            setAuditLoading(false);
            NotificationHub.send("success", "Export request has been completed");
          },
          () => setAuditLoading(false)
        );
      }
    };

    const handleAuditError = (form, error, cbArgs) => {
      setAuditLoading(false);
      NotificationHub.send("danger", "Error retrieving audit information", {
        subtitle: error?.message,
      });
    };

    const makeRequest = pageNumber => {
      if (table !== StandardTables.EQRC_SHARES_LOCATED_BROKER_LIST) {
        const body = createAuditBody(pageNumber, formData[Forms.EQRC_AUDIT_MENU.key].fields);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          table,
          handleAudit,
          handleAuditError
        );
      } else {
        let body = createAuditBody(pageNumber, formData[Forms.EQRC_AUDIT_MENU.key].fields);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          StandardTables.EQRC_BROKER_LIST,
          handleAudit,
          handleAuditError
        );

        body = createAuditBody(pageNumber, formData[Forms.EQRC_AUDIT_MENU.key].fields);
        auditRule(
          userData[INITIAL_DATA_MODEL.config]?.[USER_CONFIG_MODEL.eqrcRulesUrl],
          JSON.stringify(body),
          StandardTables.EQRC_SHARES_LOCATED_CHECK,
          handleAudit,
          handleAuditError
        );
      }
    };
    makeRequest(0);
  }, [formData, formDispatch, setAuditLoading, table, userData]);

  const disabled = useMemo(() => {
    return selected.length === 0 || selected.find(row => !row.disabled);
  }, [selected]);

  return (
    <EQRCTableButtons
      table={table}
      onClickNew={handleClickNew}
      onClickEdit={handleClickEdit}
      onClickDelete={handleClickDelete}
      onClickCopy={handleClickCopy}
      onClickNewAlert={handleClickNewAlert}
      onClickAudit={handleClickAudit}
      onClickRefresh={refreshCurrentTableData}
      isAuditLoading={isAuditLoading}
      activeOrConfigured={activeOrConfigured}
      disableEdit={!disabled}
      showActiveConfiguredWarning={selected?.[0]?.showActiveConfiguredWarning}
    />
  );
};
