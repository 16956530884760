import { CSSGrid } from "@nef/core";
import React, { useCallback, useEffect } from "react";
import { StandardTables } from "wksConstants";
import { LimoLVCIndicator } from "components/limitMonitor";
import { StandardHeader, StandardTable, useStandardTableDispatch } from "components/standardTable";
import { useEqrcCacheContext } from "./dataCacheContext";

const tableStyle = { gridColumn: "span 10", gridRow: "3 / 4" };
export const Table = () => {
  const { state: eqrcData } = useEqrcCacheContext();
  const tableDispatch = useStandardTableDispatch();

  useEffect(() => {
    // only empty table / stop loading state if the table is empty and mpid is blank
    // - otherwise we are actually loading and should just wait
    if (Object.keys(eqrcData.tableData).length === 0) {
      if (eqrcData.status.table.currentUsedCacheKeys !== null) {
        tableDispatch({
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.EQRC_MONITORING,
            data: [],
          },
        });
      }
    } else {
      tableDispatch([
        {
          type: "SET_TABLE_DATA",
          payload: {
            table: StandardTables.EQRC_MONITORING,
            data: eqrcData.tableData,
          },
        },
        {
          type: "SET_NOT_LOADING",
          payload: {
            table: StandardTables.EQRC_MONITORING,
          },
        },
      ]);
    }
  }, [eqrcData.status.table.currentUsedCacheKeys, eqrcData.tableData, tableDispatch]);

  const Header = useCallback(
    () => (
      <StandardHeader
        title={
          <CSSGrid cols="auto 2rem" gap="1rem" alignItems="center">
            Current Exposure
            <LimoLVCIndicator
              id="eqrc-table-data-feed-status"
              status={eqrcData.status.table.feedStatus}
            />
          </CSSGrid>
        }
      />
    ),
    [eqrcData.status.table.feedStatus]
  );

  return (
    <StandardTable
      header={Header}
      table={StandardTables.EQRC_MONITORING}
      enableLayoutExport={true}
      isSingleSelect={true}
      additionalRowClick={undefined}
      isColumnsVirtualized={false} // must be false for single select
      isFilterable={true}
      hideQueryDate={true}
      subHeader={undefined}
      hideRowCount={undefined}
      headerMenu={undefined}
      exportCallback={undefined}
      exportFileName={undefined}
      isEditableTableEnabled={undefined}
      Header={undefined}
      style={tableStyle}
      isRowSelectEnabled={undefined}
      hideSelectedCount={undefined}
    />
  );
};
