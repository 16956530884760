import {
  getSelectedRows,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
} from "components/standardTable";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { useCallback, useMemo } from "react";
import TableButtons from "components/standardTable/tableButtons";
import { PorWindowTimer } from "components/pvRejects/porWindowTimer";
import { getHeaders } from "keycloak";
import { formatUrl } from "utils/js.utils";
import { doFetchWrapper } from "network";
import { NotificationHub } from "@nef/core";

const LeftTableWrapper = styled.div`
  grid-column: span 1;
`;

export const PorTable = ({ toggle, title }: { toggle?: () => void | undefined; title: string }) => {
  const [tableData] = useStandardTableContext(); // TODO - move this the f out of here

  const TheHeader = useCallback(() => {
    return <StandardHeader title={title} />;
  }, [title]);

  const TheOtherHeader = useCallback(() => {
    return <PorWindowTimer marginTopPx={7} />;
  }, []);

  const handlePorAction = useCallback(
    (status: string) => () => {
      const rows = getSelectedRows(tableData[StandardTables.PV_SUPERVISOR_MONITOR2]);
      const body = rows.map(row => ({ id: row.id, statusChangeEvent: status }));

      const actionPorSuccess = (data: any) => {
        NotificationHub.send("success", `POR status has been updated to ${status}`);
      };

      const actionPorError = () => {
        NotificationHub.send("danger", `Error updating POR status to ${status}`);
      };
      doFetchWrapper(
        formatUrl(process.env.REACT_APP_URL_PVR_POR_SERVICE, "por/changeStatuses"),
        {
          method: "put",
          headers: getHeaders(),
          body: JSON.stringify(body),
        },
        actionPorSuccess,
        actionPorError
      );
    },
    [tableData]
  );

  const disabled = true;
  const tableButtons = useMemo(() => {
    const buttons = [
      {
        icon: "check",
        text: "Approve",
        actionId: "approve",
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handlePorAction("APPROVE"),
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        disable: disabled,

        // header: exportHeader,
      },
      {
        icon: "times",
        text: "Deny",
        actionId: "reject",
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handlePorAction("DENY"),
        allowConfirm: true,
        canConfirm: true,
        allowMenuOverride: false,
        disable: disabled,

        // header: exportHeader,
      },
    ];

    return <TableButtons table={StandardTables.PV_SUPERVISOR_MONITOR2} buttons={buttons} />;
  }, [disabled, handlePorAction]);

  return (
    <LeftTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR2}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={true}
        headerMenu={tableButtons}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={TheOtherHeader}
      />
    </LeftTableWrapper>
  );
};
