import { EQRC_RULE_STATUS, EqrcFields } from "./constants";

export const DAY_TYPES = {
  [EQRC_RULE_STATUS.Active]: "Today",
  [EQRC_RULE_STATUS.Configured]: "Next Day",
} as const;

export enum RULE_TO_TABLE {
  "fatFinger" = "EQRC_FAT_FINGER",
  "restrictedStockList" = "EQRC_RESTRICTED_STOCK_LIST",
  "grossExposure" = "EQRC_GROSS_EXPOSURE",
  "marketImpactCheck" = "EQRC_MARKET_IMPACT_CHECK",
  "orderType" = "EQRC_ORDER_TYPE",
  "advCheck" = "EQRC_ADV_CHECK",
  "orderRateThresholds" = "EQRC_ORDER_RATE_THRESHOLDS",
  "shortSale" = "EQRC_SHORT_SALE",
  "alertConfig" = "EQRC_ALERT_CONFIG",
  "maxSharesPerOrder" = "EQRC_MAX_SHARES_PER_ORDER",
  "maxNotionalOrder" = "EQRC_MAX_NOTIONAL_ORDER",
  "sharesLocatedBroker" = "EQRC_SHARES_LOCATED_BROKER_LIST",
}

export enum TABLE_TO_RULE {
  "EQRC_FAT_FINGER" = "fatFinger",
  "EQRC_RESTRICTED_STOCK_LIST" = "restrictedStockList",
  "EQRC_GROSS_EXPOSURE" = "grossExposure",
  "EQRC_MARKET_IMPACT_CHECK" = "marketImpactCheck",
  "EQRC_ORDER_TYPE" = "orderType",
  "EQRC_ADV_CHECK" = "advCheck",
  "EQRC_ORDER_RATE_THRESHOLDS" = "orderRateThresholds",
  "EQRC_SHORT_SALE" = "shortSale",
  "EQRC_ALERT_CONFIG" = "alertConfig",
  "EQRC_MAX_SHARES_PER_ORDER" = "maxSharesPerOrder",
  "EQRC_MAX_NOTIONAL_ORDER" = "maxNotionalOrder",
  "EQRC_SHARES_LOCATED_BROKER_LIST" = "sharesLocatedBroker",
}

export enum TABLE_TO_RULE2 {
  "eqrcFatFinger" = "fatFinger",
  "eqrcRestrictedStock" = "restrictedStockList",
  "eqrcGrossExposure" = "grossExposure",
  "eqrcMarketImpact" = "marketImpactCheck",
  "eqrcOrderType" = "orderType",
  "eqrcAvgDailyVolume" = "advCheck",
  "eqrcOrderRate" = "orderRateThresholds",
  "eqrcShortSale" = "shortSale",
  "eqrcAlertConfig" = "alertConfig",
  "eqrcMaxSharesPerOrder" = "maxSharesPerOrder",
  "eqrcMaxNotionalOrder" = "maxNotionalOrder",
  "eqrcSharesLocatedCheck" = "sharesLocatedBroker",
}

export enum RULE_TO_ROUTE {
  "fatFinger" = "fatFinger/",
  "restrictedStockList" = "restrictedStockList/",
  "grossExposure" = "grossExposure/",
  "marketImpactCheck" = "marketImpactCheck/",
  "orderType" = "orderType/",
  "advCheck" = "advCheck/",
  "orderRateThresholds" = "orderRateThresholds/",
  "shortSale" = "shortSale/",
  "alertConfig" = "alert/configuration/",
  "maxSharesPerOrder" = "maxSharesPerOrder/",
  "maxNotionalOrder" = "maxNotionalOrder/",
  "sharesLocatedBroker" = "sharesLocatedBroker/",
}
export enum EqrcRuleStatus {
  active = "ACTIVE",
  configured = "CONFIGURED",
}

export const IMPLICIT_DECIMAL_DIVISOR = 10000;

export const FIELDS_WITH_IMPLICIT_DECIMAL = [
  EqrcFields.rangeOneAmt,
  EqrcFields.rangeTwoAmt,
  EqrcFields.rangeThreeAmt,
  EqrcFields.rangeFourAmt,
  EqrcFields.rangeFiveAmt,
  EqrcFields.rangeSixAmt,
];

export const handleImplicitDecimals = (data: any) => {
  if (!data) {
    return;
  }

  FIELDS_WITH_IMPLICIT_DECIMAL.forEach(f => {
    if (data[f]) {
      data[f] = data[f] / IMPLICIT_DECIMAL_DIVISOR;
    }
  });

  if (data[EqrcFields.execExposure]) {
    if (typeof data[EqrcFields.execExposure] === "number") {
      data[EqrcFields.execExposure] = data[EqrcFields.execExposure] / IMPLICIT_DECIMAL_DIVISOR;
    }
  }

  if (data[EqrcFields.executedExposure]) {
    const { warn, watch, action } = data[EqrcFields.executedExposure];

    data[EqrcFields.executedExposure][EqrcFields.warn] = warn / IMPLICIT_DECIMAL_DIVISOR;
    data[EqrcFields.executedExposure][EqrcFields.watch] = watch / IMPLICIT_DECIMAL_DIVISOR;
    data[EqrcFields.executedExposure][EqrcFields.action] = action / IMPLICIT_DECIMAL_DIVISOR;
  }

  if (data[EqrcFields.openExposure]) {
    if (typeof data[EqrcFields.openExposure] === "number") {
      data[EqrcFields.openExposure] = data[EqrcFields.openExposure] / IMPLICIT_DECIMAL_DIVISOR;
    } else {
      const { warn, watch, action } = data[EqrcFields.openExposure];

      data[EqrcFields.openExposure].warn = warn / IMPLICIT_DECIMAL_DIVISOR;
      data[EqrcFields.openExposure].watch = watch / IMPLICIT_DECIMAL_DIVISOR;
      data[EqrcFields.openExposure].action = action / IMPLICIT_DECIMAL_DIVISOR;
    }
  }

  if (data[EqrcFields.notionalExposure]) {
    if (typeof data[EqrcFields.notionalExposure] === "number") {
      data[EqrcFields.notionalExposure] =
        data[EqrcFields.notionalExposure] / IMPLICIT_DECIMAL_DIVISOR;
    } else {
      const { warn, watch, action } = data[EqrcFields.notionalExposure];

      data[EqrcFields.notionalExposure].warn = warn / IMPLICIT_DECIMAL_DIVISOR;
      data[EqrcFields.notionalExposure].watch = watch / IMPLICIT_DECIMAL_DIVISOR;
      data[EqrcFields.notionalExposure].action = action / IMPLICIT_DECIMAL_DIVISOR;
    }
  }
};
