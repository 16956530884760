import { useCallback, useMemo } from "react";
import { TableButtons } from "../../standardTable";
import React from "react";
import { TableButtonAction, TableButtonLabel } from "../../../wksConstants";
import { Header } from "@nef/core";

const DeleteHeader = ({ validRows }) => {
  return (
    <>
      <Header size={3}>{`Delete ${validRows.length} Alert Configuration(s)?`}</Header>
    </>
  );
};

const DisableHeader = ({ validRows }) => {
  return (
    <>
      <Header size={3}>{`Disable ${validRows.length} Alert Configuration(s)?`}</Header>
    </>
  );
};

const EnableHeader = ({ validRows }) => {
  return (
    <>
      <Header size={3}>{`Enable ${validRows.length} Alert Configuration(s)?`}</Header>
    </>
  );
};

export const PvrAlertTableButtons = ({
  table,
  onClickNew,
  onClickEdit,
  onClickDelete,
  onClickCopy,
  onClickDisable,
  onClickEnable,
}) => {
  const handleEdit = useCallback(() => {
    if (typeof onClickEdit === "function") {
      onClickEdit();
    }
  }, [onClickEdit]);

  const handleNew = useCallback(() => {
    if (typeof onClickNew === "function") {
      onClickNew();
    }
  }, [onClickNew]);

  const handleDelete = useCallback(() => {
    if (typeof onClickDelete === "function") {
      onClickDelete();
    }
  }, [onClickDelete]);

  const handleDisable = useCallback(() => {
    if (typeof onClickDisable === "function") {
      onClickDisable();
    }
  }, [onClickDisable]);

  const handleEnable = useCallback(() => {
    if (typeof onClickEnable === "function") {
      onClickEnable();
    }
  }, [onClickEnable]);

  const handleCopy = useCallback(() => {
    if (typeof onClickCopy === "function") {
      onClickCopy();
    }
  }, [onClickCopy]);

  const tableButtons = useMemo(() => {
    return [
      {
        icon: "minus",
        text: TableButtonLabel.DELETE,
        actionId: TableButtonAction.RISK_ALERT_DELETE,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleDelete,
        header: DeleteHeader,
      },
      {
        icon: "ban",
        text: TableButtonLabel.DISABLE,
        actionId: TableButtonAction.RISK_ALERT_DISABLE,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleDisable,
        header: DisableHeader,
      },
      {
        icon: "check",
        text: TableButtonLabel.ENABLE,
        actionId: TableButtonAction.RISK_ALERT_ENABLE,
        canConfirm: true,
        allowConfirm: true,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleEnable,
        header: EnableHeader,
      },
      {
        icon: "copy",
        text: TableButtonLabel.COPY,
        actionId: TableButtonAction.RISK_ALERT_COPY,
        confirm: false,
        requireSelect: true,
        allowMultiSelect: false,
        onClick: handleCopy,
      },
      {
        icon: "edit",
        text: TableButtonLabel.EDIT,
        actionId: TableButtonAction.RISK_ALERT_EDIT,
        confirm: false,
        requireSelect: true,
        allowMultiSelect: true,
        onClick: handleEdit,
      },
      {
        icon: "plus",
        text: TableButtonLabel.NEW,
        actionId: TableButtonAction.RISK_ALERT_NEW,
        confirm: false,
        requireSelect: false,
        allowMultiSelect: true,
        onClick: handleNew,
      },
    ];
  }, [handleDelete, handleDisable, handleEnable, handleCopy, handleEdit, handleNew]);

  return <TableButtons table={table} buttons={tableButtons} />;
};
