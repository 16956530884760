import { EQRC_RULE_STATUS, EqrcFields, getEqrcIdFromResponse } from "../constants";

export const setRuleForStatus = (rules, rule, status) => {
  const eqrcId = getEqrcIdFromResponse(rule);

  rule[EqrcFields.eqrcId] = eqrcId;
  if (!rules.hasOwnProperty(eqrcId)) {
    rules[eqrcId] = {};
  }
  rules[eqrcId][EqrcFields.eqrcId] = eqrcId;
  rules[eqrcId][status] = rule;
};

export const setActiveAndConfiguredRules = (active, configured, activeOrConfigured) => {
  let rules = {};
  let inactiveRules = {};

  if (activeOrConfigured === EQRC_RULE_STATUS.Active) {
    active.forEach(rule => {
      setRuleForStatus(rules, rule, EQRC_RULE_STATUS.Active);
    });

    configured.forEach(rule => {
      setRuleForStatus(inactiveRules, rule, EQRC_RULE_STATUS.Configured);
    });
  }

  if (activeOrConfigured === EQRC_RULE_STATUS.Configured) {
    configured.forEach(rule => {
      setRuleForStatus(rules, rule, EQRC_RULE_STATUS.Configured);
    });
    active.forEach(rule => {
      setRuleForStatus(inactiveRules, rule, EQRC_RULE_STATUS.Active);
    });
  }

  return { rules, inactiveRules };
};
