import { Forms } from "components/fields";
import { useFormContext } from "components/form";
import { EQRC_RULE_STATUS, EqrcFields } from "../constants";

export const Header = ({ ruleType, totalSteps, currentStep }) => {
  const [formData] = useFormContext();

  const currentStatus =
    formData[Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE.key].fields[EqrcFields.status];

  const statusText = currentStatus === EQRC_RULE_STATUS.Active ? "Intraday" : "Next Day";
  const steps = totalSteps > 0 ? ` - Step ${currentStep + 1} of ${totalSteps}` : "";

  return <>{`Multi-Row Edit: ${ruleType.label} Rule (${statusText})${steps}`}</>;
};
