import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { AlertProvider } from "../context";
import { PvrAlertsContainer } from "./alerts";

export const PVR = () => {
  return (
    <AlertProvider>
      <PvrAlertsContainer />
    </AlertProvider>
  );
};
