import { NotificationHub } from "@nef/core";
import { FieldNames, SelectOptions } from "components/fields";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ViewActions, Views } from "../../viewConstants";
import { FirmType, RequestResult } from "../../wksConstants";
import { useGetInitialData } from "../settings";
import { USER_CONFIG_MODEL } from "../user/initialDataModel";
import { INITIAL_DATA_MODEL } from "./initialDataModel";
import { KeycloakRoles } from "./keycloakRoles";
import { USER_MPIDS_ATTRIBUTE } from "./mpidContext";
import { FIRM_DATA_MODEL } from "components/refData";

const userDispatch = createContext();
userDispatch.displayName = "UserDispatch";
export const useUserDispatch = () => {
  return useContext(userDispatch);
};

export const userContext = createContext();
userContext.displayName = "UserContext";
export const useUserContext = () => {
  return useContext(userContext);
};

const defaultState = {
  userId: null,
  profileLoaded: false,
  username: "",
  isLoggedIn: false,
  entitlements: {},
  allowRight: false,
  userData: null,
  // defaultHomepage: Views.REPORT
  // TODO: circular dependency here
  // defaultHomepage: null,
  allowed: { views: {}, actions: {} },
  defaultHomepage: null,
  hasRolesMisconfigured: false,
  incompatibleRoles: [
    [KeycloakRoles.TRADE_REPORTING_READONLY, KeycloakRoles.TRADE_REPORTING],
    [KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY, KeycloakRoles.TRADE_REPORTING],
    [KeycloakRoles.TRADE_REPORTING_READONLY, KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY],
    [KeycloakRoles.CORRESPONDENT, KeycloakRoles.CLEARER],
    [KeycloakRoles.PTR_MONITOR, KeycloakRoles.PTR_MONITOR_RO],
    [KeycloakRoles.PTR_RISK_CONFIG, KeycloakRoles.PTR_RISK_CONFIG_RO],
    [KeycloakRoles.SUPERVISOR, KeycloakRoles.TRADE_REPORTING],
    [KeycloakRoles.SUPERVISOR, KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY],
  ],
  userDataError: null,
  roleDependencies: {
    [KeycloakRoles.PTR_MONITOR]: [[KeycloakRoles.CLEARER, KeycloakRoles.CORRESPONDENT]],
    [KeycloakRoles.PTR_MONITOR_RO]: [[KeycloakRoles.CLEARER, KeycloakRoles.CORRESPONDENT]],
    [KeycloakRoles.PTR_RISK_CONFIG]: [[KeycloakRoles.CLEARER, KeycloakRoles.CORRESPONDENT]],
    [KeycloakRoles.PTR_RISK_CONFIG_RO]: [[KeycloakRoles.CLEARER, KeycloakRoles.CORRESPONDENT]],
    [KeycloakRoles.PTR_ALERTS]: [[KeycloakRoles.CLEARER, KeycloakRoles.CORRESPONDENT]],
    [KeycloakRoles.REALTIME_STATISTICS]: [
      [
        KeycloakRoles.TRADE_REPORTING,
        KeycloakRoles.TRADE_REPORTING_READONLY,
        KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY,
      ],
    ],
  },
  isUserDataLoading: true,
  [INITIAL_DATA_MODEL.userData]: null,
  [INITIAL_DATA_MODEL.userDataResult]: null,
  [INITIAL_DATA_MODEL.clearingData]: null,
  [INITIAL_DATA_MODEL.clearingDataResult]: null,
  [INITIAL_DATA_MODEL.config]: null,
  [INITIAL_DATA_MODEL.configDataResult]: null,
  [INITIAL_DATA_MODEL.eqrcData]: null,
  [INITIAL_DATA_MODEL.eqrcDataResult]: null,
  [INITIAL_DATA_MODEL.firmData]: null,
  [INITIAL_DATA_MODEL.firmDataResult]: null,
  hasInitialized: false,
};

const setConfigUrl = (config, property, url) => {
  if (url) {
    if (config) {
      config[property] = url;
    } else {
      config = { [property]: url };
    }
  }
  return config;
};

const DispatchFn = (state, actions) => {
  if (!Array.isArray(actions)) {
    return DispatchFnSwitch(state, actions);
  }
  return actions.reduce((acc, curr) => DispatchFnSwitch(acc, curr), { ...state });
};

const DispatchFnSwitch = (state, action) => {
  switch (action.type) {
    case "SET_INITIAL_DATA": {
      const response = action.payload;

      let config = response?.[INITIAL_DATA_MODEL.config];
      config = setConfigUrl(
        config,
        USER_CONFIG_MODEL.ptraUrl,
        process.env.REACT_APP_URL_PTR_SUB_API
      );
      config = setConfigUrl(
        config,
        USER_CONFIG_MODEL.configApiCacheUrl,
        process.env.REACT_APP_URL_CONFIG_API_CACHE
      );
      config = setConfigUrl(
        config,
        USER_CONFIG_MODEL.ptraCacheUrl,
        process.env.REACT_APP_URL_PTRA_CACHE
      );
      config = setConfigUrl(
        config,
        USER_CONFIG_MODEL.rashWebServiceUrl,
        process.env.REACT_APP_URL_RASH_WS
      );

      let mpidAttributes = state.mpidAttributes;
      if (
        state.entitlements[KeycloakRoles.SUPERVISOR] &&
        response?.[INITIAL_DATA_MODEL.firmDataResult] === RequestResult.success
      ) {
        if (Array.isArray(response?.[INITIAL_DATA_MODEL.firmData])) {
          mpidAttributes = { ...state.mpidAttributes };
          const allMPIDs = response?.[INITIAL_DATA_MODEL.firmData].reduce((acc, curr) => {
            if (
              state.entitlements[KeycloakRoles.CLEARER] &&
              curr[FIRM_DATA_MODEL.firmType] === FirmType.CLEARING
            ) {
              acc.push(curr[FIRM_DATA_MODEL.firmMPID]);
            } else if (
              state.entitlements[KeycloakRoles.CORRESPONDENT] &&
              curr[FIRM_DATA_MODEL.firmType] === FirmType.CORRESPONDENT
            ) {
              acc.push(curr[FIRM_DATA_MODEL.firmMPID]);
            }
            return acc;
          }, []);
          mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_TRADE_REPORTING_MPIDS] = allMPIDs;
          mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_REALTIME_STATISTICS_MPIDS] = allMPIDs;
          mpidAttributes[USER_MPIDS_ATTRIBUTE.WORKX_PTR_MPIDS] = allMPIDs;
        } else {
          console.warn("Invalid clearing data returned for SUPERVISOR. Unable to set MPIDs.");
        }
      }

      return {
        ...state,
        initialData: action.payload,
        isUserDataLoading: false,
        userDataError: response?.[INITIAL_DATA_MODEL.userDataResult] === RequestResult.error,
        [INITIAL_DATA_MODEL.userData]: response?.[INITIAL_DATA_MODEL.userData],
        [INITIAL_DATA_MODEL.userDataResult]: response?.[INITIAL_DATA_MODEL.userDataResult],
        [INITIAL_DATA_MODEL.clearingData]: response?.[INITIAL_DATA_MODEL.clearingData],
        [INITIAL_DATA_MODEL.clearingDataResult]: response?.[INITIAL_DATA_MODEL.clearingDataResult],
        [INITIAL_DATA_MODEL.config]: config,
        [INITIAL_DATA_MODEL.configDataResult]: response?.[INITIAL_DATA_MODEL.configDataResult],
        [INITIAL_DATA_MODEL.eqrcData]: response?.[INITIAL_DATA_MODEL.eqrcData],
        [INITIAL_DATA_MODEL.eqrcDataResult]: response?.[INITIAL_DATA_MODEL.eqrcDataResult],
        [INITIAL_DATA_MODEL.firmData]: response?.[INITIAL_DATA_MODEL.firmData],
        [INITIAL_DATA_MODEL.firmDataResult]: response?.[INITIAL_DATA_MODEL.firmDataResult],
        hasInitialized: true,
        mpidAttributes,
      };
    }
    case "SET_INITIALIZED": {
      return {
        ...state,
        hasInitialized: true,
      };
    }
    case "SET_USER_DATA": {
      // payload = { isUserDataLoading?: boolean, userDataError?: obj, userDataResponse?: obj}
      return {
        ...state,
        ...action.payload,
      };
    }
    case "TOGGLE": {
      if (["report", "query", "limit"].includes(action.payload)) {
        return {
          ...state,
          entitlements: {
            ...state.entitlements,
            [action.payload]: !state.entitlements[action.payload],
          },
        };
      }

      return { ...state, [action.payload]: !state[action.payload] };
    }

    case "SET_USER_PARAMS": {
      const {
        mpidAttributes,
        userId,
        roles,
        username,
        compdb = undefined,
        hackyCallback,
      } = action.payload;

      const entitlements = {};
      roles.forEach(role => {
        entitlements[role] = true;
      });

      const mappedEntitlements = Object.entries(entitlements).map(([k]) => k);
      const misconfiguredRoles = state.incompatibleRoles.filter(roleSet =>
        roleSet.every(role => mappedEntitlements.includes(role))
      );
      const rolesWithMissingDependencies = Object.fromEntries(
        Object.entries(state.roleDependencies)
          .filter(([role, deps]) => mappedEntitlements.includes(role))
          .filter(([role, deps]) =>
            deps.find(dep => dep.every(innerDep => !mappedEntitlements.includes(innerDep)))
          )
      );

      const hasRolesMisconfigured = misconfiguredRoles.length > 0;
      const hasMissingRoleDeps = Object.keys(rolesWithMissingDependencies).length > 0;
      if (hasRolesMisconfigured || hasMissingRoleDeps) {
        return {
          ...state,
          userId,
          entitlements,
          hasRolesMisconfigured,
          hasMissingRoleDeps,
          misconfiguredRoles,
          rolesWithMissingDependencies,
          profileLoaded: true,
        };
      }

      SelectOptions[FieldNames.portUser] = () => {
        return [{ id: 1, value: username, label: username }];
      };

      SelectOptions.rightViews = [
        ...(entitlements[KeycloakRoles.REALTIME_STATISTICS]
          ? [SelectOptions.rightViewsContainer.stats]
          : []),
        ...(entitlements[KeycloakRoles.PTR_MONITOR] || entitlements[KeycloakRoles.PTR_MONITOR_RO]
          ? [SelectOptions.rightViewsContainer.risk]
          : []),
      ];

      const allowed = {
        views: {
          [Views.REPORT]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY],
          [Views.SCAN]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.REJECTS]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.UPLOAD]: entitlements[KeycloakRoles.TRADE_REPORTING],
          [Views.QUERY_TOOL]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.POST_TRADE_RISK]:
            entitlements[KeycloakRoles.PTR_MONITOR] || entitlements[KeycloakRoles.PTR_MONITOR_RO],
          [Views.REAL_TIME_STATS]: entitlements[KeycloakRoles.REALTIME_STATISTICS],
          [Views.HISTORICAL_STATS]: false,
          [Views.COMPLIANCE_DB]: entitlements[KeycloakRoles.COMPLIANCE_ALL],
          [Views.SETTINGS]: true,
          [Views.TRADER]: false,
          [Views.REFERENCE_DATA_CLEARING]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.REFERENCE_DATA_AGU]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.REFERENCE_DATA_CUSIP]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_READONLY],
          [Views.RIGHT]: !!(
            entitlements[KeycloakRoles.REALTIME_STATISTICS] ||
            entitlements[KeycloakRoles.PTR_MONITOR] ||
            entitlements[KeycloakRoles.PTR_MONITOR_RO]
          ),
          [Views.RISK_SETTINGS_UPLOAD]:
            entitlements[KeycloakRoles.PTR_RISK_CONFIG] && entitlements[KeycloakRoles.SUPERVISOR],
          [Views.SETTINGS_LIMO]:
            entitlements[KeycloakRoles.PTR_RISK_CONFIG] ||
            entitlements[KeycloakRoles.PTR_RISK_CONFIG_RO],
          [Views.PTR_AUDIT_TRAIL]:
            entitlements[KeycloakRoles.PTR_RISK_CONFIG] ||
            entitlements[KeycloakRoles.PTR_RISK_CONFIG_RO],
          [Views.PTR_ALERTS]: entitlements[KeycloakRoles.PTR_ALERTS],
          [Views.PTR_ALERTS_AGU]: entitlements[KeycloakRoles.PTR_ALERTS_AGU],
          [Views.PTR_EMAILS]:
            entitlements[KeycloakRoles.PTR_ALERTS] || entitlements[KeycloakRoles.PTR_ALERTS_AGU],
          [Views.EQRC_RULES]: entitlements[KeycloakRoles.EQRC_RULES_READ],
          [Views.EQRC_MONITOR]: entitlements[KeycloakRoles.EQRC_RULES_READ],
          [Views.ORDER_ENTRY]: entitlements[KeycloakRoles.ORDER_ENTRY_TEST_TOOL],
          [Views.KEYCLOAK_EXPORT]: entitlements[KeycloakRoles.SUPERVISOR],
          [Views.PVR_CLIENT_MONITOR]: entitlements[KeycloakRoles.PVR_CLIENT_MONITOR],
          [Views.PVR_LITE_MONITOR]: entitlements[KeycloakRoles.PVR_LITE_MONITOR],
          [Views.PVR_FINRA_MONITOR]: entitlements[KeycloakRoles.PVR_FINRA_MONITOR],
          [Views.PVR_ALERTS]:
            entitlements[KeycloakRoles.PVR_ALERTS] || entitlements[KeycloakRoles.PVR_ALERTS_FINRA],
          [Views.PVR_EMAIL]:
            entitlements[KeycloakRoles.PVR_ALERTS] || entitlements[KeycloakRoles.PVR_ALERTS_FINRA],
        },
        actions: {
          [ViewActions.TRADE_REPORTING]:
            entitlements[KeycloakRoles.TRADE_REPORTING] ||
            entitlements[KeycloakRoles.TRADE_REPORTING_CLEARING_ONLY],
          [ViewActions.PTR_CONFIG]: entitlements[KeycloakRoles.PTR_RISK_CONFIG],
          [ViewActions.PTR_TRADES]: entitlements[KeycloakRoles.PTR_MONITOR],
          [ViewActions.SUPERVISOR]: entitlements[KeycloakRoles.SUPERVISOR],
          [ViewActions.EQRC_RULES_WRITE]: entitlements[KeycloakRoles.EQRC_RULES_WRITE],
          [ViewActions.EQRC_MONITOR_SAFETY_SWITCH]: entitlements[KeycloakRoles.EQRC_SAFETY_SWITCH],
          [ViewActions.ORDER_ENTRY]: entitlements[KeycloakRoles.ORDER_ENTRY_TEST_TOOL],
          [ViewActions.PVR_CLIENT_MONITOR_POR]: entitlements[KeycloakRoles.PVR_POR_ACTION],
          [ViewActions.PVR_CLIENT_MONITOR_RESUBMIT]:
            entitlements[KeycloakRoles.PVR_RESUBMIT_ACTION],
        },
      };

      let defaultHomepage;
      const entitlementKeys = Object.keys(entitlements);
      if (window.location.pathname.replace("/", "").length) {
        defaultHomepage = window.location.pathname.replace("/", "");
      } else if (allowed.views[Views.REPORT]) {
        defaultHomepage = Views.REPORT;
      } else if (allowed.views[Views.SCAN] && !allowed.views[Views.REPORT]) {
        defaultHomepage = Views.SCAN;
      } else if (allowed.views[Views.REAL_TIME_STATS]) {
        defaultHomepage = Views.REAL_TIME_STATS;
      } else if (allowed.views[Views.POST_TRADE_RISK]) {
        defaultHomepage = Views.POST_TRADE_RISK;
      } else if (allowed.views[Views.EQRC_MONITOR]) {
        defaultHomepage = Views.EQRC_MONITOR;
      } else if (allowed.views[Views.ORDER_ENTRY]) {
        defaultHomepage = Views.ORDER_ENTRY;
      } else {
        defaultHomepage = Views.NO_ACCESS;
      }

      if (hackyCallback) {
        hackyCallback(entitlementKeys);
      }

      return {
        ...state,
        mpidAttributes,
        userId,
        roles,
        entitlements,
        username,
        defaultHomepage,
        compdb,
        profileLoaded: true,
        allowRight: allowed.views[Views.RIGHT],
        allowed,
      };
    }
    default:
      return { ...state };
  }
};

const UserProvider = ({ children, defaultData }) => {
  const [state, dispatchF] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));
  const [hasInitialized, setInitialized] = useState(false);

  const {
    response: initialDataResponse,
    loading: isInitialDataLoading,
    error: initialDataError,
  } = useGetInitialData({
    username: state.username,
  });

  useEffect(() => {
    if (
      initialDataError === false &&
      initialDataResponse &&
      !isInitialDataLoading &&
      !state.hasInitialized
    ) {
      dispatchF({
        type: "SET_INITIAL_DATA",
        payload: initialDataResponse,
      });
    }
  }, [initialDataError, initialDataResponse, isInitialDataLoading, state]);

  const sendNotification = useCallback(message => {
    NotificationHub.send("danger", `Error retrieving ${message}`, {
      subtitle: "Please report the issue to WorkX Help Desk",
    });
  }, []);

  useEffect(() => {
    if (!state.isUserDataLoading && !hasInitialized) {
      if (state.userDataError === true || state.userDataResult === RequestResult.error) {
        sendNotification("User Defaults");
      }
      if (
        state[INITIAL_DATA_MODEL.clearingDataResult] === RequestResult.error ||
        state[INITIAL_DATA_MODEL.firmDataResult] === RequestResult.error
      ) {
        sendNotification("Clearing / Correspondent Data");
      }
      if (state[INITIAL_DATA_MODEL.eqrcDataResult] === RequestResult.error) {
        sendNotification("EQRC Reference Data");
      }

      if (state[INITIAL_DATA_MODEL.configDataResult] === RequestResult.error) {
        sendNotification("Config Data");
      }

      setInitialized(true);
    }
  }, [state, hasInitialized, initialDataError, sendNotification]);
  return (
    <userDispatch.Provider value={dispatchF}>
      <userContext.Provider value={[state, dispatchF]}>{children}</userContext.Provider>
    </userDispatch.Provider>
  );
};

export default UserProvider;
