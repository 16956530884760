import { REJ_PRICE_OO_RANGE } from "components/pvRejects/constant";
import { DATE_FILTER } from "components/pvRejects/member/constant";
import {
  REJECT_CACHE_FILTER_BY,
  useRejectCacheDispatch,
  useRejectCacheState,
} from "components/pvRejects/member/rejectCache";
import {
  getSelectedRows,
  RecapButton,
  StandardHeader,
  StandardTable,
  useStandardTableContext,
} from "components/standardTable";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { StandardTables } from "wksConstants";
import { All_Or_Pending } from "../context";

const RightTableWrapper = styled.div`
  grid-column: span 1;
`;

export const RejectsTable = ({
  isAllOrPending,
  selected,
}: {
  isAllOrPending: All_Or_Pending;
  selected?: string;
}) => {
  const [tableData, tableDispatch] = useStandardTableContext();
  const [title, setTitle] = useState<string | null>(null);
  const [prevSelected, setSelected] = useState<string>("");
  const rejectCacheDispatch = useRejectCacheDispatch();
  const rejectCacheState = useRejectCacheState();

  const TheHeader = useCallback(() => <StandardHeader title={title} />, [title]);

  useEffect(() => {
    const selectedIndexes = Object.keys(
      tableData[StandardTables.PV_SUPERVISOR_MONITOR2].selected
    )?.join("|");
    if (prevSelected !== selectedIndexes) {
      const rows = getSelectedRows(tableData[StandardTables.PV_SUPERVISOR_MONITOR2]);
      const mpidToSymbols = rows.reduce((acc, curr) => {
        if (!acc[curr.mpid]) {
          acc[curr.mpid] = [];
        }
        acc[curr.mpid].push(curr.symbol);
        return acc;
      }, {} as any);

      rejectCacheDispatch({
        type: "START_POLLING",
        payload: {
          filter: {
            filterBy: REJECT_CACHE_FILTER_BY.MPID_SYMBOL,
            criteria: {
              mpidToSymbols,
              rejectText: REJ_PRICE_OO_RANGE,
              date: DATE_FILTER.ALL,
            },
          },
        },
      });

      const numSelectedRows = rows.length;
      let newTitle = "";
      if (numSelectedRows > 0 && selected) {
        newTitle = `Rejects for ${numSelectedRows} ${selected} POR`;
      } else if (numSelectedRows === 0 && selected) {
        newTitle = `Rejects for ${selected} POR`;
      } else if (numSelectedRows === 0 && !selected) {
        newTitle = `Rejects for ${isAllOrPending} POR`;
      } else if (numSelectedRows > 0 && !selected) {
        newTitle = `Rejects for ${numSelectedRows} POR`;
      }

      if (newTitle !== title) {
        setTitle(newTitle);
      } else {
        return;
      }

      setSelected(selectedIndexes);
    }
  }, [isAllOrPending, prevSelected, rejectCacheDispatch, selected, tableData, title]);

  useEffect(() => {
    tableDispatch([
      {
        type: "SET_TABLE_DATA",
        payload: {
          table: StandardTables.PV_SUPERVISOR_MONITOR,
          data: Object.values(rejectCacheState.data),
        },
      },
      {
        type: "SET_IS_LOADING",
        payload: {
          table: StandardTables.PV_SUPERVISOR_MONITOR,
          isLoading: rejectCacheState.isLoading,
        },
      },
    ]);
  }, [rejectCacheState.data, rejectCacheState.isLoading, tableDispatch]);

  return (
    <RightTableWrapper>
      <StandardTable
        header={TheHeader}
        table={StandardTables.PV_SUPERVISOR_MONITOR}
        enableLayoutExport={true}
        isSingleSelect={false}
        isColumnsVirtualized={false}
        isFilterable={true}
        subHeader={undefined}
        hideRowCount={false}
        hideSelectedCount={false}
        hideQueryDate={undefined}
        headerMenu={<RecapButton table={StandardTables.PV_SUPERVISOR_MONITOR} />}
        exportCallback={undefined}
        exportFileName={undefined}
        additionalRowClick={undefined}
        isEditableTableEnabled={undefined}
        style={undefined}
        isRowSelectEnabled={undefined}
        Header={undefined}
      />
    </RightTableWrapper>
  );
};
