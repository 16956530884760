import { EqrcFields, getEqrcIdFromResponse } from "../constants";
import _ from "lodash";
import { genericAddRemove } from "./generic";

export const addRemoveSymbols = async ({ url, table, body, cbArgs, callback, errorCallback }) => {
  const rows = {};

  body.inputDTOs.forEach(dto => {
    const eqrcId = getEqrcIdFromResponse(dto);
    const cbArgsRow = cbArgs.find(row1 => row1.originalRow[EqrcFields.eqrcId] === eqrcId);
    const { symbols: newSymbols, status } = dto;
    const { symbols: currentSymbols } = cbArgsRow?.originalRow?.[status] || {
      originalRow: { [status]: { symbols: [] } },
    };

    rows[eqrcId] = { dto, newSymbols, currentSymbols };
  });

  const { success, failed } = await genericAddRemove({
    url,
    table,
    body,
    cbArgs,
    rows,
    callback,
    urlSubRoutes: { add: "addSymbols", remove: "removeSymbols" },
    payloadKey: "symbols",
  });

  callback(table, success, cbArgs, {
    showNotification: false,
    resetForm: true,
    doOnClose: true,
  });

  if (Object.entries(failed).length) {
    errorCallback(table, Object.values(failed), cbArgs, {
      showNotification: false,
      resetForm: false,
      doOnClose: false,
      deselectRows: false,
    });
  }
};
