import { Flex } from "@nef/core";
import { Forms, eqrcActiveOrConfiguredStatus } from "components/fields";
import FieldLoop from "components/fields/fieldLoop";
import styled from "styled-components";
import { StandardTables } from "wksConstants";

const StyledSpan = styled.span`
  margin-right: 1rem;
`;

export const SettingsHeaderMenu = ({ activeTable }) => {
  if (activeTable === StandardTables.EQRC_ALERT_CONFIG) {
    return <></>;
  } else {
    return (
      <Flex>
        <StyledSpan>Show Rules for</StyledSpan>
        <FieldLoop
          form={Forms.EQRC_ACTIVE_OR_CONFIGURED_TABLE}
          fields={eqrcActiveOrConfiguredStatus}
          isReactFragment={true}
        />
      </Flex>
    );
  }
};
