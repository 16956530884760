import React, { useMemo } from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircleProgress } from "../../upload";
// import "./index.css";
import { useBulkJobState } from "./context";

export const BulkJobProcessing = () => {
  const bulkJobState = useBulkJobState();

  const bulkJobs = useMemo(() => {
    return Object.values(bulkJobState.processing);
  }, [bulkJobState.processing]);

  return bulkJobs.map((job: any) => {
    return (
      <CircleProgress
        key={job.id}
        title={"Bulk Job"}
        filename={undefined}
        name={`BulkJob_${job.id.substr(0, 8)}`}
        id={`processing_${job.id}`}
        progress={((job.countAccepted + job.countRejected + job.countFailed) / job.numItems) * 100}
      />
    );
  });
};
